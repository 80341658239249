<template>
  <v-off-canvas
    id="pricing-invoice-create-off-canvas"
    :backdrop-static="true"
    offcanvas-class="offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
        <h5 class="offcanvas-title">{{ $t("general.shared.customNotes") }}</h5>
      </off-canvas-header>

      <off-canvas-body>
        <div class="form-outline">
          <label class="label">
            <span>{{ $t("general.shared.customNotes") }}</span>
          </label>
          <v-html-editor v-model="localNotes" id="the-custom-notes" ref="the-custom-notes" height="600" />
        </div>
        <div class="d-flex justify-content-end">
          <v-button class="btn-icon w-fit mt-16" size="sm" variant="primary" @click="SubmitComments">
            {{ $t("general.button.send") }}
            <v-icon icon="arrow-right" size="sm" space="ms-12" />
          </v-button>
        </div>
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import VOffCanvas from "@/components/vendor/basic/offcanvas/VOffCanvas.vue";
import OffCanvasHeader from "@/components/vendor/basic/offcanvas/OffCanvasHeader.vue";
import OffCanvasBody from "@/components/vendor/basic/offcanvas/OffCanvasBody.vue";
import VHtmlEditor from "@/components/vendor/htmlEditor/VHtmlEditor.vue";
import VButton from "@/components/vendor/basic/button/VButton.vue";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";
import ResizableContainer from "@/components/resizablecontainer/ResizableContainer.vue";
import api from "@/api";

export default defineComponent({
  name: "TheCustomNotesOffCanvas",
  components: {
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    ResizableContainer,
    VHtmlEditor,
    VButton,
    VIcon,
  },
  props: {
    agency: {
      type: String,
      required: true,
    },
    caseId: {
      type: Number,
      required: true,
    },
    tourId: {
      type: Number,
      required: true,
    },
    supplier: {
      type: Object as any,
    },
  },
  emits: ["closed", "reloadItems"],
  data() {
    return {
      localNotes: this?.supplier?.notes,
    };
  },
  methods: {
    async SubmitComments() {
      const payload = {
        notes: this.localNotes,
      };

      const respone = await api.tourPricing.customnotes(
        this.agency,
        this.caseId,
        this.tourId,
        this.supplier?.itemResourcesGrouped[0]?.tourDayId,
        this.supplier?.tourDayItemIds,
        payload
      );
      if (respone) {
        this.$toast.success(this.$t("general.shared.savedChanges"));
        this.$emit("closed");
        this.$emit("reloadItems");
      }
    },
  }
});
</script>
