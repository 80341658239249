<template>
  <div v-if="!!currentTourDayItem" class="h-100">
    <v-loader v-if="tourDayItemResourcesStatus === 'loading'" />

    <form v-else id="tour-day-item-resource-update" class="d-flex flex-column justify-content-between h-100">
      <div>
        <template
          v-if="currentTourDayItem.type !== 'general_information' && currentTourDayItem.type !== 'stage_information'"
        >
          <fieldset>
            <legend class="visually-hidden">{{ $t("tourBuilder.searchTourDayItemResource") }}</legend>

            <div class="gap-16 mb-16 d-flex">
              <div class="fw-medium">{{ $t("general.shared.supplier") }}:</div>
              <div>{{ supplierName }}</div>
            </div>

            <div class="resources-container form-outline">
              <div class="d-flex justify-content-between">
                <label class="label">
                  <span>{{ $t("general.shared.products") }}</span>
                </label>

                <label class="label me-20 bg-light">
                  <v-button
                    class="p-0 mx-4 btn-rates"
                    variant="icon"
                    @click="openOffCanvas('the-supplier-details-rates-off-canvas')"
                  >
                    <v-icon class="px-0" icon="euro" />
                  </v-button>

                  <v-button
                    class="p-0 mx-4 btn-rates"
                    variant="icon"
                    @click="openOffCanvas('the-supplier-details-offcanvas')"
                  >
                    <v-icon class="px-0" icon="pencil" />
                  </v-button>

                  <v-button class="p-0 mx-4 btn-rates" variant="icon" @click="loadSupplierSelected">
                    <v-icon class="px-0" icon="refresh" />
                  </v-button>
                </label>
              </div>

              <div v-if="!!resources && resources.length > 0" class="form-control">
                <div
                  v-for="resource in resources"
                  :key="resource.id"
                  class="py-4 resource-item d-flex justify-content-between align-items-center"
                >
                  <v-checkbox
                    :id="`tour-day-item-create-resource-${resource.id}`"
                    v-model="resource.check"
                    :for-key="`tour-day-item-create-resource-${resource.id}`"
                    :label="resource.name"
                    class="form-check-inline me-32"
                    label-class="ms-0"
                    @click.prevent="setResource(resource)"
                  />

                  <div v-if="!!checkExistResource(resource.id)" class="d-flex">
                    <v-button v-if="checkExistResource(resource.id).quantity > 1" class="p-0" size="sm" variant="icon"
                    @click="removeResource(resource)">
                    <v-icon icon="remove" size="sm" />
                  </v-button>

                    <div class="mx-8">{{ checkExistResource(resource.id)?.quantity }}</div>

                    <v-button class="p-0" size="sm" variant="icon" @click="addResource(resource)">
                      <v-icon icon="circle-plus" size="sm" />
                    </v-button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <div class="pb-24 mb-24 search-separator-container">
              <div class="mb-16 separator-text-container" />
            </div>

            <legend class="visually-hidden">{{ $t("tourBuilder.tourDayItemResourceDates") }}</legend>

            <div v-if="resourcesSelected.length > 0">
              <template
                v-for="resource in resourcesSelected"
                :key="`resource-${resource.data.id}${resource.data.roomTypeSlug}`"
              >
                <div
                  :id="`accordionSupplierResource${resource.data.id}${resource.data.roomTypeSlug}`"
                  class="mb-16 border accordion"
                >
                  <div class="mb-0 supplier-resource-details accordion-item">
                    <div
                      :id="`headingSupplierResource${resource.data.id}${resource.data.roomTypeSlug}`"
                      class="accordion-header"
                    >
                      <button
                        :aria-controls="`collapseSupplierResource${resource.data.id}${resource.data.roomTypeSlug}`"
                        :data-bs-target="`#collapseSupplierResource${resource.data.id}${resource.data.roomTypeSlug}`"
                        aria-expanded="false"
                        class="p-8 accordion-button collapsed"
                        data-bs-toggle="collapse"
                        type="button"
                      >
                        <span class="d-flex flex-column">
                          <span class="fw-medium pe-80" v-if="!resource.tourDayItemResources[0].roomTypeSlug">
                            {{ resource.data.name }}
                          </span>

                          <span class="fw-medium pe-80" v-else>
                            {{ resource.tourDayItemResources[0].roomTypeSlug }}
                            <span style="color: red">(Remove this and add appropriate room type)</span>
                          </span>

                          <span v-if="algo" class="text-xs ms-4">
                            {{ $t("general.shared.pax") }}:
                            {{
                              resource.tourDayItemResources[0].roomTypeSlug
                                ? resource.tourDayItemResources[0].pax
                                : resourceItemTotalResourcePax(resource.data.id)
                            }}
                            -
                            {{ $t("general.shared.quantityShort") }}:
                            {{ resource.quantity || resource.tourDayItemResources.length }}
                          </span>
                        </span>
                      </button>
                    </div>

                    <div
                      :id="`collapseSupplierResource${resource.data.id}${resource.data.roomTypeSlug}`"
                      :aria-labelledby="`headingSupplierResource${resource.data.id}${resource.data.roomTypeSlug}`"
                      :data-bs-parent="`#accordionSupplierResource${resource.data.id}${resource.data.roomTypeSlug}`"
                      class="accordion-collapse collapse"
                    >
                      <div class="p-8 accordion-body">
                        <div v-if="!shouldHideCheckbox(resource)">
                          <v-checkbox
                            v-model="groupByMode[resource.data.id]"
                            label="Group By"
                            class="mt-8 text-nowrap"
                            @click.prevent="toggleGroupByMode(resource.data.id)"
                          />
                        </div>
                        <template v-if="groupByMode[resource.data.id]">
                          <div
                            v-for="(tourDayItemResource, index) in resourceItemGroupResource(resource)"
                            :key="`tour-day-item-resource-${index}`"
                            class="p-12 mb-8 border rounded-xs gx-8"
                          >
                            <div class="d-flex justify-content-between align-items-center">
                              <div
                                class="text-sm fw-medium"
                                :class="{ 'fw-bold text-md': tourDayItemResource.id.length > 1 }"
                              >
                                {{ resource.data.name }}
                              </div>
                              <div
                                class="text-sm fw-medium"
                                :class="{ 'fw-bold text-md': tourDayItemResource.id.length > 1 }"
                              >
                                {{ $t("general.shared.quantityShort") }}:{{ tourDayItemResource.id.length }}
                              </div>
                              <!-- TODO: Dissucs about id -->
                              <!-- <v-button
                                size="sm"
                                variant="icon"
                                @click="removeTourDayItemResource(tourDayItemResource.id, true)"
                              >
                                <v-icon icon="trash" size="sm" />
                              </v-button> -->
                            </div>

                            <the-tour-day-item-details-resource-form
                              v-if="!!tourDayItemResource"
                              :agency="agency"
                              :client-tour-id="clientTourId"
                              :data="tourDayItemResource"
                              :index="index"
                              :init-date="currentTourDayItem.tourDay.date"
                              :status="status"
                              :tour-day-id="currentTourDayItem.tourDayId"
                              :tour-day-item-id="currentTourDayItem.id"
                              :tour-id="tourId"
                              :type="currentTourDayItem.type"
                              :group-by-mode="groupByMode[resource.data.id]"
                              :update-view="true"
                              @saved="setItemResourcePax(tourDayItemResource)"
                              @refresh-resource-data="tourDayItemResource = $event"
                              @emit-reload-tour="
                                $emit('emitReloadTour');
                                $emit('openItemDetailsOffCanvas', $event);
                              "
                            />

                            <div class="mb-16 search-separator-container pb16">
                              <div class="separator-text-container" />
                            </div>

                            <the-tour-day-item-details-resource-reservation-form
                              :agency="agency"
                              :client-tour-id="clientTourId"
                              :resource="tourDayItemResource"
                              :tour-day-id="currentTourDayItem.tourDayId"
                              :tour-day-item-id="currentTourDayItem.id"
                              :tour-id="tourId"
                              @emit-reload-tour="$emit('emitReloadTour')"
                            />
                          </div>
                        </template>
                        <template v-else>
                          <div
                            v-for="(tourDayItemResource, index) in resource.tourDayItemResources"
                            :key="`tour-day-item-resource-${tourDayItemResource.id}`"
                            class="p-12 mb-8 border rounded-xs gx-8"
                          >
                            <div class="d-flex justify-content-between align-items-center">
                              <div class="text-sm fw-medium">
                                {{ resource.data.name }}
                                <span v-if="resource.tourDayItemResources.length > 1" class="text-xs fw-normal">
                                  (#{{ index + 1 }})
                                </span>
                              </div>

                              <v-button
                                size="sm"
                                variant="icon"
                                @click="removeTourDayItemResource(tourDayItemResource.id, true)"
                              >
                                <v-icon icon="trash" size="sm" />
                              </v-button>
                            </div>

                            <the-tour-day-item-details-resource-form
                              v-if="!!tourDayItemResource"
                              :agency="agency"
                              :client-tour-id="clientTourId"
                              :data="tourDayItemResource"
                              :index="tourDayItemResource.id"
                              :init-date="currentTourDayItem.tourDay.date"
                              :status="status"
                              :tour-day-id="currentTourDayItem.tourDayId"
                              :tour-day-item-id="currentTourDayItem.id"
                              :tour-id="tourId"
                              :type="currentTourDayItem.type"
                              :update-view="true"
                              :group-by-mode="groupByMode[resource.data.id]"
                              @saved="setItemResourcePax(tourDayItemResource)"
                              @refresh-resource-data="tourDayItemResource = $event"
                              @emit-reload-tour="
                                $emit('emitReloadTour');
                                loadTourData();
                              "
                            />

                            <div class="mb-16 search-separator-container pb16">
                              <div class="separator-text-container" />
                            </div>

                            <the-tour-day-item-details-resource-reservation-form
                              :agency="agency"
                              :client-tour-id="clientTourId"
                              :resource="tourDayItemResource"
                              :tour-day-id="currentTourDayItem.tourDayId"
                              :tour-day-item-id="currentTourDayItem.id"
                              :tour-id="tourId"
                              @emit-reload-tour="$emit('emitReloadTour')"
                            />
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </fieldset>
        </template>
        <template v-else>
          <fieldset>
            <div class="py-8 selected d-center">
              <div class="mb-20 detail-title d-flex justify-content-between align-items-center">
                <div class="fw-medium">{{ $t("resource.basicInformation") }}</div>

                <v-button class="p-4 btn-edit" variant="icon" @click="openOffCanvas('the-resource-details-offcanvas')">
                  <v-icon icon="pencil" />
                </v-button>
              </div>
              <template v-for="(lang, index) in stageData?.translations" :key="index">
                <div class="py-4 text-sm d-flex" v-if="currentLanguage === lang?.locale">
                  <span class="me-16 fw-medium">{{ $t("general.shared.title") }}: </span>
                  <span>{{ lang?.title }}</span>
                </div>
              </template>
              <div class="py-4 text-sm d-flex">
                <span class="me-16 fw-medium">{{ $t("general.shared.location") }}: </span>
                <span>
                  {{ stageData?.city }}
                  <v-icon v-if="!!stageData?.destination" icon="arrow-right" size="xs" space="px-8" />
                  {{ stageData?.destination }}
                </span>
              </div>
              <div class="py-4 text-sm d-flex" v-if="!!stageData?.types">
                <span class="me-16 fw-medium">{{ $t("general.shared.types") }}: </span>
                <span>{{ JSON.parse(stageData?.types)[0] }}</span>
              </div>
              <div class="py-4 text-sm d-flex">
                <span class="info-title fw-medium">{{ $t("general.shared.distanceKm") }} : </span>
                <span v-if="stageData?.stageLength">{{ stageData?.stageLength }}</span>
                <span v-else>-</span>
              </div>
              <template v-for="(lang, index) in stageData?.translations" :key="index">
                <div class="py-4 text-sm d-flex" v-if="currentLanguage === lang?.locale">
                  <span class="me-16 fw-medium">{{ $t("general.shared.description") }}: </span>
                  <span v-html="lang?.description" />
                </div>
              </template>
              <div class="mb-8 search-separator-container">
                <div class="separator-text-container">
                  <span class="text-sm separator-text">{{ $t("general.shared.links") }}</span>
                </div>
              </div>
              <div v-if="stageData?.links" class="py-4 text-sm d-flex flex-column">
                <span class="me-16 fw-medium">{{ $t("general.shared.links") }}: </span>
                <template v-for="url in JSON.parse(stageData?.links)" :key="url">
                  <a :href="url" class="resource-link btn-link" rel="noopener noreferrer" target="_blank">
                    {{ url }}
                  </a>
                </template>
              </div>
            </div>
          </fieldset>
        </template>
      </div>
    </form>
    <!-- TODO: Temporary comment this code block -->
    <!-- <fieldset class="mb-24">
      <div class="form-outline">
        <label class="label">
          <span>{{ $t("general.shared.supplier_observations") }}</span>
        </label>
      </div>
      <v-html-editor
        :id="`tour-day-item-resource-supplier-update-observations`"
        ref="tour-day-item-resource-supplier-observations-ref"
        :label="$t('general.shared.supplier_observations')"
        :value="observations"
        :height="'250px'"
        readonly="true"
        disabled="disabled"
      />
    </fieldset> -->

    <the-supplier-details-rates-off-canvas
      v-if="offCanvasToShow === 'the-supplier-details-rates-off-canvas'"
      :supplier-id="supplier.id"
      @closed="closeOffCanvas"
    />

    <the-supplier-create-offcanvas
      v-if="offCanvasToShow === 'the-supplier-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-suppliers="closeOffCanvas"
      @open-details="supplier = $event"
    />

    <the-supplier-details-offcanvas
      v-if="offCanvasToShow === 'the-supplier-details-offcanvas'"
      :is-archived="false"
      :is-double-off-canvas="false"
      :supplier-data="supplier"
      @closed="
        closeOffCanvas();
        loadSupplierSelected();
      "
    />
    <the-resource-details-offcanvas
      v-if="offCanvasToShow === 'the-resource-details-offcanvas'"
      :is-archived="false"
      :resource-data="stageData"
      tour-builder="tourBuilder"
      @closed="closeOffCanvas()"
      @reload-resources="
        closeOffCanvas();
        $emit('emitReloadTour');
      "
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { VCheckbox } from "@uniqoders/form";
import { DateTime } from "luxon";
import { mapState } from "pinia";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";
import VLoader from "@/components/vendor/loader/VLoader.vue";
import Supplier from "@/api/objects/Supplier";
import { useUserStore } from "@/stores/user";
import api from "@/api";
import SupplierResource from "@/api/objects/SupplierResource";
import VButton from "@/components/vendor/basic/button/VButton.vue";
import TheTourDayItemDetailsResourceForm from "@/components/tour/TheTourDayItemDetailsResourceForm.vue";
import TourDayItemResource from "@/api/objects/TourDayItemResource";
import TheSupplierDetailsRatesOffCanvas from "@/components/supplier/parts/TheSupplierDetailsRatesOffCanvas.vue";
import useOffCanvasUtils from "@/helpers/OffCanvasUtils";
import TheTourDayItemDetailsResourceReservationForm from "@/components/tour/TheTourDayItemDetailsResourceReservationForm.vue";
import TheSupplierCreateOffcanvas from "@/components/supplier/TheSupplierCreateOffcanvas.vue";
import TheSupplierDetailsOffcanvas from "@/components/supplier/TheSupplierDetailsOffcanvas.vue";
import TheResourceDetailsOffcanvas from "@/components/resource/TheResourceDetailsOffcanvas.vue";
import { useAppStore } from "@/stores/app";

export default defineComponent({
  name: "TheTourDayItemDetailsUpdateForm",
  components: {
    TheSupplierDetailsOffcanvas,
    TheSupplierCreateOffcanvas,
    TheTourDayItemDetailsResourceReservationForm,
    TheSupplierDetailsRatesOffCanvas,
    TheTourDayItemDetailsResourceForm,
    VLoader,
    VIcon,
    VButton,
    VCheckbox,
    TheResourceDetailsOffcanvas,
  },
  emits: ["closed", "emitReloadTour", "deletedItemResource", "openItemDetailsOffCanvas"],
  props: {
    agency: {
      type: String,
      required: true,
    },
    clientTourId: {
      type: Number,
      required: true,
    },
    tourId: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  setup() {
    const userStore = useUserStore();

    return {
      userStore,
      ...useOffCanvasUtils(),
    };
  },
  data() {
    return {
      resourceKey: 0 as number,
      supplier: {} as Supplier,
      supplierName: "" as string,
      resources: [] as SupplierResource[],
      resourcesMapped: [] as SupplierResource[],
      suppliersStatus: "loading" as string,
      tourDayItemResourcesStatus: "loading" as string,
      name: "" as string,
      resourcesSelected: [] as Record<string, any>[],
      resourcesNextStep: false as boolean,
      isDirty: false as boolean,
      filters: {
        name: "" as string,
        type: "" as string,
      } as Record<string, any>,
      timers: {
        name: null as any,
      } as Record<string, any>,
      algo: true as boolean,
      currentLanguage: localStorage.getItem("language"),
      observations: "" as string,
      groupByMode: {} as { [key: number]: boolean },
      updatingTourStatus: false as boolean,
    };
  },
  computed: {
    ...mapState(useAppStore, ["settings"]),
    ...mapState(useUserStore, ["currentTourDayItem"]),
    stageData() {
      return this.currentTourDayItem?.resources[0]?.resource;
    },
  },
  async created() {
    this.setTourDayItemData();
    await this.loadSupplierSelected();
    this.resourcesSelected.forEach((resource) => {
      this.groupByMode[resource.data.id] = true;
    });
  },
  methods: {
    async setItemResourcePax(tourDayItemResource: any) {
      for (const resourceSelected of this.resourcesSelected) {
        const resource = resourceSelected.tourDayItemResources.find(
          (resource: any) => resource.id === tourDayItemResource.id
        );

        if (resource) {
          resource.pax = tourDayItemResource.pax;
        }
      }
    },
    checkExistRoomType(supplierRoomTypeSlug: string | any): Record<string, any> | undefined {
      return this.resourcesSelected.find((resourceAux) => resourceAux.data.roomTypeSlug === supplierRoomTypeSlug);
    },
    setTourDayItemData() {
      if (!!this.currentTourDayItem) {
        this.resourcesSelected = [];

        this.currentTourDayItem.resources.forEach((resource) => {
          const resourceExist = this.checkExistResource(resource.modelId);
          const roomtypeSulgExist = this.checkExistRoomType(resource.roomTypeSlug);

          if (!!resourceExist && !!roomtypeSulgExist) {
            resourceExist.tourDayItemResources.push(resource);

            resourceExist.quantity += 1;
          } else {
            this.resourcesSelected.push({
              data: {
                id: resource.resource.id,
                name: resource.resource.name,
                roomTypeSlug: resource.roomTypeSlug,
              },
              quantity: resource.quantity || 1,
              tourDayItemResources: [resource],
            });
          }
        });

        this.tourDayItemResourcesStatus = "loaded";
      }
    },
    async loadTourData() {
      const data = JSON.parse(localStorage.getItem("currentTourDayItem") as string);

      this.$emit("openItemDetailsOffCanvas", data);
    },
    mapResources() {
      this.resources = this.supplier.resources.map((resourceAux) => {
        const resourceExist = this.checkExistResource(resourceAux.id);
        if (resourceAux.type === "hotel" && resourceAux.roomTypeSlug) {
          const roomtypeSulgExist = this.checkExistRoomType(resourceAux.roomTypeSlug);
          resourceAux.check = !!resourceExist && !!roomtypeSulgExist;
        } else {
          resourceAux.check = !!resourceExist;
        }

        return resourceAux;
      });
    },
    async loadSupplierSelected() {
      if (!!this.currentTourDayItem) {
        try {
          const resource = this.currentTourDayItem.resources[0];
          let supplierId = 0;

          if (resource.modelType === "supplier_resource") {
            supplierId = resource.resource.supplierId;
          } else if (resource.modelType === "supplier") {
            supplierId = resource.modelId;
          }

          if (supplierId !== 0) {
            this.supplier = await api.supplier.retrieve(supplierId);
          }

          this.mapResources();

          this.supplierName = !!this.supplier.name ? this.supplier.name : "-";
        } catch (e: any) {
          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        }
      }
      const data = this.resourcesSelected?.flatMap((resourceSelected: any) =>
        resourceSelected?.tourDayItemResources
          ?.filter((resource: any) => resource?.observations !== null)
          ?.map((resource: any) => resource?.observations)
      );
      this.observations = data[0];
    },
    setResource(resource: SupplierResource | any) {
      if (!resource.check) {
        this.addResource(resource);
        resource.check = true;
      } else {
        this.deleteModalTourDayItemResource(resource);
      }
    },
    async removeResource(resource: any) {
      const resourceExist = this.checkExistResource(resource.id);
      await this.removeTourDayItemResource(resourceExist.tourDayItemResources[0].id, true)
    },
    async addResource(resource: SupplierResource | any) {
      if (!!this.currentTourDayItem) {

        let pax;

        switch (resource.type) {
          case "hotel":
            if (resource?.name?.includes("Single")) {
              pax = 1;
            } else if (resource?.name?.includes("Double")) {
              pax = 2;
            } else if (resource?.name?.includes("Triple")) {
              pax = 3;
            } else {
              pax = this.currentTourDayItem.tourDay.pax;
            }
            break;

          case "transfer":
            pax = this.currentTourDayItem.tourDay.pax;
            break;

          default:
            pax = 1;
            break;
        }

        try {
          const defaultEndDate = DateTime.fromISO(this.currentTourDayItem.tourDay.date)
            .plus({ days: 1 })
            .toFormat("yyyy-MM-dd");

          const data: Record<string, any> = {
            pax: pax,
            end_date: defaultEndDate,
            model_type: "supplier_resource",
            model_id: resource.id,
          };

          const response: TourDayItemResource = await api.tourDayItemResource.create(
            this.agency,
            this.clientTourId,
            this.tourId,
            this.currentTourDayItem.tourDay.id,
            this.currentTourDayItem.id,
            data
          );

          this.$toast.success(this.$t("general.shared.savedChanges"));

          const item = {
            agency: this.agency,
            clientTourId: this.clientTourId,
            tourId: this.tourId,
            tourDayId: this.currentTourDayItem.tourDayId,
            tourDayItemId: this.currentTourDayItem.id,
          };

          await this.userStore.setCurrentTourDayItem(item);

          const tourDayItemResource: Record<string, any> = {
            id: response.id,
            endDate: response.endDate,
            pax: response.pax,
            resource: {
              type: response.resource.attributes.type,
            },
          };


          const resourceExist = this.checkExistResource(resource.id);

          if (!!resourceExist) {
            resourceExist.tourDayItemResources.push(tourDayItemResource);

            resourceExist.quantity += 1;
          } else {
            this.resourcesSelected.push({
              data: {
                id: resource.id,
                name: resource.name,
              },
              quantity: 1,
              tourDayItemResources: [tourDayItemResource],
            });
          }
        } catch (e: any) {
          console.error(e);

          this.$toast.error(e.response.data.message);
        }
      }
    },
    checkExistResource(supplierResourceId: number | any): Record<string, any> | undefined {
      return this.resourcesSelected.find((resourceAux) => resourceAux.data.id === supplierResourceId);
    },
    resourceItemTotalResourcePax(resourceId: number): number {
      const resource = this.resourcesSelected.find((resourceAux) => resourceAux.data.id === resourceId);
      if (!!resource && resource.tourDayItemResources.length > 0) {
        let totalPax = 0;

        resource.tourDayItemResources.forEach((tourDayItemResource: Record<string, any>) => {
          if (tourDayItemResource?.resource?.type === "transfer" || tourDayItemResource?.resource?.type === "hotel") {
            totalPax += Number(tourDayItemResource.pax);
          } else {
            totalPax += 1;
          }
        });

        return totalPax;
      }

      return 0;
    },
    async toggleGroupByMode(resourceId: number) {
      if (typeof this.groupByMode[resourceId] === "undefined") {
        // Add a new property to groupByMode and make it reactive
        this.groupByMode[resourceId] = true;
      } else {
        // Toggle the group by mode for the specified resource ID
        this.groupByMode[resourceId] = !this.groupByMode[resourceId];
      }
    },
    resourceItemGroupResource(resourceData: any) {
      const resource = this.resourcesSelected.find((resourceAux) => resourceAux.data.id === resourceData.data.id);

      const grouped = resource?.tourDayItemResources?.reduce((acc: any, cur: any) => {
        const resourceId = cur?.resource?.id;
        const key = `${cur.pax}-${cur.observations}-${cur.clientObservations}-${cur.roomTypeSlug}`;

        if (!acc[resourceId]) {
          acc[resourceId] = {};
        }

        const matchingResourceData = resourceData.tourDayItemResources.find((data: any) => {
          return (
            cur.pax === data.pax &&
            cur.observations === data.observations &&
            cur.clientObservations === data.clientObservations &&
            cur.roomTypeSlug === data.roomTypeSlug
          );
        });

        if (matchingResourceData) {
          if (!acc[resourceId][key]) {
            acc[resourceId][key] = { ...cur, id: [cur.id], pax: cur.pax };
          } else {
            acc[resourceId][key].id.push(cur.id);
            if (cur?.resource?.type !== "transport") {
              acc[resourceId][key].pax += cur.pax;
            } else {
              acc[resourceId][key].pax = cur.pax;
            }
          }
        }
        return acc;
      }, {});

      const result = [];
      for (const resourceId in grouped) {
        for (const key in grouped[resourceId]) {
          result.push(grouped[resourceId][key]);
        }
      }
      return result.toReversed();
    },
    shouldHideCheckbox(resource: any) {
      const groupedResources = this.resourceItemGroupResource(resource);
      const allSingleId = groupedResources.every((item) => item.id.length === 1);
      if (allSingleId) {
        this.groupByMode[resource.data.id] = false;
        return true;
      }
      return false;
    },
    async deleteModalTourDayItemResource(resource: any) {
      if (!!this.currentTourDayItem) {
        const resourceExist = this.resourcesSelected.find((resourceAux) => resourceAux.data.id === resource.id);

        await this.$modal.delete({
          title: this.$t("tourBuilder.deleteTourDayItemResourceTitle"),
          text: this.$t("tourBuilder.deleteTourDayItemResourceText", {
            resource: resourceExist?.data.name,
            date: this.$str.formatDateTime(this.currentTourDayItem.tourDay.date, this.settings.formatDate),
          }),
          deleteButtonText: this.$t("general.button.delete"),
          rightButtonClasses: "btn-tertiary",
          deleteButtonCallback: () => this.doDeleteAllTourDayItemResources(resourceExist, resource),
        });
      }
    },
    async doDeleteAllTourDayItemResources(resource: any, resourceOriginal: any) {
      if (!!this.currentTourDayItem) {
        // eslint-disable-next-line no-restricted-syntax
        for (const tourDayItemResource of resource.tourDayItemResources) {
          // eslint-disable-next-line no-await-in-loop
          await this.removeTourDayItemResource(tourDayItemResource.id, false);
        }

        resourceOriginal.check = false;

        const item = {
          agency: this.agency,
          clientTourId: this.clientTourId,
          tourId: this.tourId,
          tourDayId: this.currentTourDayItem.tourDayId,
          tourDayItemId: this.currentTourDayItem.id,
        };

        this.$emit("deletedItemResource");

        await this.userStore.setCurrentTourDayItem(item);

        if (!!this.currentTourDayItem.resources && this.currentTourDayItem.resources.length > 0) {
          this.setTourDayItemData();
        }
      }
    },
    async removeTourDayItemResource(tourDayItemResource: number, refreshCurrentTourDayItem = true) {
      if (!!this.currentTourDayItem) {
        try {
          await api.tourDayItemResource.delete(
            this.agency,
            this.clientTourId,
            this.tourId,
            this.currentTourDayItem.tourDay.id,
            this.currentTourDayItem.id,
            tourDayItemResource
          );

          this.$toast.success(this.$t("general.shared.savedChanges"));

          if (refreshCurrentTourDayItem) {
            const item = {
              agency: this.agency,
              clientTourId: this.clientTourId,
              tourId: this.tourId,
              tourDayId: this.currentTourDayItem.tourDayId,
              tourDayItemId: this.currentTourDayItem.id,
            };

            this.$emit("deletedItemResource");

            await this.userStore.setCurrentTourDayItem(item);

            this.setTourDayItemData();
            this.mapResources();
          }
        } catch (e: any) {
          console.error(e);

          this.$toast.error(e.response.data.message);
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.accordion-item {
  background-color: transparent;
}

.accordion .accordion-item .accordion-button {
  font-size: 1rem;
}

.accordion-button,
.accordion-button:not(.collapsed) {
  background-color: transparent;
}
</style>
