export default {
    greeting: "Hey",
    hope_you_are_fine: "Hope you are doing fine!",
    email_subject: "Subject:",
    client: "Client:",
    case: "Case:",
    room: "Room:",
    quantity: "Quantity:",
    check_in_date: "Check-in Date:",
    check_out_date: "Check-out Date:",
    nights: "Nights:",
    number_of_people: "Number of People:",
    observations: "Observations:",
    understanding: "Thank you for your cooperation.",
    request: "We want to *Request* this booking made in your accommodation:",
    mofication: "We want to *Modify* this booking made in your accommodation:",
    reminder: "We want to *Cancel* this booking made in your accommodation:",
    invoice_request: "We want to *Request* an invoice for this booking made in your accommodation:",
    payment_information: "We want to *Request* payment information for this booking made in your accommodation:",
    email_warning: "Warning",
    price_warning: "Are you sure that the price is 0?",
    free_text_warning: "The free text can not be empty.",
}
