export default {
  cases: 'Casos',
  noCases: 'No hay casos',
  createNewCase: 'Crear nuevo caso',
  createNewProduct: 'Crear nueva actividad',
  activityCreatedFor: 'Actividad creada para',
  archivedCases: 'Casos archivados',
  activeCases: 'Casos activos',
  basicInformation: 'Información básica',
  details: 'Detalles',
  caseDetails: 'Detalles del caso',
  detailsSubtitle: 'Aquí se encuentra la información general del caso',
  media: 'Medios',
  noMedia: 'No hay medios asociados',
  archivedText: 'Este caso está archivado, si lo deseas puedes restaurarlo haciendo clic en el botón \'Restaurar\'.',
  archiveTitle: 'Archivar caso',
  archiveSubtitle: 'Puedes archivar el caso aquí',
  archiveText: 'Puedes archivar un caso, si lo haces, no aparecerá en las búsquedas de la aplicación. Podrás verlo en la lista de casos si lo filtras por \'archivado\'. Una vez que veas los detalles de un caso archivado, puedes eliminarlo definitivamente o desarchivarlo.',
  archiveAlertText: '¿Estás seguro de que deseas archivar el caso \'{ case }\'? Si lo archivas, no aparecerá en las búsquedas.',
  deleteText: 'Este caso está archivado, si deseas eliminarlo permanentemente, haz clic en el botón \'Eliminar\'. Ten en cuenta que si lo eliminas, este caso dejará de existir y no se podrá recuperar.',
  deleteClientTourTitle: 'Eliminar caso',
  deleteClientTourText: '¿Estás seguro de que deseas eliminar el caso \'{ clientTour }\'? Si lo eliminas, no podrás recuperarlo.',
  restoreClientTourTitle: 'Restaurar caso',
  restoreClientTourText: '¿Estás seguro de que deseas restaurar el caso \'{ clientTour }\'? Si lo restauras, ya no estará archivado y aparecerá en las búsquedas de la aplicación.',
  caseStatus: 'Estado del caso',
  client: 'Cliente',
  createClient: 'Crear nuevo cliente',
  createSupplier: 'Crear nuevo proveedor',
  createResource: 'Crear nuevo recurso',
  products: 'Actividades',
  templateNoOptions: 'No hay actividades disponibles',
  locationNoOptions: 'No hay ubicaciones en esta actividad',
  chooseTemplate: 'Elige una actividad',
  orStartScratch: 'o empezar desde cero',
  participantsDetails: 'Detalles de los participantes',
  rooming: 'Alojamiento',
  howToDoIt: 'Cómo hacerlo',
  extraDaysAtStart: 'Días extra al inicio',
  extraDaysAtEnd: 'Días extra al final',
  howWillYourPath: '¿Cómo será tu camino?',
  extraDays: 'Días extra',
  pax: 'Pasajeros',
  paxSubtitle: 'Pasajeros del caso',
  choosingProductOptional: 'La elección de una actividad es opcional, puedes crear el caso desde cero.',
  caseType: 'Tipo de caso',
  travelType: 'Tipo de viaje',
  paxSendEmailText: 'Enviar un correo electrónico al cliente con un enlace para que todos los viajeros completen un formulario con sus datos.',
  sendEmail: 'Enviar correo electrónico',
  tourBasicInformation: 'Información básica del Tour',
  travelers: 'Viajeros',
  noTravelers: 'No se han agregado viajeros',
  traveler: {
    update: 'Actualizar viajero',
    removeTitle: 'Eliminar viajero',
    removeText: '¿Está seguro de eliminar \'{ name }\' como pasajero de este tour?',
  },
  invoicesReceived: 'Facturas recibidas',
  invoicesReceivedSubtitle: 'Facturas recibidas del caso',
  invoicesEmitted: 'Facturas emitidas',
  invoicesEmittedSubtitle: 'Facturas emitidas del caso',
  invoice: {
    noInvoicesReceived: 'No hay facturas recibidas',
    noInvoicesEmitted: 'No hay facturas emitidas',
    removeTitle: 'Eliminar archivo de factura',
    removeText: '¿Está seguro de eliminar la factura \'{ name }\' de este tour?',
    number: 'Número',
    amount: 'Importe',
    basicInformation: 'Información básica',
    invoiceDetails: 'Detalles de la factura',
    invoiceLines: 'Líneas de la factura',
    quantity: 'Cantidad',
    nif: 'NIF',
    vat: 'Número de IVA',
    deleteTitle: 'Eliminar factura',
    deleteText: '¿Está seguro de eliminar la factura \'{ invoice }\'? Si la elimina, no podrá recuperarla.',
  },
  tourStatuses: 'Estados del caso',
  statuses: 'Estados',
  reservations: 'Reservas',
  payments: 'Pagos',
  invoices: 'Facturas',
  contact: {
    contacts: 'Contactos',
    contactsSubtitle: 'Aquí podrás añadir la información de contacto para la vista previa y presupuesto del caso.',
    contactInformation: 'Información de contacto',
  },
  dateModal: {
    title: '¿Estás seguro de que deseas modificar la fecha de inicio?',
    text: 'Este ajuste modificará las fechas de todo el itinerario. Por favor, verifica que no existan reservas solicitadas o confirmadas, ya que también se verán afectadas por este cambio.',
  },
  sendNotification: 'Enviar notificación',
  notification: 'Notificación',
  noResourcesSelectedMessage: 'Selecciona recursos para poder mandar las notificaciones',
  sendNotificationSubtitle: 'Enviar correo al cliente',
  sendClientAndPaxs: 'Enviar notificación al cliente y a los pasajeros',
  sendOnlyClient: 'Envíar notificación sólo al cliente',
  termsConditions: 'Términos  & Condiciones',
  termsSubtitle: 'Aquí podrás añadir los términos y condiciones del caso.',
  complementaryText: 'Extras',
  complementarySubtitle: 'Aquí puedes añadir detalles adicionales y explicativos para el caso.',
  afterTour: 'Después de tu viaje',
  afterTourSubtitle: 'Aquí puedes anadir un texto que se mostrará tras el viaje.',
  beforeTour: 'Antes de tu viaje',
  beforeTourSubtitle: 'Aquí puedes anadir un texto que se mostrará antes del viaje.',
  include: 'Incluido',
  notInclude: 'No Incluido',
  optional: 'Optional',
  quotes: 'Presupuestos',
  quoteOptionalText: 'Añadir recursos opcionales al presupuesto',
  quotePriceText: 'Indique en el presupuesto el precio total o el precio por persona.',
  quoteTotalPrice: 'Precio total',
  quotePricePerPerson: 'Precio por persona',
  optionals: 'Opcionales',
  noQuotes: 'No hay presupuestos',
  featuredImage: 'Imágen destacada',
  notificationLog: 'Registro de notificaciones a proveedores',
  clientNotificationLog: 'Registro de notificaciones a clientes',
  notificationLogSubtitle: 'Aquí está el historial de notificaciones a proveedores.',
  noClientNotifications: 'No hay notificaciones a clientes',
  noSuppliersNotifications: 'No hay notificaciones a proveedores',
  paxsInformation: 'Información sobre los pasajeros',
  tourName: 'Nombre del Tour',
  emailModal: {
    title: 'Enviar correo electrónico',
    text: '¿Estás seguro de que quieres enviar el correo electrónico al cliente?',
  },
  additionalInfo: 'Información adicional',
  additionalRequirements: 'Requisitos adicionales',
  otherRoomRequirements: 'Otros requisitos de habitación',
};
