<template>
  <template v-if="sucessEmailUpdated">
    <the-confirm-price-reservation :status="status" />
  </template>
  <template v-else-if="emailUpdated && status === 'loaded'">
    <the-email-already-update />
  </template>
  <div v-else>
    <div class="supplier-container d-flex" v-if="!emailUpdated && emailUpdated !== null && status === 'loaded'">
      <div
        v-for="(supplierName, index) in supplierData"
        :key="index"
        class="supplier-data p-8 ps-0 ps-xl-12 p-xl-12 w-100"
      >
        <div class="d-flex justify-content-between align-items-center accordion-toggle d-flex-wrap gx-12 w-100">
          <div class="td w-80">
            <div class="d-flex align-items-center">
              <div class="image-container rounded-sm bg-tertiary me-12">
                {{ capitalizeFirstLetter(supplierName.name) }}
              </div>

              <div class="py-12 fw-medium">{{ supplierName.name }}</div>
            </div>
          </div>
        </div>
        <div class="accordion-body collapse show p-0">
          <div class="resources-profit-header d-none d-lg-block">
            <div class="table-container">
              <div class="row-header d-flex w-100 text-sm">
                <div class="th w-20">
                  {{ $t("general.shared.name") }}
                </div>
                <div class="w-10 th">{{ $t("general.shared.checkIn") }}</div>
                <div class="w-10 th">{{ $t("general.shared.checkOut") }}</div>
                <div class="w-10 th ps-0 ps-xxl-8 text-center">
                  {{ $t("tourPricing.nights") }}
                </div>
                <div class="w-10 th text-center">{{ $t("general.shared.quantityShort") }}</div>
                <div class="w-20 th text-center">{{ $t("tourPricing.netPrice") }}/{{ $t("general.shared.unit") }}</div>
                <div class="w-20 th">{{ $t("travelBook.totalPrice") }}</div>
              </div>
              <template v-for="(item, index) in supplierName.suppliers" :key="index">
                <div
                  v-for="(newItem, itemResourceIndex) in item.itemResourcesGrouped"
                  :key="itemResourceIndex"
                  class="wrapper"
                  :class="{ 'bg-gray-200': itemResourceIndex % 2 !== 0 }"
                >
                  <div class="row-data d-flex w-100">
                    <div class="th w-20 d-flex flex-column product-info">
                      <div>
                        <span>{{ supplierNameByLange(newItem?.translations) }}</span>
                      </div>
                      <template v-if="newItem?.clientObservations">
                        <span class="p-16" v-html="newItem?.clientObservations" />
                      </template>
                    </div>
                    <div class="w-10 th product-info">
                      {{ $str.formatDateTime(newItem.date, settings.formatDate) }}
                    </div>
                    <div class="w-10 th product-info">
                      {{ $str.formatDateTime(newItem.endDate, settings.formatDate) }}
                    </div>
                    <div class="th w-10 product-info text-center">
                      <span class="w-100">
                        {{ calculateNights(newItem.date, newItem.endDate) }}
                      </span>
                    </div>
                    <div class="w-10 th product-info text-center">
                      <span class="w-100">
                        {{ newItem.quantity }}
                      </span>
                    </div>
                    <div class="w-20 th product-info text-center">
                      <template v-if="showModificationPrice">
                        <v-form-input
                          id="pricing-net-price"
                          v-model="newItem.supplierPrice"
                          autocomplete="off"
                          form-type="input-group"
                          onwheel="return false;"
                          right-addon="€"
                          step=".01"
                          type="number"
                          @blur="updateItemPriceResource(newItem)"
                          :class="{ 'custom-input-style': showModificationPrice }"
                          @keyup.enter="$event.target.blur()"
                        />
                      </template>
                      <template v-else>
                        <span class="w-100">
                          {{ newItem.supplierPrice }}
                        </span>
                      </template>
                    </div>
                    <div class="w-20 th product-info">
                      <v-form-input
                        id="pricing-net-price"
                        :modelValue="newItem.supplierPrice * newItem.quantity"
                        disabled="true"
                        autocomplete="off"
                        form-type="input-group"
                        onwheel="return false;"
                        right-addon="€"
                        step=".01"
                        type="number"
                      />
                    </div>
                    <div
                      v-if="newItem.resources.length > 1"
                      :aria-controls="`collapse-tour-day-item-resource-${newItem.id}-dropdown`"
                      :data-bs-target="`#collapse-tour-day-item-resource-${newItem.id}-dropdown`"
                      aria-expanded="false"
                      class="accordion-toggle collapsed"
                      data-bs-toggle="collapse"
                    >
                      <v-icon class="accordion-icon" icon="chevron-up" size="xl" />
                    </div>
                  </div>
                  <div
                    v-if="newItem.resources.length > 1"
                    :id="`collapse-tour-day-item-resource-${newItem.id}-dropdown`"
                    class="accordion-body collapse p-0 accordion-sub-body"
                  >
                    <div
                      v-for="(newResource, index) in newItem.resources"
                      :key="index"
                      class="row-data d-flex w-100 dashed"
                    >
                      <div class="th w-20 d-flex flex-column product-info">
                        <div>
                          <span>{{ supplierNameByLange(newResource?.translations) }}</span>
                        </div>
                        <template v-if="newResource?.clientObservations">
                          <div class="text-muted" v-html="newResource?.clientObservations" />
                        </template>
                      </div>
                      <div class="w-10 th product-info">
                        {{ $str.formatDateTime(newResource.date, settings.formatDate) }}
                      </div>
                      <div class="w-10 th product-info">
                        {{ $str.formatDateTime(newResource.endDate, settings.formatDate) }}
                      </div>
                      <div class="th w-10 product-info text-center">
                        <span class="w-100">
                          {{ calculateNights(newResource.date, newResource.endDate) }}
                        </span>
                      </div>
                      <div class="w-10 th product-info text-center">
                        <span class="w-100">
                          {{ newResource.pax }}
                        </span>
                      </div>
                      <div class="w-20 th product-info text-center">
                        <template v-if="showModificationPrice">
                          <v-form-input
                            id="pricing-net-price"
                            v-model="newResource.supplierPrice"
                            autocomplete="off"
                            form-type="input-group"
                            onwheel="return false;"
                            right-addon="€"
                            step=".01"
                            type="number"
                            @blur="updateItemPriceResource(newResource)"
                            @keyup.enter="$event.target.blur()"
                          />
                        </template>
                        <template v-else>
                          <span class="w-100">
                            {{ newResource.supplierPrice }}
                          </span>
                        </template>
                      </div>
                      <div class="w-20 th product-info">
                        <v-form-input
                          id="pricing-net-price"
                          :modelValue="newResource.supplierPrice * newResource.pax"
                          disabled="true"
                          autocomplete="off"
                          form-type="input-group"
                          onwheel="return false;"
                          right-addon="€"
                          step=".01"
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="d-lg-none">
            <div class="table-container">
              <template v-for="(item, index) in supplierName.suppliers" :key="index">
                <template v-for="(newItem, index) in item.itemResourcesGrouped" :key="index">
                  <div class="w-100 mb-16">
                    <div class="d-flex flex-column align-items-start fw-semi py-4">
                      <div>
                        <span>{{ $t("general.shared.name") }}:</span>
                        <span>{{ supplierNameByLange(newItem?.translations) }}</span>
                      </div>
                      <template v-if="newItem?.clientObservations">
                        <span class="text-muted" v-html="newItem?.clientObservations" />
                      </template>
                    </div>
                    <div class="d-flex align-items-center fw-semi py-4">
                      <span>{{ $t("general.shared.checkIn") }}:</span>
                      <span> {{ $str.formatDateTime(newItem.date, settings.formatDate) }}</span>
                    </div>
                    <div class="d-flex align-items-center fw-semi py-4">
                      <span>{{ $t("general.shared.checkOut") }}:</span>
                      <span>{{ $str.formatDateTime(newItem.endDate, settings.formatDate) }}</span>
                    </div>
                    <div class="d-flex align-items-center fw-semi py-4">
                      <span> {{ $t("tourPricing.nights") }}:</span>
                      <span>{{ calculateNights(newItem.date, newItem.endDate) }}</span>
                    </div>
                    <div class="d-flex align-items-center fw-semi py-4">
                      <span>{{ $t("general.shared.quantityShort") }}:</span>
                      <span>{{ newItem.quantity }}</span>
                    </div>
                    <template v-if="showModificationPrice">
                      <div class="d-flex flex-column align-items-start fw-semi py-4">
                        <span>{{ $t("tourPricing.netPrice") }}/{{ $t("general.shared.unit") }}:</span>
                        <span class="w-100">
                          <v-form-input
                            id="pricing-net-price"
                            v-model="newItem.supplierPrice"
                            autocomplete="off"
                            form-type="input-group"
                            onwheel="return false;"
                            right-addon="€"
                            step=".01"
                            type="number"
                          />
                        </span>
                      </div>
                    </template>
                    <template v-else>
                      <div class="d-flex align-items-center fw-semi py-4">
                        <span>{{ $t("tourPricing.netPrice") }}/{{ $t("general.shared.unit") }}:</span>
                        <span>
                          {{ newItem.supplierPrice }}
                        </span>
                      </div>
                    </template>
                    <div class="d-flex flex-column align-items-start fw-semi py-4">
                      <span>{{ $t("travelBook.totalPrice") }}:</span>
                      <span class="w-100">
                        <v-form-input
                          id="pricing-net-price"
                          :modelValue="newItem.supplierPrice * newItem.quantity"
                          disabled="true"
                          autocomplete="off"
                          form-type="input-group"
                          onwheel="return false;"
                          right-addon="€"
                          step=".01"
                          type="number"
                        />
                      </span>
                    </div>
                    <div
                      v-if="newItem.resources.length > 1"
                      :aria-controls="`collapse-tour-day-item-resource-${newItem.id}-dropdown`"
                      :data-bs-target="`#collapse-tour-day-item-resource-${newItem.id}-dropdown`"
                      aria-expanded="false"
                      class="accordion-toggle collapsed"
                      data-bs-toggle="collapse"
                    >
                      <v-icon class="accordion-icon" icon="chevron-up" size="xl" />
                    </div>
                  </div>
                  <div
                    v-if="newItem.resources.length > 1"
                    :id="`collapse-tour-day-item-resource-${newItem.id}-dropdown`"
                    class="accordion-body collapse p-0 accordion-sub-body"
                  >
                    <template v-for="(newResource, index) in newItem.resources" :key="index">
                      <div class="w-100 mb-16">
                        <div class="d-flex flex-column align-items-start fw-semi py-4">
                          <div>
                            <span>{{ $t("general.shared.name") }}:</span>
                            <span>{{ supplierNameByLange(newResource?.translations) }}</span>
                          </div>
                          <template v-if="newResource?.clientObservations">
                            <span class="text-muted" v-html="newResource?.clientObservations" />
                          </template>
                        </div>
                        <div class="d-flex align-items-center fw-semi py-4">
                          <span>{{ $t("general.shared.checkIn") }}:</span>
                          <span> {{ $str.formatDateTime(newResource.date, settings.formatDate) }}</span>
                        </div>
                        <div class="d-flex align-items-center fw-semi py-4">
                          <span>{{ $t("general.shared.checkOut") }}:</span>
                          <span>{{ $str.formatDateTime(newResource.endDate, settings.formatDate) }}</span>
                        </div>
                        <div class="d-flex align-items-center fw-semi py-4">
                          <span> {{ $t("tourPricing.nights") }}:</span>
                          <span>{{ calculateNights(newResource.date, newResource.endDate) }}</span>
                        </div>
                        <div class="d-flex align-items-center fw-semi py-4">
                          <span>{{ $t("general.shared.quantityShort") }}:</span>
                          <span>{{ newResource.quantity }}</span>
                        </div>
                        <template v-if="showModificationPrice">
                          <div class="d-flex flex-column align-items-start fw-semi py-4">
                            <span>{{ $t("tourPricing.netPrice") }}/{{ $t("general.shared.unit") }}:</span>
                            <span class="w-100">
                              <v-form-input
                                id="pricing-net-price"
                                v-model="newResource.supplierPrice"
                                autocomplete="off"
                                form-type="input-group"
                                onwheel="return false;"
                                right-addon="€"
                                step=".01"
                                type="number"
                              />
                            </span>
                          </div>
                        </template>
                        <template v-else>
                          <div class="d-flex align-items-center fw-semi py-4">
                            <span>{{ $t("tourPricing.netPrice") }}/{{ $t("general.shared.unit") }}:</span>
                            <span>
                              {{ newResource.supplierPrice }}
                            </span>
                          </div>
                        </template>
                        <div class="d-flex flex-column align-items-start fw-semi py-4">
                          <span>{{ $t("travelBook.totalPrice") }}:</span>
                          <span class="w-100">
                            <v-form-input
                              id="pricing-net-price"
                              :modelValue="newResource.supplierPrice * newResource.pax"
                              disabled="true"
                              autocomplete="off"
                              form-type="input-group"
                              onwheel="return false;"
                              right-addon="€"
                              step=".01"
                              type="number"
                            />
                          </span>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
                <hr />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="free-text" v-if="showModificationText">
      <v-form-input
        label="Free text"
        id="supplier-observation"
        autocomplete="off"
        v-model="supplierObservation"
        class="mb-24"
        form-type="outline"
        input-type="textarea"
        label-class="text-sm"
        rows="5"
        autofocus
        :class="{ 'custom-input-style': showModificationText }"
      />
    </div>
    <div
      class="supplier-container d-flex justify-content-end gap-12 p-32"
      v-if="!emailUpdated && emailUpdated !== null && status === 'loaded'"
    >
      <div class="btn-container">
        <div class="row-header d-flex w-100 text-sm">
          <div class="th w-20">
            <div class="d-flex">
              <template v-if="showModificationText">
                <v-button
                  class="btn-primary text-center cursor-pointer"
                  size="sm"
                  variant="primary"
                  @click="confirm('freeText')"
                >
                  {{ $t("general.button.save") }} & {{ $t("general.button.confirm") }}
                </v-button>
              </template>
              <template v-else>
                <v-button
                  class="btn-primary text-center cursor-pointer"
                  size="sm"
                  variant="primary"
                  :disabled="showModificationPrice"
                  @click="acceptModification"
                >
                  {{ $t("general.shared.modification") }}
                </v-button>
                <span class="warning-info topright" id="tooltip">
                  <v-icon icon="warning-sign" size="xs" />
                  <span class="tooltip-text">
                    {{ $t("general.shared.editFreeTextWarning") }}
                  </span>
                </span>
              </template>
            </div>
          </div>
          <div class="w-10 th"></div>
          <div class="w-10 th"></div>
          <div class="w-10 th ps-0 ps-xxl-8 text-center"></div>
          <div class="w-10 th text-center"></div>
          <div class="w-20">
            <div class="d-flex justify-content-center">
              <template v-if="showModificationPrice">
                <v-button
                  class="btn-primary text-center cursor-pointer"
                  size="sm"
                  variant="primary"
                  @click="checkPriceValue"
                >
                  {{ $t("general.button.save") }} & {{ $t("general.button.confirm") }}
                </v-button>
              </template>
              <template v-else>
                <v-button
                  class="btn-primary text-center cursor-pointer"
                  size="sm"
                  :disabled="showModificationText"
                  variant="primary"
                  @click="accept"
                >
                  {{ $t("general.shared.editPrice") }}
                </v-button>
                <span class="warning-info topright" id="tooltip">
                  <v-icon icon="warning-sign" size="xs" />
                  <span class="tooltip-text">
                    {{ $t("general.shared.editPriceWarning") }}
                  </span>
                </span>
              </template>
            </div>
          </div>
          <div class="w-20 th text-end">
            <v-button
              v-if="showModificationText || showModificationPrice"
              class="btn-icon w-fit align-self-end"
              size="sm"
              variant="primary"
              @click="goBack"
            >
              {{ $t("uploader.goBack") }}
              <v-icon icon="arrow-right" size="sm" space="ms-12" />
            </v-button>
          </div>
        </div>
      </div>
      <div class="d-lg-none w-100 d-flex justify-content-between">
        <div>
          <v-button
            v-if="showModificationText || showModificationPrice"
            class="btn-icon w-fit align-self-end"
            size="sm"
            variant="primary"
            @click="goBack"
          >
            {{ $t("uploader.goBack") }}
            <v-icon icon="arrow-right" size="sm" space="ms-12" />
          </v-button>
        </div>
        <div class="d-flex gap-12">
          <div class="d-flex">
            <template v-if="showModificationText">
              <v-button
                class="btn-primary text-center button-container cursor-pointer"
                variant="primary"
                size="sm"
                @click="confirm('freeText')"
              >
                {{ $t("general.button.save") }} & {{ $t("general.button.confirm") }}
              </v-button>
            </template>
            <template v-else>
              <v-button
                class="btn-primary text-center button-container cursor-pointer"
                variant="primary"
                size="sm"
                @click="acceptModification"
              >
                {{ $t("general.shared.modification") }}
              </v-button>
              <span class="warning-info topright" id="tooltip">
                <v-icon icon="warning-sign" size="xs" />
                <span class="tooltip-text">
                  {{ $t("general.shared.editFreeTextWarning") }}
                </span>
              </span>
            </template>
          </div>
          <div class="d-flex">
            <template v-if="showModificationPrice">
              <v-button
                class="btn-primary text-center button-container cursor-pointer"
                variant="primary"
                size="sm"
                @click="checkPriceValue"
              >
                {{ $t("general.button.save") }} & {{ $t("general.button.confirm") }}
              </v-button>
            </template>
            <template v-else>
              <v-button
                class="btn-primary text-center button-container cursor-pointer"
                variant="primary"
                size="sm"
                @click="accept"
              >
                {{ $t("general.shared.editPrice") }}
              </v-button>
              <span class="warning-info topright" id="tooltip">
                <v-icon icon="warning-sign" size="xs" />
                <span class="tooltip-text">
                  {{ $t("general.shared.editPriceWarning") }}
                </span>
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <the-editable-info-modal
      v-if="showModal && !emailUpdated && emailUpdated !== null && status === 'loaded'"
      @closed="closeModal"
    />
    <the-email-warning-modal
      v-if="showWarningModal"
      :price-warning="showPriceWarning"
      :free-text-warning="showFreeTextWarning"
      @closed="closeWarningModal"
    />
    <v-modal
      id="show-check-0-price-modal"
      class-name="modal-fullscreen-sm-down"
      v-if="showPricingConfirmation"
      size="lg"
      @closed="showPricingConfirmation = false"
    >
      <modal-header>
        <modal-title>{{ $t("emailTemplate.email_warning") }}</modal-title>
      </modal-header>
      <modal-body>
        <div>
          {{ $t("emailTemplate.price_warning") }}
        </div>
      </modal-body>
      <modal-footer>
        <v-button
          class="btn-sm btn-success align-self-end cursor-pointer"
          size="sm"
          variant="success"
          @click="confirm('price')"
        >
          {{ $t("general.button.ok") }}
        </v-button>
      </modal-footer>
    </v-modal>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { DateTime } from "luxon";
import { VFormInput } from "@uniqoders/form";
import VButton from "@/components/vendor/basic/button/VButton.vue";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";
import { useAppStore } from "@/stores/app";
import api from "@/api";
import { calculateAllNetValidated, calculateAllPaymentStatus, calculatePriceGroup } from "@/composables/pricing";
import TheConfirmPriceReservation from "@/components/email/TheConfirmPriceReservation.vue";
import TheEmailAlreadyUpdate from "@/components/email/TheEmailAlreadyUpdate.vue";
import TheEditableInfoModal from "@/components/email/TheEditableInfoModal.vue";
import TheEmailWarningModal from "@/components/email/TheEmailWarningModal.vue";
import VModal from "@/components/vendor/basic/modal/VModal.vue";
import ModalHeader from "@/components/vendor/basic/modal/ModalHeader.vue";
import ModalTitle from "@/components/vendor/basic/modal/ModalTitle.vue";
import ModalBody from "@/components/vendor/basic/modal/ModalBody.vue";
import ModalFooter from "@/components/vendor/basic/modal/ModalFooter.vue";

export default defineComponent({
  name: "TheUserFinalPrice",
  components: {
    VFormInput,
    VButton,
    VIcon,
    TheConfirmPriceReservation,
    TheEmailAlreadyUpdate,
    TheEditableInfoModal,
    TheEmailWarningModal,
    VModal,
    ModalHeader,
    ModalTitle,
    ModalBody,
    ModalFooter,
  },
  data() {
    return {
      showModificationPrice: false,
      showModificationText: false,
      supplierData: {},
      supplierObservation: "",
      status: "loading",
      successPriceEmail: false,
      successFreeTextEmail: false,
      sucessEmailUpdated: false,
      emailUpdated: null,
      showModal: true,
      showWarningModal: false,
      showPriceWarning: false,
      showFreeTextWarning: false,
      showPricingConfirmation: false,
    };
  },
  computed: {
    ...mapState(useAppStore, ["settings"]),
  },
  async created() {
    this.status = "loading";
    await this.EmailPricingInfo();
    this.status = "loaded";
  },
  methods: {
    supplierNameByLange(lang) {
      const language = localStorage.getItem("language");
      const filterLangName = lang.find((item) => item.locale === language);
      return filterLangName?.name;
    },
    closeModal() {
      this.showModal = false;
    },
    closeWarningModal() {
      this.showWarningModal = false;
      this.showPriceWarning = false;
      this.showFreeTextWarning = false;
    },
    async updateItemPriceResource(item) {
      const token = this.$route.query.token;
      const tour = this.$route.query.tour;
      let actualId;
      if (typeof item.id === "string" && item.id.includes("-")) {
        actualId = item.id.split("-");
      } else {
        actualId = [item.id];
      }

      const payload = {
        tour: tour,
        token: token,
        updated_price: "net",
        updated_value: item.supplierPrice,
        resources_id: actualId,
      };

      if (item.supplierPrice > 0) {
        try {
          const response = await api.emailAuthLess.updatePricing(payload);
          if (response) {
            // this.$toast.success(this.$t("general.shared.savedChanges"));
            this.loadTourPricing();
          }
        } catch (error) {
          // this.$toast.error(error?.response?.data?.message || this.$t("general.shared.errorMessage"));
        }
      } else {
        this.showWarningModal = true;
        this.showPriceWarning = true;
      }
    },
    async EmailPricingInfo() {
      this.loadTourPricing();
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase();
    },
    calculateNights(initDate, endDate) {
      if (!initDate || !endDate) {
        return 0;
      }

      const init = DateTime.fromISO(initDate).startOf("day");
      const end = DateTime.fromISO(endDate).startOf("day");

      return end.diff(init, "days").days;
    },
    async accept() {
      const { tour, tour_day_item_resource_ids, token } = this.$route.query;
      let actualId;
      if (typeof tour_day_item_resource_ids === "string" && tour_day_item_resource_ids.includes(",")) {
        actualId = tour_day_item_resource_ids.split(",");
      } else {
        actualId = [tour_day_item_resource_ids];
      }
      const payload = {
        tour: tour,
        token: token,
        resource_id: actualId,
        value: false,
      };
      try {
        await api.emailAuthLess.netValidate(payload);
        this.showModificationPrice = true;
      } catch (error) {
        console.error(error);
        this.showModificationPrice = false;
        this.$toast.error(error?.response?.data?.message || this.$t("general.shared.errorMessage"));
      }
    },
    async confirm(type) {
      const { tour, tour_day_item_resource_ids, token } = this.$route.query;
      let actualId;
      if (typeof tour_day_item_resource_ids === "string" && tour_day_item_resource_ids.includes(",")) {
        actualId = tour_day_item_resource_ids.split(",");
      } else {
        actualId = [tour_day_item_resource_ids];
      }
      const payload = {
        tour: tour,
        token: token,
        resource_id: actualId,
        observations: this.supplierObservation,
      };
      try {
        if ((type === "freeText" && this.supplierObservation !== "") || type === "price") {
          await api.emailAuthLess.confirmedemail(payload);
          this.sucessEmailUpdated = true;
          this.showPricingConfirmation = false;
        } else {
          this.showWarningModal = true;
          this.showFreeTextWarning = true;
        }
      } catch (error) {
        console.error(error);
        this.sucessEmailUpdated = false;
        this.$toast.error(error?.response?.data?.message || this.$t("general.shared.errorMessage"));
      }
    },
    checkPriceValue() {
      const hasZeroSupplierPrice = this.supplierData[0]?.suppliers?.some((supplier) =>
        supplier.itemResourcesGrouped?.some((item) => item.supplierPrice === 0)
      );

      if (hasZeroSupplierPrice) {
        this.showPricingConfirmation = true;
      } else {
        this.confirm("price");
      }
    },
    async acceptModification() {
      this.showModificationText = true;
    },
    goBack() {
      this.showModificationText = false;
      this.showModificationPrice = false;
    },
    async loadTourPricing() {
      const { tour, tour_day_item_resource_ids, token } = this.$route.query;

      if (tour) {
        const pricing = await api.emailAuthLess.retrieveTourPricing(tour, tour_day_item_resource_ids, token);

        const orderedByType = pricing.group((item) => item.type);

        const pricingItems = Object.keys(orderedByType).map((name) => {
          const tourDayItems = orderedByType[name];

          // 1. Calculate the net, gross and markup for each type.
          const { netPrice, grossPrice, markup } = this.calculatePrice(tourDayItems);

          // 2. Get all suppliers within each type
          const suppliers = tourDayItems.reduce((values, item) => {
            // 2.1. Search if the given supplier exists...
            const supplierInValues = !!values.find((i) => i.id === item.resource.supplier?.id);

            // 2.2. If it doesn't exist, add it.
            if (!supplierInValues && !!item.resource.supplier) {
              values.push(item.resource.supplier);
            }

            return values;
          }, []);

          // 3. For each supplier add your items
          const suppliersMapped = suppliers.map((supplier) => {
            const tourDayItemResources = tourDayItems.filter((item) => item.resource.supplier?.id === supplier.id);

            tourDayItemResources.forEach((tourDayItemResource) => {
              tourDayItemResource.resource = {
                amenities: tourDayItemResource.resource.amenities,
                cancellationPolicy: tourDayItemResource.resource.cancellationPolicy,
                createdAt: tourDayItemResource.resource.createdAt,
                deletedAt: tourDayItemResource.resource.deletedAt,
                description: tourDayItemResource.resource.description,
                id: tourDayItemResource.resource.id,
                name: tourDayItemResource.resource.name,
                supplierId: tourDayItemResource.resource.supplierId,
                type: tourDayItemResource.resource.type,
                updatedAt: tourDayItemResource.resource.updatedAt,
                translations: tourDayItemResource.resource.translations,
              };
              // tourDayItemResource.account = supplier.account;
              // tourDayItemResource.paymentMethod = supplier.paymentMethod;
              tourDayItemResource.invoiceChecked = true;
            });

            this.sortTourDayItemResources(tourDayItemResources);

            const tourDayItemsResourcesGroup = tourDayItemResources.reduce((acc, itemResource) => {
              const key = `${itemResource.resource.id}-${itemResource.date}-${itemResource.endDate}-${itemResource.netPrice}-${itemResource.markup}-${itemResource.supplierPrice}`;

              if (!acc[key]) {
                acc[key] = [];
              }

              acc[key].push(itemResource);

              return acc;
            }, {});

            const tourDayItemsResourcesGrouped = Object.keys(tourDayItemsResourcesGroup).map((key) => {
              const tourDayItemsResourcesGroupMapped = tourDayItemsResourcesGroup[key].map((item) => {
                const total = Number(item.netPrice);
                const paid = !!item.amountPaid ? Number(item.amountPaid) : 0;
                const amountPending = total - paid;
                return {
                  amountPaid: item.amountPaid,
                  clientObservations: item.clientObservations,
                  amountPending: amountPending,
                  date: item.date,
                  endDate: item.endDate,
                  grossPrice: Number(item.grossPrice),
                  id: item.id,
                  invoiceChecked: true,
                  invoiceStatus: item.invoiceStatus,
                  isUpdatedBySupplier: item.isUpdatedBySupplier,
                  isOptional: item.isOptional,
                  markup: Number(item.markup),
                  markToPay: false,
                  modelId: item.modelId,
                  modelType: item.modelType,
                  name: item.resource.name,
                  netPrice: item.isUpdatedBySupplier ? Number(item.netPrice) : 0,
                  supplierPrice: Number(item.supplierPrice) !== 0 ? Number(item.supplierPrice) : 0,
                  netValidated: item.netValidated,
                  pax: item.pax,
                  paymentStatus: item.paymentStatus,
                  reservationStatus: item.reservationStatus,
                  splitPayment: item.paymentStatus === "partial",
                  stage: item.tourDayItem.stage,
                  supplierResourceId: item.resource.id,
                  tourDayId: item.tourDayItem.tourDayId,
                  tourDayItemId: item.tourDayItemId,
                  type: item.type,
                  paymentLink: item.paymentLink,
                  translations: item.resource.translations,
                };
              });

              const { netPrice, grossPrice, amountPaid, amountPending } = calculatePriceGroup(
                tourDayItemsResourcesGroup[key]
              );

              // Calculate net validated of group
              const netValidated = calculateAllNetValidated(tourDayItemsResourcesGroup[key]);

              // Calculate if all payments in the group are complete
              const paymentComplete = calculateAllPaymentStatus(tourDayItemsResourcesGroup[key], "complete");
              const paymentPartial = calculateAllPaymentStatus(tourDayItemsResourcesGroup[key], "partial");
              const paymentPending = calculateAllPaymentStatus(tourDayItemsResourcesGroup[key], "pending");
              const paymentCancelled = calculateAllPaymentStatus(tourDayItemsResourcesGroup[key], "cancelled");

              const ids = tourDayItemsResourcesGroup[key].map((itemResource) => itemResource.id);

              const data = tourDayItemsResourcesGroup[key].map(
                (item) => item.clientObservations !== null && item.clientObservations
              );

              const uniqueData = Array.from(new Set(data.filter(Boolean)));

              const observationValue = uniqueData.join(", ");
              const allPax = tourDayItemsResourcesGroup[key].map((itemResource) => itemResource.pax);

              const totalQuanity =
                tourDayItemsResourcesGroup[key][0].quantity ||
                allPax.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

              return {
                account: tourDayItemsResourcesGroup[key][0].account,
                clientObservations: observationValue,
                amountPaid: amountPaid,
                amountPending: amountPending,
                date: tourDayItemsResourcesGroup[key][0].date,
                endDate: DateTime.fromISO(tourDayItemsResourcesGroup[key][0].endDate),
                grossPrice: grossPrice,
                id: ids.join("-"),
                invoiceChecked: true,
                markup: Number(tourDayItemsResourcesGroup[key][0].markup),
                markToPay: false,
                name: tourDayItemsResourcesGroup[key][0].resource.name,
                isUpdatedBySupplier: tourDayItemsResourcesGroup[key][0].isUpdatedBySupplier,
                netPrice: tourDayItemsResourcesGroup[key][0].isUpdatedBySupplier ? netPrice : 0,
                supplierPrice: Number(tourDayItemsResourcesGroup[key][0].supplierPrice)
                  ? Number(tourDayItemsResourcesGroup[key][0].supplierPrice)
                  : 0,
                netValidated: netValidated,
                paymentMethod: tourDayItemsResourcesGroup[key][0].paymentMethod,
                paymentLink: tourDayItemsResourcesGroup[key][0].paymentLink,
                paymentDate: tourDayItemsResourcesGroup[key][0].paymentDate,
                bookingType: tourDayItemsResourcesGroup[key][0].bookingType,
                bookingTypeMeta: tourDayItemsResourcesGroup[key][0].bookingTypeMeta,
                paymentComplete: paymentComplete,
                paymentPartial: paymentPartial,
                paymentPending: paymentPending,
                paymentCancelled: paymentCancelled,
                quantity: totalQuanity,
                resources: tourDayItemsResourcesGroupMapped,
                splitPayment: paymentPartial,
                stage: tourDayItemsResourcesGroup[key][0].tourDayItem.stage,
                tourDayId: tourDayItemsResourcesGroup[key][0].tourDayItem.tourDayId,
                tourDayItemIds: tourDayItemsResourcesGroup[key].map((itemResource) => itemResource.tourDayItem.id),
                tourDayItemResourceIds: ids,
                type: tourDayItemsResourcesGroup[key][0].type,
                typeResource: tourDayItemsResourcesGroup[key][0].resource.type,
                isGroup: tourDayItemsResourcesGroupMapped.length > 1,
                translations: tourDayItemsResourcesGroup[key][0].resource.translations,
              };
            });
            const updateFlage = tourDayItemsResourcesGrouped.map((item) => {
              return item.isUpdatedBySupplier;
            });
            this.emailUpdated = updateFlage[0];
            const data = tourDayItemsResourcesGrouped.map(
              (item) => item.clientObservations !== null && item.clientObservations
            );

            const observationValue = data.filter(Boolean).join(", ");

            // supplier.tourDayItemResources = tourDayItemResources;
            supplier.clientObservations = observationValue;
            supplier.itemResourcesGrouped = tourDayItemsResourcesGrouped;
            supplier.invoiceChecked = true;

            return {
              account: supplier.account,
              clientObservations: supplier.clientObservations,
              email: supplier.email,
              id: supplier.id,
              invoiceChecked: true,
              itemResourcesGrouped: tourDayItemsResourcesGrouped,
              mapUrl: supplier.mapUrl,
              name: supplier.name,
              paymentMethod: supplier.paymentMethod,
              paymentDate: supplier.paymentDate,
              bookingType: supplier.bookingType,
              bookingTypeMeta: supplier.bookingTypeMeta,
              phone: supplier.phone,
              preferredLanguage: supplier.preferredLanguage,
              priceRange: supplier.priceRange,
              siteUrl: supplier.siteUrl,
              type: supplier.type,
              typeEmail: supplier.typeEmail,
            };
          });

          return {
            name,
            suppliers: suppliersMapped,
            total: {
              grossPrice,
              markup,
              netPrice,
            },
          };
        });
        const arr = [];
        const filterSupplier = pricingItems.filter((item) => item.name !== "null");
        const allId = tour_day_item_resource_ids.split(",");
        filterSupplier.map((item) => {
          item.suppliers.map((supplier) => {
            supplier.itemResourcesGrouped.map((itemResource) => {
              itemResource.resources.map((resource) => {
                if (allId.includes(String(resource.id))) {
                  arr.push({ ...item, name: supplier?.name });
                }
              });
            });
          });
        });
        this.supplierData = [{ name: arr[0].name, suppliers: arr[0].suppliers }];
      }
    },
    calculatePrice(tourDayItemResources) {
      // 1. Calculate the net, gross and markup for each type.
      const { netPrice, grossPrice } = calculatePriceGroup(tourDayItemResources);

      let markup = ((grossPrice - netPrice) * 100) / netPrice;

      if (markup % 1 !== 0) {
        markup = Number(markup.toFixed(2));
      }

      if (Number.isNaN(markup)) {
        markup = 0;
      }

      return {
        netPrice,
        grossPrice,
        markup,
      };
    },
    sortTourDayItemResources(items) {
      return items.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        const dateEndA = new Date(a.endDate);
        const dateEndB = new Date(b.endDate);

        if (dateA.getTime() === dateB.getTime()) {
          if (dateEndA.getTime() === dateEndB.getTime()) {
            return a.id - b.id;
          }

          return dateEndA.getTime() - dateEndB.getTime();
        }

        return dateA.getTime() - dateB.getTime();
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.accordion-toggle {
  cursor: pointer;
}

:deep(.custom-input-style) {
  .form-control {
    background-color: rgba(var(--uq-warning-rgb), 0.25);
    border-color: var(--uq-dragon);
  }

  .input-group-text {
    background-color: #f7f7fc;
  }

  .form-control + .input-group-text {
    border-color: var(--uq-dragon);
  }
}

.suppliers-header {
  .th {
    padding-left: 2.75rem !important;
  }
}

.suppliers-header,
.resources-profit-header {
  .th {
    padding: 0.5rem 0.375rem;
    font-weight: 500;
    font-size: 0.875rem;

    @media (min-width: 1400px) {
      font-size: 1rem;
    }
  }
}

.free-text {
  width: 80%;
  margin: 0 auto;

  @media screen and (max-width: 1250px) {
    width: 95%;
  }

  @media screen and (max-width: 992px) {
    width: 90%;
  }
}

.supplier-container {
  border: 1px solid var(--uq-gray-400);
  border-radius: 0.25rem;
  margin: 0 auto;
  width: 80%;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 1250px) {
    width: 95%;
  }

  @media screen and (max-width: 992px) {
    width: 90%;
  }
}

.supplier-data {
  position: relative;

  .td {
    padding-inline: 0.375rem;
    font-weight: 300;
  }
}

.supplier-name {
  font-size: 0.875rem;

  @media (min-width: 992px) {
    font-size: 1rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.125rem;
  }
}

.row {
  padding: 0.375rem;
  font-size: 0.875rem;

  @media (min-width: 992px) {
    font-size: 1rem;
  }

  .td {
    padding-inline: 0.375rem;
    font-weight: 300;
  }
}

.item-list {
  .resources-container {
    position: relative;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: var(--uq-secondary);
    }
  }

  .type-total,
  .pricing-total {
    padding-block: 1rem;
    border: 1px solid var(--uq-gray-400);
    border-radius: 0.25rem;
  }
}

.image-container {
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  color: var(--uq-light);
}

.spinner-border {
  position: fixed;
  top: 1.25rem;
  right: 1.25rem;
  z-index: 1050;
}

.suppliers-body {
  .tour-day-item-resource-container,
  .wrapper {
    position: relative;
    padding-top: 0.75rem;

    &:not(:last-child) {
      padding-bottom: 0.75rem;
    }

    &:not(:last-child):after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: var(--uq-gray-300);
    }

    &.bg-gray-200 {
      :deep(.form-outline .label span) {
        background-color: #f7f7fc !important;
      }

      :deep(.input-group .input-group-text) {
        background-color: #f0f0f9;
      }
    }

    :deep(.input-group .input-group-text) {
      background-color: #f7f7fc;
    }
  }

  .mobile-view {
    display: none;
  }

  .sub-container {
    width: 44%;
  }

  .form-check-inline {
    margin-top: 8px;
  }

  @media (max-width: 993px) {
    .normal-view {
      display: none;
    }

    .mobile-view {
      display: flex;
    }

    .sub-container {
      width: 100%;
      margin-left: 1rem;
      margin-top: 1rem;
    }
  }
}

.dashed:not(:last-child) {
  padding-bottom: 1rem;
  border-bottom: 1px dashed var(--uq-gray-400);
}

.accordion-toggle {
  .accordion-icon {
    right: 0.5rem;
  }
}

.table-container {
  display: table;
  width: 90%;
  margin: 0 auto;

  @media screen and (max-width: 992px) {
    width: 84%;
  }
}

.btn-container {
  display: table;
  width: 91.5%;
  margin: 0 auto;

  @media screen and (max-width: 1206px) {
    width: 94.5%;
  }

  @media screen and (max-width: 992px) {
    display: none;
    width: 85%;
  }
}

.row-header,
.row-data {
  display: table-row;
}

.product-info {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
}

.warning-info {
  width: 25px;
  height: 25px;
  background: #dbdbdb;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  @media screen and (max-width: 992px) {
    width: 32px;
    height: 25px;
  }
}

.topright {
  top: -8px;
  right: 20px;
  font-size: 18px;
}

img,
.button-container {
  width: 100%;
  height: auto;
}

.accordion-toggle .accordion-icon {
  right: -2.5rem;

  @media screen and (max-width: 992px) {
    top: -1.8rem;
  }
}

.accordion-sub-body .row-data .th {
  font-weight: 300;
}

.accordion-toggle .accordion-icon {
  right: -2.5rem;

  @media screen and (max-width: 992px) {
    top: -1.8rem;
  }
}

.accordion-sub-body .row-data .th {
  font-weight: 300;
}

.row-data {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.warning-info {
  position: relative;
  display: flex;
  align-items: center;
}

/* Tooltip text */
.tooltip-text {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: white;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -88px;
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip text arrow */
.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* Show the tooltip text on hover */
.warning-info:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.warning-info {
  position: relative;
  display: flex;
  align-items: center;
}

/* Tooltip text */
.tooltip-text {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: white;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -88px;
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip text arrow */
.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* Show the tooltip text on hover */
.warning-info:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.dashed:not(:last-child) {
  padding-bottom: 1rem;
  border-bottom: 1px dashed var(--uq-gray-400);
}

.wrapper {
  position: relative;
  padding-top: 0.75rem;

  &:not(:last-child) {
    padding-bottom: 0.75rem;
  }

  &:not(:last-child):after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: var(--uq-gray-300);
  }

  &.bg-gray-200 {
    :deep(.form-outline .label span) {
      background-color: #f7f7fc !important;
    }

    :deep(.input-group .input-group-text) {
      background-color: #f0f0f9;
    }
  }

  :deep(.input-group .input-group-text) {
    background-color: #f7f7fc;
  }
}
</style>