export default {
  shared: {
    appLogoAlt: "WAW Travel Logo",
    agency: "Agentschap",
    user: "Gebruiker",
    client: "Klant",
    collaborator: "medewerker",
    admin: "Beheerder",
    location: "Locatie",
    resource: "Bron",
    contact: "Contact",
    supplier: "Leverancier",
    case: "Geval",
    search: "Zoeken",
    name: "Naam",
    surname: "Achternaam",
    nameAndSurname: "Naam en Achternaam",
    email: "E-mail",
    password: "Wachtwoord",
    passwordConfirmation: "Wachtwoord bevestiging",
    filters: "Filters",
    cannotUndo: "Deze bewerking kan niet ongedaan worden gemaakt.",
    noLocation: "Geen locatie",
    writeSearchAgencies: "Schrijf om te zoeken naar agentschappen",
    writeSearchUsers: "Schrijf om te zoeken naar gebruikers",
    pagination: "Paginering",
    tableShowNumber: "Toon: {number}",
    archived: "Gearchiveerd",
    active: "Actief",
    actions: "Acties",
    orCreateNew: "of maak nieuw",
    archive: "Archiveren",
    restore: "Herstellen",
    delete: "Verwijderen",
    type: "Type",
    types: "Types",
    created: "Aangemaakt",
    title: "Titel",
    description: "Beschrijving",
    writeItManually: "of u kunt het handmatig typen",
    links: "Links",
    map: "Kaart",
    uploadMedia: "Media uploaden",
    source: "Bron",
    media: "Media",
    documentType: "Documenttype",
    documentNumber: "Documentnummer",
    nationality: "Nationaliteit",
    language: "Taal",
    genre: "Genre",
    dateBirth: "Geboortedatum",
    phone: "Telefoon",
    web: "Web",
    website: "Website",
    note: "Opmerking",
    tag: "Tag",
    countryCode: "Landcode",
    filterSearch: "Zoeken: '{value}'",
    filterSource: "Bron: '{value}'",
    filterType: "Type: '{value}'",
    filterTypes: "Types: '{value}'",
    filterAmenity: "Voorziening: '{value}'",
    filterLocation: "Locatie: '{value}'",
    filterSurname: "Achternaam: '{value}'",
    all: "Allemaal",
    dateBirthCannotFuture: "Geboortedatum kan niet in de toekomst zijn",
    basicInformation: "Basisinformatie",
    contactInformation: "Contactinformatie",
    countryCodePlaceholder: "Land (+000)",
    pax: "Passagiers",
    age: "Leeftijd",
    room:"Kamer",
    dateRange: "Datumbereik",
    initDate: "Begin datum",
    endDate: "Eind datum",
    writeSearchLocations: "Schrijf om te zoeken naar locaties",
    origin: "Oorsprong",
    destination: "Bestemming",
    isStage: "Markeer etappe-informatie",
    isGeneral: "Markeer algemene informatie",
    dates: "Datums",
    cancellationPolicy: "Annuleringsbeleid",
    notes: "Notities",
    paymentMethod: "Betaalmethode",
    accountNumber: "Rekeningnummer",
    amenities: "Voorzieningen",
    distanceKm: "Afstand (Km)",
    products: "Producten",
    checkIn: "Check-in",
    checkOut: "Uitchecken",
    priceRange: "Prijsbereik",
    veryCheap: "Zeer goedkoop",
    cheap: "Goedkoop",
    normal: "Normaal",
    expensive: "Duur",
    category: "Categorie",
    price: "Prijs",
    searchByNameOrLocation: "Zoek op naam of locatie",
    selected: "Geselecteerd",
    amount: "Bedrag",
    invoiceStatus: "Factuurstatus",
    paymentStatus: "Betalingsstatus",
    noEndDate: "Geen einddatum",
    moreData: "Meer gegevens",
    secondSurname: "Tweede achternaam",
    selectOriginMessage: "U moet een oorsprongsstad zoeken en selecteren",
    selectDestinationMessage: "U moet een bestemmingsstad zoeken en selecteren",
    accommodation: "Accommodatie",
    kilometersShorted: "Km",
    date: "Datum",
    size: "Grootte",
    status: "Status",
    filterStatus: "Status: '{value}'",
    filterInitDate: "Begin datum sinds: '{value}'",
    filterEndDate: "Eind datum tot: '{value}'",
    filterTravelType: "Reistype: '{value}'",
    filterPaxNumber: "Aantal reizigers: '{value}'",
    filterClient: "Klant: '{value}'",
    filterReservationStatus: "Reserveringsstatus: '{value}'",
    filterPaymentsStatus: "Betalingsstatus: '{value}'",
    filterInvoicesStatus: "Factuurstatus: '{value}'",
    filterDestination: "Bestemming: '{value}'",
    filterOrigin: "Oorsprong: '{value}'",
    filterName: "Naam: '{value}'",
    reservationStatus: "Reserveringsstatus",
    reservations: "Reserveringen",
    paymentsStatus: "Betalingsstatus",
    payments: "Betalingen",
    invoicesStatus: "Factuurstatus",
    invoices: "Facturen",
    travelType: "Reistype",
    information: "Locatie",
    paxNumber: "Aantal personen: {value}",
    originAndDestination: "Herkomst en bestemming",
    caseManager: "Case manager",
    url: "Url",
    writeSearchProducts: "Schrijf om producten te zoeken",
    number: "Nummer",
    euroShort: "€",
    product: "Product",
    totalDays: "Totaal aantal dagen",
    sendEmailNotification: "Stuur e-mailmeldingen",
    notificationsSentMail: "Meldingen worden verzonden naar de e-mail",
    notNotificationsSentMail: "Er worden geen meldingen naar de e-mail verzonden",
    googleMaps: "Google Maps",
    viewWebsite: "Bekijk website",
    viewGoogleMaps: "Bekijk in Google Maps",
    filterManager: "Verantwoordelijk: '{value}'",
    seenOnlyCasesOnWay: "Zie alleen lopende zaken",
    filterOnlyOnWay: "Zie alleen lopende zaken",
    filterStartingSoon: "Begin van zaken tot '{value}' dagen",
    startingSoon: "Begin van zaken vanaf vandaag tot",
    oneWeek: "Een week",
    twoWeeks: "Twee weken",
    oneMonth: "Een maand",
    daysNumber: "Aantal dagen",
    code: "Code",
    supplier_observations: "Observaties van leveranciers",
    payment_link: "Betaallink",
    paymentBy: "Wanneer moet u betalen?",
    client_observations: "Observaties van klanten",
    savedChanges: "Opgeslagen wijzigingen",
    nif: "NIF",
    vat: "VAT",
    preferredLanguage: "Voorkeurstaal",
    bookingType: "Soorten boekingen",
    supplierPortal: "Portaal",
    promoCode: "Promotiecode",
    other: "Boeking door",
    byEmail: "Boeking per e-mail",
    bookingBy: "Boeking door",
    bookingTypeNotFound: "Geen type boeking",
    typesOfBooking: "Soorten boekingen",
    bookingOptions: "Boekingsoptie",
    bookings: "Boekingen",
    persons: "Personen",
    tripManager: "Reismanager",
    offers: "Aanbiedingen",
    initHour: "begin uur",
    endHour: "einde uur",
    optional: "optioneel",
    nReserve: "Reserveringsnummer",
    optionalQuoteItem: "Optioneel offerte-item",
    netValidated: "Gevalideerde prijs ",
    isValidated: "Gevalideerde tarieven",
    paid: "Betaald",
    pending: "In afwachting van",
    errorMessage: "Er is een fout opgetreden",
    files: "Bestanden",
    writeCity: "Schrijf de stad",
    canSearchSupplier: "U kunt de leverancier zoeken (optioneel)",
    canSearchResource: "U kunt de bron zoeken (optioneel)",
    day: "Dag",
    notification: "Melding",
    supplierNotFound: "Verwijderde leverancier",
    markedPay: "Markeer om te betalen",
    payAmount: "Betaalbaar",
    pendingAmount: "In behandeling",
    paidAmount: "Betaald",
    hotelName: "Hotel naam",
    total: "Totaal",
    generateXml: "XML genereren",
    priceSummary: "Prijsoverzicht",
    preview: "Betalings-XML",
    noDataAvailable: "Geen gegevens beschikbaar.",
    withoutSupplier: "Geen leverancier",
    quantityShort: "Aantal.",
    paymentMethodOpt: "Leverancier heeft voor gekozen",
    supplierAccountNotFound: "Geen bankrekening",
    noPaymentMethod: "Geen betaalmethode geselecteerd",
    general_information: "Algemeen",
    stage_information: "Etappe",
    point_of_interest: "POI",
    confirmationOfPricing: "Bevestiging van prijzen",
    confirmPriceInfo: "Weet u zeker dat elke prijs Neto-prijskosten zijn die door WAW.travel moeten worden betaald?",
    headerTile: "Hoeveel bedraagt ​​de prijs per eenheid? Wil je iets anders aanbieden?",
    unit: "eenheid",
    editPrice: "Prijs bewerken",
    editPriceWarning: "Om de prijs te bewerken, klik op de 'Prijs bewerken' knop.",
    editFreeTextWarning: "Om de tekst te bewerken, klik op de 'Wijziging' knop.",
    companyName: "Bedrijfsnaam",
    totalCases: "Totaal aantal gevallen",
    completedCases: "Voltooide gevallen",
    pendingCases: "In behandeling zijnde gevallen",
    yearsOld: "jaren oud",
    startDate:"Begin datum",
    alternativeStartDate:"AlternatieveStartDatum",
    clientName:"Klantnaam",
    clientEmail:"E-mailadres van de klant",
    languagePreferedByClient:"Door de klant gewenste taal",
    clientPhoneNumber:"klantTelefoonnummer",
    modification: "Wijziging",
    supplierList: "Lijst van leveranciers",
    transfer: "Overdracht",
    transport: "Transport",
    showSuppliers: "Leveranciers tonen",
    confirmationText: "Weet u zeker dat u { title } dit geval?",
    invoice: "Factuur",
    concept: "Concept",
    remainingAmount: "Overig",
    billing: "Facturering",
    customNotes: "Aangepaste notitie"
  },

  button: {
    new: "Nieuw",
    close: "Sluiten",
    loading: "Bezig met laden...",
    create: "Maken",
    save: "Opslaan",
    book: "boek",
    bookNow: "Boek nu",
    getAQuote: "Ontvang een offerte",
    saving: "Opslaan...",
    archive: "Archiveren",
    archiving: "Bezig met archiveren...",
    delete: "Verwijderen",
    deleting: "Verwijderen...",
    remove: "Verwijder",
    removing: "Verwijderen...",
    confirm: "Bevestigen",
    modify: "Bewerken",
    cancel: "Annuleren",
    processing: "Bezig met verwerken...",
    add: "Toevoegen",
    adding: "Toevoegen...",
    forgiveAccess: "Toegang vergeven",
    forgivingAccess: "Toegang vergeven...",
    login: "Inloggen",
    loggingIn: "Inloggen...",
    restore: "Herstellen",
    restoring: "Herstellen...",
    seeAll: "Alles bekijken",
    next: "Volgende",
    update: "Bijwerken",
    sendEmail: "E-mail verzenden",
    send: "Verzenden",
    sending: "Verzenden...",
    see: "Zie",
    selectAll: "Alles selecteren",
    unselectAll: "Alles deselecteren",
    download: "Downloaden",
    seeFiles: "Bestanden bekijken",
    generatingPdf: "PDF genereren...",
    seeDay: "Bekijk",
    view: "Meer",
    viewMore: "Meer bekijken",
    viewLess: "Bekijk minder",
    yes: "Ja",
    no: "Nee",
    ok: "OK",
    exportBookings: "Boekingen exporteren",
  },
  toast: {
    successMessage: "Succesbericht",
    informationMessage: "Informatiebericht",
    adventMessage: "Adventbericht",
    errorMessage: "Foutbericht",
    otherTypeTooltipMessage: "Geef eventuele speciale wensen of kamervoorkeuren door",
    exportTooltip: "Klik hier om uw boekingen naar Google sheet te exporteren",
    showSupplierBookings: "Klik hier om de boekingen van de leverancier te zien",
  },
  menu: {
    impersonateStopText: "Terug naar beheerder",
    impersonateText: "Je bent ingelogd als gebruiker { user } ({ email }).",
    cases: "Cases",
    clients: "Klanten",
    suppliers: "Leveranciers",
    resources: "Informatie",
    contacts: "Contacten",
    agencies: "Agentschappen",
    statistics: "Statistieken",
    logout: "Uitloggen",
    myProfile: "Mijn profiel",
    products: "Activiteiten",
  },
  adminMenu: {
    users: "Gebruikers",
    agencies: "Agentschappen",
    collaborators: "Medewerkers",
  },
  address: {
    address: "Adres",
    addAddress: "Adres toevoegen",
    addressLine2: "Adresregel 2",
    billingAddress: "Factuuradres",
    aptSuit: "Apt, Suite, Unit, etc",
    secondAddress: "Tweede adres",
    city: "Stad",
    state: "Staat",
    country: "Land",
    postalCode: "Postcode",
    provinceStateRegion: "Provincie / Staat / Regio",
    streetAddress: "Straatnaam",
    number: "Nummer",
    matchesContactAddress: "Overeenkomend met contactadres",
  },
  screenVerify: {
    title: "Onze excuses!",
    message: "De Tour builder is niet beschikbaar in de mobiele versie.",
    openOnDesktop: "Voor de beste ervaring raden we aan de pagina te openen vanaf een apparaat met een groter scherm.",
  },
  vueSelect: {
    noOptions: "Sorry, geen bijpassende opties.",
  },
  errors: {
    error404: "404",
    pageNotFound: "Opps! Pagina niet gevonden",
    pageNotFoundText: "We kunnen de pagina die je zoekt niet vinden.",
  },
  pages: {
    confirmingReservation: "Reservering wordt bevestigd...",
    modifingReservation: "Reservering wordt gewijzigd...",
    reservationConfirmed: "Reservering bevestigd!",
    reservationModified: "Reservering gewijzigd!",
    confirmEmailStatus: "U ontvangt de e-mail zo snel mogelijk.",
    modifiedStatus: "Bedankt voor de suggestie. We nemen contact met je op.",
    emailConfirmationSucess: "Bedankt...",
    emailAlreadyUpdated: "Deze e-mail is al bijgewerkt door de leverancier. Als u vragen heeft, neem dan contact met ons op.",
  },
};
