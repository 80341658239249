export default {
  pricing: 'Prijzen',
  profit: 'Winst',
  netPrice: 'Kostprijs',
  supplierOldPrice: 'Leverancier Oude Prijs',
  percentageProfit: '% Winst',
  grossPrice: 'Verkoopprijs',
  dates: 'Data',
  supplierTypeTotal: '{supplierType} totaal',
  updatePriceTitle: 'Totaalprijs bijwerken',
  updatePriceText: 'De totale \'{newTotal}\' komt niet overeen met de oude waarde \'{initialValue}\'. Als u deze totaalprijs wijzigt, bedenk dan dat de prijzen van de bronnen zullen worden aangepast om overeen te komen. Weet u zeker dat u dit wilt doen?',
  updateTotalPriceText: 'De totale \'{newTotal}\' komt niet overeen met de oude waarde \'{initialValue}\'. Als u deze totaalprijs wijzigt, let dan op dat de prijs van de tour zal veranderen maar de prijzen van de producten hetzelfde blijven. Als u de prijs van een product wijzigt, wordt het totaal opnieuw berekend volgens de prijzen van de producten. Weet u zeker dat u dit wilt doen?',
  total: 'Totaal',
  quotePrice: 'Offerte prijs',
  newInvoice: 'Nieuwe factuur',
  invoice: {
    noResourcesSelectedMessage: 'Selecteer een product om een factuur aan te maken',
    createNew: 'Nieuwe factuur aanmaken',
    basicInformation: 'Basisinformatie',
    number: 'Factuurnummer',
    amount: 'Bedrag',
    address: 'Adres',
    resource: 'Bron',
    resources: 'Bronnen',
    status: 'Status',
    idIdentity: 'NIF',
    vat: 'BTW-nummer',
    type: 'Type',
    currency: 'Valuta',
  },
  nights: 'Nachten',
  dayNumber: 'Dagnummer',
  dayNumberShort: 'Nº dag',
  quantity: 'Hoeveelheid',
  quantityShort: 'Aantal.',
  splitPayment: 'de kosten delen',
  resetPriceTitle: 'Prijs resetten',
  resetPriceInfo: 'Weet je zeker dat je de prijs wilt resetten?',
};
