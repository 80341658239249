<template>
  <div id="tour" class="container pb-32 pb-lg-64">
    <v-loader v-if="status === 'loading'" />

    <template v-else>
      <div class="pt-40 pb-32 pb-lg-48">
        <div class="d-flex flex-column flex-md-row justify-content-between">
          <div class="d-flex justify-content-between align-items-center w-100 mb-24 mb-md-0">
            <div class="h4 ff-secondary mb-0 d-flex align-items-center">
              <div class="bg-secondary rounded-xs me-8">
                <v-icon icon="compass" size="xxl" space="p-12" />
              </div>

              {{ $t(getTitle()) }} - {{ clientTour.name }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="status === 'loaded' && !!tourIdAux"
        class="tour-steps-container d-flex align-items-center justify-content-center mb-48 flex-wrap">
        <div v-for="step in filteredTourSteps" :key="step.key" class="tour-step">
          <router-link v-if="step.key !== 'quote' && step.key !== 'preview'" :id="step.id"
            :aria-label="$t(step.translation)" :class="{ 'router-link-active': step.url === $route.name }"
            :to="{ name: step.url, params: getParams(step.url), query: step.query }" class="btn btn-outline-primary"
            @click="actualStep = step.key">
            {{ $t(step.translation) }}
          </router-link>

          <a v-if="step.key === 'preview'" :id="step.id" :aria-label="$t(step.translation)"
            :class="{ 'router-link-active': step.url === $route.name }" class="btn btn-outline-primary" href="#"
            @click="openPreview(step)">
            {{ $t(step.translation) }}
          </a>

          <a v-if="step.key === 'quote'" :id="step.id" :aria-label="$t(step.translation)"
            :class="{ 'router-link-active': step.url === $route.name }" class="btn btn-outline-primary" href="#"
            @click="openQuote(step)">
            {{ $t(step.translation) }}
          </a>
        </div>
      </div>
      <div v-if="pricingSection !== 'tour.pricing.show'" class="d-flex justify-content-end mb-28 gap-8">
        <v-button :disabled="offCanvasToShow === 'the-case-duplicate-off-canvas'" class="btn-icon w-fit align-self-end"
          size="sm" variant="primary" @click="openOffCanvas('the-case-duplicate-off-canvas')">
          {{ $t('tourClient.duplicateTour') }}
          <span class="mi mi-copy text-sm ms-12" />
        </v-button>
      </div>

      <router-view v-if="!!clientTour" ref="routerChild" :client-tour="clientTour" @reload-client-tour="loadClientTour()" />
    </template>

    <the-case-setup-quote-off-canvas v-if="offCanvasToShow === 'the-case-setup-quote-off-canvas'"
      :opened-window="openedWindow" :quote-route="quoteRoute" @closed="closeOffCanvas"
      @set-opened-window="openedWindow = $event" />

    <the-case-duplicate-off-canvas v-if="offCanvasToShow === 'the-case-duplicate-off-canvas'" :case-id="caseId"
      :client-id="clientTour.clientId" :client-name="clientTour.client.name" :tour-id="tourId" 
      @closed="closeOffCanvas" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import api from '@/api';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { useAuthStore } from '@/stores/auth';
import ClientTour from '@/api/objects/ClientTour';
import Tour from '@/api/objects/Tour';
import { useUserStore } from '@/stores/user';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
import TheCaseSetupQuoteOffCanvas from '@/components/case/parts/TheCaseSetupQuoteOffCanvas.vue';
import TheCaseDuplicateOffCanvas from '@/components/case/parts/TheCaseDuplicateOffCanvas.vue';

export default defineComponent({
  name: 'TheCase',
  components: {
    TheCaseDuplicateOffCanvas,
    TheCaseSetupQuoteOffCanvas,
    VLoader,
    VIcon,
    VButton,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    tourId: {
      type: String,
      required: false,
    },
    
  },
  setup() {
    const userStore = useUserStore();

    return {
      ...useOffCanvasUtils(),
      userStore,
    };
  },
  data() {
    return {
      openedWindow: null as any,
      openedWindowPreview: null as any,
      clientTour: null as null | ClientTour,
      tourIdAux: null as null | number | string,
      tours: [] as Tour[],
      status: 'loading' as string,
      actualStep: 'setup',
      quoteRoute: {} as Record<string, any>,
      pricingSection: '',
      showConfirmationModal: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
    ...mapState(useUserStore, ['currentTour']),
    tourSteps() {
      return [
        {
          key: 'setup',
          url: 'case.setup.show',
          id: 'case-setup',
          translation: 'tourClient.setup',
        },
        {
          key: 'builder',
          url: 'tour.builder.show',
          id: 'tour-builder',
          translation: 'tourClient.builder',
        },
        {
          key: 'pricing',
          url: 'tour.pricing.show',
          id: 'tour-pricing',
          translation: 'tourClient.pricing',
        },
        {
          key: 'preview',
          url: 'tour.travel-book.preview',
          id: 'tour-preview',
          translation: 'tourClient.preview',
          query: {
            token: this.currentTour?.token,
            locale: !!localStorage.getItem('language') ? localStorage.getItem('language') : !!import.meta.env.VITE_APP_DEFAULT_LOCALE ? import.meta.env.VITE_APP_DEFAULT_LOCALE : !!import.meta.env.VITE_APP_DEFAULT_LOCALE ? import.meta.env.VITE_APP_DEFAULT_LOCALE : 'en',
            summary: 0,
          },
        },
        {
          key: 'quote',
          url: 'tour.travel-book.quote',
          id: 'tour-preview-quote',
          translation: 'tourClient.quote',
          query: {
            token: this.currentTour?.token,
            locale: !!localStorage.getItem('language') ? localStorage.getItem('language') : !!import.meta.env.VITE_APP_DEFAULT_LOCALE ? import.meta.env.VITE_APP_DEFAULT_LOCALE : !!import.meta.env.VITE_APP_DEFAULT_LOCALE ? import.meta.env.VITE_APP_DEFAULT_LOCALE : 'en',
            summary: 1,
          },
        },
      ];
    },
    defaultLocale() {
      return !!import.meta.env.VITE_APP_DEFAULT_LOCALE ? import.meta.env.VITE_APP_DEFAULT_LOCALE : 'en';
    },
    filteredTourSteps() {
      return this.tourSteps.filter(
        (step) => !(step.key === 'pricing' && this.agency?.isCollaborator),
      );
    },
  },
  watch: {
    async tourId(newValue) {
      this.status = 'loading';

      this.tourIdAux = Number(newValue);

      await this.loadClientTour();

      this.status = 'loaded';
    },
    '$route'(to, from) {
      if (to.name !== from.name) {
        this.pricingSection = to.name;
      }
    },
  },
  async created() {
    await this.loadClientTour();

    this.status = 'loaded';
  },
  methods: {
    async loadClientTour() {
      if (!!this.agency) {
        try {
          this.pricingSection = String(this.$router.currentRoute.value.name) || '';

          this.clientTour = await api.case.retrieve(this.agency.slug,
            Number(this.caseId));
          
          this.tours = this.clientTour.tours;

          // TODO: Cambiar cuando se gestionen los casos con varios tours
          // if (!this.tourId) {
          //   const firstTour = this.tours[0];
          //   this.tourIdAux = firstTour.id;
          // } else {
          //   this.tourIdAux = Number(this.tourId);
          // }

          const firstTour = this.tours[0];

          const tourData = {
            id: firstTour.id,
            agency: this.agency.slug,
            clientTourId: Number(this.caseId),
          };

          const params = {
            expand: ['client', 'paxs', 'featuredImage', 'quotes', 'invoicesReceived', 'clientTour'],
          };

          await this.userStore.setCurrentTour(tourData, params);

          this.tourIdAux = firstTour.id;

          if (!!this.tourId && !!this.$router.currentRoute.value.name) {
            await this.$router.replace({
              name: this.$router.currentRoute.value.name,
              params: {
                caseId: Number(this.caseId),
                tourId: this.tourIdAux,
              },
            });
          } else {
            await this.$router.replace({
              name: 'case.setup.show',
              params: {
                caseId: Number(this.caseId),
                tourId: this.tourIdAux,
              },
            });
          }

        } catch (e: any) {
          console.error(e);
        }
      }
    },
    async createTour() {
      await this.$modal.confirm({
        title: this.$t('tourClient.createTour'),
        text: this.$t('tourClient.createTourText'),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonText: this.$t('general.button.confirm'),
        confirmButtonCallback: this.doCreateTour,
      });
    },
    async doCreateTour() {
      if (!!this.agency) {
        try {
          const tour: Tour = await api.tour.create(this.agency.slug,
            Number(this.caseId), { status: 'initializing' });

          this.$toast.success(this.$t('general.shared.savedChanges'));

          await this.loadClientTour();

          await this.$router.replace({
            name: 'tour.builder.show',
            params: {
              caseId: Number(this.caseId),
              tourId: tour.id,
            },
          });
        } catch (e) {
          console.error(e);
        }
      }
    },
    async archiveTour(tourId: string | number) {
      await this.$modal.delete({
        title: this.$t('tourClient.archiveTour'),
        text: this.$t('tourClient.archiveTourText'),
        deleteButtonText: this.$t('general.button.archive'),
        deleteButtonLoadingText: this.$t('general.button.archiving'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doArchiveTour(tourId),
      });
    },
    async doArchiveTour(tourId: string | number) {
      if (!!this.agency) {
        try {
          await api.tour.archive(this.agency.slug, this.caseId, tourId);

          this.$toast.success(this.$t('general.shared.savedChanges'));

          await this.loadClientTour();

          // TODO: Que te lleve a otro tour
          //
          // await this.$router.replace({
          //   name: 'tour.builder.show',
          //   params: { caseId: Number(this.caseId), tourId: tour.id },
          // });
        } catch (e) {
          console.error(e);
        }
      }
    },
    getTitle() {
      let title = '';

      switch (this.actualStep) {
        case 'setup':
          title = 'tourClient.case';
          break;
        case 'builder':
          title = 'tourClient.tourBuilder';
          break;
        case 'pax':
          title = 'tourClient.tourPax';
          break;
        case 'contacts':
          title = 'tourClient.tourContacts';
          break;
        case 'bookings':
          title = 'tourClient.tourStatuses';
          break;
        case 'pricing':
          title = 'tourClient.tourPricing';
          break;
        default:
          title = 'tourClient.tourBuilder';
          break;
      }

      return title;
    },
    getParams(url: string) {
      if (url === 'case.index') {
        return {};
      }

      return {
        caseId: this.caseId,
        tourId: this.tourId
      };
    },
    openQuote(step: Record<string, any>) {
      this.quoteRoute = step;

      this.openOffCanvas('the-case-setup-quote-off-canvas');
    },
    openPreview(step: Record<string, any>) {
      if (!!this.openedWindowPreview && !this.openedWindowPreview.closed) {
        this.openedWindowPreview.close();
      }

      const routeData = this.$router.resolve({
        name: step.url,
        query: step.query,
      });
      const width = 1000;
      const height = 800;
      const top = window.top.outerHeight / 2 + window.top.screenY - (height / 2);
      const left = window.top.outerWidth / 2 + window.top.screenX - (width / 2);

      this.openedWindowPreview = window.open(routeData.href, '_blank', `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,left=${left},width=${width},height=${height},top=${top}`);
    },
  },
  unmounted() {
    this.userStore.setCurrentTour(null);
  },
});
</script>

<style lang="scss" scoped>
.tour-steps-container {
  gap: 1rem;
}

.container {
  @media (max-width: 991.98px) {
    width: 100%;
    max-width: 100%;
    padding-right: var(--uq-gutter-x, 1rem);
    padding-left: var(--uq-gutter-x, 1rem);
    margin-right: 0;
    margin-left: 0;
  }
}
</style>
