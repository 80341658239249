<template>
  <editor
    :id="id"
    ref="htmlEditorRef"
    v-model="content"
    :init="config"
    @change="update"
    @init="loaded"
    @keyup="update"
    @redo="update"
    @undo="update"
  />
</template>

<script lang="ts">
import Editor from "@tinymce/tinymce-vue";
import { defineComponent } from "vue";
import api from "@/api";

export default defineComponent({
  name: "VHtmlEditor",
  inject: ["tinymce_key"],
  props: {
    id: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: false,
    },
    width: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    modelType: {
      type: String,
      required: false,
      default: "",
    },
    modelId: {
      type: String,
      required: false,
      default: "",
    }
  },
  components: {
    Editor,
  },
  emits: ["input", "mediaUploaded"],
  computed: {
    config(): any {
      return {
        selector: `textarea#${this.id}`,
        hidden_input: false,
        base_url: "/tinymce/js/tinymce", // Base URL for TinyMCE resources
        suffix: ".min", // Suffix for TinyMCE resources
        license_key: "gpl", // for use without api key
        // eslint-disable-next-line no-async-promise-executor
        images_upload_handler: (blobInfo: any) =>
          new Promise(async (resolve: any, reject: any) => {
            try {
              const formData = new FormData();
              formData.append("file", blobInfo.blob(), blobInfo.filename());

              const media: any = await api.media.upload("s3", formData, {
                "Content-Type": "multipart/form-data",
              });

              const mediaObject = {
                uuid: media.uuid,
                src: media.url,
                name: blobInfo.name(),
              };

              this.$emit("mediaUploaded", mediaObject);

              if (!!media.url) {
                resolve(media.url);
              } else {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject(this.$t("general.shared.errorMessage"));
              }
            } catch (error) {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(this.$t("general.shared.errorMessage"));
            }
          }),
        automatic_uploads: true,
        entity_encoding: "UTF-8",
        height: !!this.height ? this.height : 500,
        width: !!this.width ? this.width : "100%",
        placeholder: this.placeholder,
        menubar: "format table",
        language: !!navigator.language ? navigator.language : import.meta.env.VITE_APP_DEFAULT_LOCALE,
        contextmenu: "paste | link image inserttable | cell row column deletetable",
        extended_valid_elements: "img[!src|border:0|alt|title|width|height|style]a[name|href|target|title|onclick]",
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "image",
          "charmap",
          "preview",
          "anchor",
          "searchreplace",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "code",
          "help",
          "wordcount",
        ],
        mobile: {
          toolbar_mode: "wrap",
        },
        toolbar:
          "undo redo | blocks | bold italic underline strikethrough forecolor backcolor" +
          "fontselect | alignleft aligncenter alignright alignjustify | " +
          "bullist numlist outdent indent | table | removeformat | image ",
      };
    },
  },
  data() {
    return {
      content: this.value,
      editor: false as any,
    };
  },
  watch: {
    value() {
      this.content = this.value;
    },
  },
  methods: {
    loaded(event: any, editor: any) {
      this.editor = editor;
    },
    update() {
      this.$emit("input", this.content);
    },
    async clear() {
      this.editor.setContent("");
      this.content = "";
    },
  },
});
</script>
