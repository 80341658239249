<template>
  <div class="item-list">
    <v-dropdown
      id="add-dropdown"
      :disable-icon="true"
      button-classes="btn-gradient fw-bold justify-content-center py-12 px-28"
      class="me-md-24"
      dropdown-classes="add-dropdown dropdown-menu-end"
    >
      <template #button>
        <v-icon icon="plus" space="me-12" />
        {{ $t("general.button.new") }}
      </template>

      <template #content>
        <li v-for="item in sortedDropdownItems" :key="item.id" class="dropdown-list p-0">
          <a
            :id="item.id"
            :aria-label="item.label"
            class="dropdown-item d-flex align-items-center px-28 py-16"
            href="#"
            @click.prevent="
              $emit('emitCloseOffCanvas');
              openOffCanvas(item.offCanvasId);
            "
          >
            <v-icon class="text-secondary" :icon="item.icon" size="xxl" space="me-12" />
            <span>{{ item.label }}</span>
          </a>
        </li>
      </template>
    </v-dropdown>

    <the-admin-user-create-offcanvas
      v-if="offCanvasToShow === 'the-admin-user-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-users="$emit('reloadUsers')"
      @open-details="openDetailsOffCanvas('the-admin-user-details-offcanvas', $event)"
    />

    <the-admin-user-details-offcanvas
      v-if="offCanvasToShow === 'the-admin-user-details-offcanvas'"
      :is-archived="false"
      :user-data="currentData"
      @closed="
        closeOffCanvas();
        currentData = null;
      "
      @reload-users="$emit('reloadUsers')"
    />

    <the-admin-agency-create-offcanvas
      v-if="offCanvasToShow === 'the-admin-agency-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-agencies="$emit('reloadAgencies')"
      @open-details="openDetailsOffCanvas('the-admin-agency-details-offcanvas', $event)"
    />

    <the-admin-collaborator-create-offcanvas
      v-if="offCanvasToShow === 'the-admin-collaborator-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-agencies="$emit('reloadAgencies')"
      @open-details="openDetailsOffCanvas('the-admin-collaborator-details-offcanvas', $event)"
    />

    <the-admin-agency-details-offcanvas
      v-if="offCanvasToShow === 'the-admin-agency-details-offcanvas'"
      :agency-data="currentData"
      :is-archived="false"
      @closed="
        closeOffCanvas();
        currentData = null;
      "
      @reload-agencies="$emit('reloadAgencies')"
    />

    <the-client-create-offcanvas
      v-if="offCanvasToShow === 'the-client-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-clients="$emit('reloadClients')"
      @open-details="openDetailsOffCanvas('the-client-details-offcanvas', $event)"
    />

    <the-client-details-offcanvas
      v-if="offCanvasToShow === 'the-client-details-offcanvas'"
      :client-data="currentData"
      :is-archived="false"
      @closed="
        closeOffCanvas();
        currentData = null;
      "
      @reload-clients="$emit('reloadClients')"
    />

    <the-resource-create-offcanvas
      v-if="offCanvasToShow === 'the-resource-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-resources="$emit('reloadResources')"
      @open-details="openDetailsOffCanvas('the-resource-details-offcanvas', $event)"
    />

    <the-resource-information-create-offcanvas
      v-if="offCanvasToShow === 'the-resource-information-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-resources="$emit('reloadResources')"
      @open-details="openDetailsOffCanvas('the-resource-details-offcanvas', $event)"
    />

    <the-resource-details-offcanvas
      v-if="offCanvasToShow === 'the-resource-details-offcanvas'"
      :is-archived="false"
      :resource-data="currentData"
      @closed="
        closeOffCanvas();
        currentData = null;
      "
      @reload-resources="$emit('reloadResources')"
    />

    <the-supplier-create-offcanvas
      v-if="offCanvasToShow === 'the-supplier-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-suppliers="$emit('reloadSuppliers')"
      @open-details="openDetailsOffCanvas('the-supplier-details-offcanvas', $event)"
    />

    <the-supplier-details-offcanvas
      v-if="offCanvasToShow === 'the-supplier-details-offcanvas'"
      :is-archived="false"
      :supplier-data="currentData"
      @closed="
        closeOffCanvas();
        currentData = null;
      "
      @reload-suppliers="$emit('reloadSuppliers')"
    />

    <the-case-create-offcanvas
      v-if="offCanvasToShow === 'the-case-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-client-tours="$emit('reloadClientTours')"
    />

    <the-template-create-offcanvas
      v-if="offCanvasToShow === 'the-template-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-client-tours="$emit('reloadClientTours')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";
import VDropdown from "@/components/vendor/basic/dropdown/VDropdown.vue";
import useOffCanvasUtils from "@/helpers/OffCanvasUtils";
import TheAdminUserCreateOffcanvas from "@/components/admin/user/TheAdminUserCreateOffcanvas.vue";
import TheAdminAgencyCreateOffcanvas from "@/components/admin/agency/TheAdminAgencyCreateOffcanvas.vue";
import TheAdminCollaboratorCreateOffcanvas from "@/components/admin/collaborator/TheAdminCollaboratorCreateOffcanvas.vue";
import TheClientCreateOffcanvas from "@/components/client/TheClientCreateOffcanvas.vue";
import TheResourceCreateOffcanvas from "@/components/resource/TheResourceCreateOffcanvas.vue";
import TheSupplierCreateOffcanvas from "@/components/supplier/TheSupplierCreateOffcanvas.vue";
import TheCaseCreateOffcanvas from "@/components/case/TheCaseCreateOffcanvas.vue";
import TheResourceInformationCreateOffcanvas from "@/components/resource/TheResourceInformationCreateOffcanvas.vue";
import TheClientDetailsOffcanvas from "@/components/client/TheClientDetailsOffcanvas.vue";
import TheAdminAgencyDetailsOffcanvas from "@/components/admin/agency/TheAdminAgencyDetailsOffcanvas.vue";
import TheAdminUserDetailsOffcanvas from "@/components/admin/user/TheAdminUserDetailsOffcanvas.vue";
import TheResourceDetailsOffcanvas from "@/components/resource/TheResourceDetailsOffcanvas.vue";
import TheSupplierDetailsOffcanvas from "@/components/supplier/TheSupplierDetailsOffcanvas.vue";
import TheTemplateCreateOffcanvas from "@/components/template/TheTemplateCreateOffcanvas.vue";
import useCapabilities from "@/helpers/capabilities";

export default defineComponent({
  name: "VCreateItemDropdown",
  components: {
    TheTemplateCreateOffcanvas,
    TheSupplierDetailsOffcanvas,
    TheResourceDetailsOffcanvas,
    TheAdminUserDetailsOffcanvas,
    TheAdminAgencyDetailsOffcanvas,
    TheClientDetailsOffcanvas,
    TheResourceInformationCreateOffcanvas,
    TheCaseCreateOffcanvas,
    TheSupplierCreateOffcanvas,
    TheResourceCreateOffcanvas,
    TheClientCreateOffcanvas,
    TheAdminAgencyCreateOffcanvas,
    TheAdminCollaboratorCreateOffcanvas,
    TheAdminUserCreateOffcanvas,
    VDropdown,
    VIcon,
  },
  emits: [
    "openFiltersOffcanvas",
    "emitCloseOffCanvas",
    "reloadUsers",
    "reloadAgencies",
    "reloadClients",
    "reloadResources",
    "reloadSuppliers",
    "reloadClientTours",
  ],
  setup() {
    return {
      ...useOffCanvasUtils(),
      ...useCapabilities(),
    };
  },
  data() {
    return {
      currentData: null as any,
      dropdownItems: [
        {
          id: "create-user",
          label: this.$t("general.shared.user"),
          icon: "group",
          offCanvasId: "the-admin-user-create-offcanvas",
          can: "user.store",
        },
        {
          id: "create-agency",
          label: this.$t("general.shared.agency"),
          icon: "shop",
          offCanvasId: "the-admin-agency-create-offcanvas",
          can: "agency.store",
        },
        {
          id: "create-collaborator",
          label: this.$t("general.shared.collaborator"),
          icon: "user",
          offCanvasId: "the-admin-collaborator-create-offcanvas",
          can: "agency.store",
        },
        {
          id: "create-client",
          label: this.$t("general.shared.client"),
          icon: "group",
          offCanvasId: "the-client-create-offcanvas",
        },
        {
          id: "create-resource",
          label: this.$t("general.shared.resource"),
          icon: "send-love",
          offCanvasId: "the-resource-create-offcanvas",
        },
        {
          id: "create-resource-information",
          label: this.$t("general.shared.information"),
          icon: "marker",
          offCanvasId: "the-resource-information-create-offcanvas",
        },
        {
          id: "create-supplier",
          label: this.$t("general.shared.supplier"),
          icon: "briefcase",
          offCanvasId: "the-supplier-create-offcanvas",
        },
        {
          id: "create-case",
          label: this.$t("general.shared.case"),
          icon: "compass",
          offCanvasId: "the-case-create-offcanvas",
        },
        {
          id: "create-template",
          label: this.$t("general.shared.product"),
          icon: "map",
          offCanvasId: "the-template-create-offcanvas",
        },
      ],
    };
  },
  computed: {
    sortedDropdownItems() {
      return this.dropdownItems
        .filter((item) => !item.can || this.can(item.can))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
  },
  methods: {
    openDetailsOffCanvas(offCanvasName: string, id: number) {
      this.currentData = id;

      if (this.offCanvasToShow !== offCanvasName) {
        this.openOffCanvas(offCanvasName);
      }
    },
  },
});
</script>
