export default {
  shared: {
    appLogoAlt: "WAW Travel Logo",
    agency: "Agencia",
    user: "Usuario",
    client: "Cliente",
    collaborator: "colaborador",
    admin: "Administrador",
    location: "Ubicación",
    resource: "Recurso",
    contact: "Contacto",
    supplier: "Proveedor",
    case: "Caso",
    search: "Buscar",
    name: "Nombre",
    surname: "Apellidos",
    nameAndSurname: "Nombre y Apellidos",
    email: "Correo electrónico",
    password: "Contraseña",
    passwordConfirmation: "Confirmación de contraseña",
    filters: "Filtros",
    cannotUndo: "Esta operación no se puede deshacer",
    noLocation: "Sin ubicación",
    writeSearchAgencies: "Escribir para buscar agencias",
    writeSearchUsers: "Escribir para buscar usuarios",
    pagination: "Paginación",
    tableShowNumber: "Mostrar: {number}",
    archived: "Archivado",
    active: "Activo",
    actions: "Acciones",
    orCreateNew: "o crear nuevo",
    archive: "Archivar",
    restore: "Restaurar",
    delete: "Eliminar",
    type: "Tipo",
    types: "Tipos",
    title: "Título",
    created: "Creado",
    description: "Descripción",
    writeItManually: "o puedes escribirlo manualmente",
    links: "Enlaces",
    map: "Mapa",
    uploadMedia: "Cargar medios",
    source: "Fuente",
    media: "Medios",
    documentType: "Tipo de documento",
    documentNumber: "Número de documento",
    nationality: "Nacionalidad",
    language: "Lengua",
    genre: "Género",
    dateBirth: "Fecha de nacimiento",
    phone: "Teléfono",
    web: "Web",
    website: "Sitio web",
    note: "Nota",
    tag: "Etiqueta",
    countryCode: "Código de país",
    filterSearch: "Búsqueda: '{value}'",
    filterSource: "Origen: '{value}'",
    filterType: "Tipo: '{value}'",
    filterTypes: "Tipos: '{value}'",
    filterAmenity: "Servicios: '{value}'",
    filterLocation: "Localidad: '{value}'",
    filterSurname: "Apellido: '{value}'",
    all: "Todos",
    dateBirthCannotFuture: "Fecha de nacimiento no puede ser en el futuro",
    basicInformation: "Información básica",
    contactInformation: "Información de contacto",
    countryCodePlaceholder: "País (+000)",
    pax: "Pasajeros",
    age: "Edad",
    room: "Habitación",
    dateRange: "Rango de fechas",
    initDate: "Fecha inicial",
    endDate: "Fecha final",
    writeSearchLocations: "Escribe para buscar ubicaciones",
    origin: "Origen",
    destination: "Destino",
    isStage: "Marcar información de etapa",
    isGeneral: "Marcar información general",
    dates: "Fechas",
    cancellationPolicy: "Política de cancelación",
    notes: "Notas",
    paymentMethod: "Forma de pago",
    accountNumber: "Número de cuenta",
    amenities: "Servicios",
    distanceKm: "Distancia (Km)",
    products: "Products",
    checkIn: "Entrada",
    checkOut: "Salida",
    priceRange: "Rango de precios",
    veryCheap: "Muy barato",
    cheap: "Barato",
    normal: "Normal",
    expensive: "Caro",
    category: "Categoría",
    price: "Precio",
    searchByNameOrLocation: "Buscar por nombre o ubicación",
    selected: "Seleccionado",
    amount: "Importe",
    invoiceStatus: "Estado de la factura",
    paymentStatus: "Estado del pago",
    noEndDate: "Sin fecha final",
    moreData: "Más datos",
    secondSurname: "Segundo apellido",
    selectOriginMessage: "Debe buscar y seleccionar una ciudad de origen",
    selectDestinationMessage: "Debe buscar y seleccionar una ciudad de destino",
    accommodation: "Alojamiento",
    kilometersShorted: "Km",
    date: "Fecha",
    size: "Tamaño",
    status: "Estado",
    filterStatus: "Estado: '{value}'",
    filterInitDate: "Fecha de inicio desde: '{value}'",
    filterEndDate: "Fecha de finalización hasta: '{value}'",
    filterTravelType: "Tipo de viaje: '{value}'",
    filterPaxNumber: "Pax: '{value}'",
    filterClient: "Cliente: '{value}'",
    filterReservationStatus: "Estado de la reserva: '{value}'",
    filterPaymentsStatus: "Estado del pago: '{value}'",
    filterInvoicesStatus: "Estado de las facturas: '{value}'",
    filterDestination: "Destino: '{value}'",
    filterOrigin: "Origen: '{value}'",
    filterName: "Nombre: '{value}'",
    reservationStatus: "Estado de la reserva",
    reservations: "Reservas",
    paymentsStatus: "Estado de los pagos",
    payments: "Pagos",
    invoicesStatus: "Estado de las facturas",
    invoices: "Facturas",
    travelType: "Tipo de viaje",
    information: "Localidad",
    paxNumber: "Pasajeros: {value}",
    originAndDestination: "Origen y destino",
    caseManager: "Responsable del caso",
    url: "Url",
    writeSearchProducts: "Escribir para buscar actividades",
    number: "Número",
    euroShort: "€",
    product: "Actividad",
    totalDays: "Total de días",
    sendEmailNotification: "Enviar notificaciones por correo electrónico",
    notificationsSentMail: "Las notificaciones se envían al correo",
    notNotificationsSentMail: "No se envían notificaciones al correo",
    googleMaps: "Google Maps",
    viewWebsite: "Ver sitio web",
    viewGoogleMaps: "Ver en Google Maps",
    filterManager: "Resonsable: '{value}'",
    seenOnlyCasesOnWay: "Ver sólo casos en curso",
    filterOnlyOnWay: "Ver sólo los casos en curso",
    filterStartingSoon: "Inicio de casos hasta '{value}' días",
    startingSoon: "Inicio de casos desde hoy hasta",
    oneWeek: "f semana",
    twoWeeks: "Dos semanas",
    oneMonth: "Un mes",
    daysNumber: "Número de días",
    code: "Código",
    supplier_observations: "Observaciones del proveedor",
    payment_link: "Enlace de pago",
    paymentBy: "Cuando pagar",
    client_observations: "Observaciones de la clienta",
    savedChanges: "Cambios guardados",
    nif: "NIF",
    vat: "VAT",
    preferredLanguage: "Lenguaje preferente",
    bookingType: "Tipo de reserva",
    supplierPortal: "Portal",
    promoCode: "Código promocional",
    other: "Reservando por",
    byEmail: "Reserva por correo electrónico",
    bookingBy: "Reserva por",
    bookingTypeNotFound: "Ningún tipo de Reserva",
    typesOfBooking: "Tipos de reserva",
    bookingOptions: "Opciones de reserva",
    bookings: "Reservaciones",
    persons: "Personas",
    tripManager: "Gestor de viaje",
    offers: "Offers",
    initHour: "Hora de inicio",
    endHour: "Hora de fin",
    optional: "opcional",
    nReserve: "Número de reserva",
    optionalQuoteItem: "Recurso opcional",
    netValidated: "Precio Validado",
    isValidated: "Tarifas Validadas",
    paid: "Pagado",
    pending: "Pendiente",
    errorMessage: "Ha ocurrido un error",
    files: "Archivos",
    writeCity: "Escribe la ciudad",
    canSearchSupplier: "Puedes buscar el proveedor (opcional)",
    canSearchResource: "Puedes buscar el recurso (opcional)",
    day: "Día",
    notification: "Notificación",
    supplierNotFound: "Proveedor eliminado",
    markedPay: "Marcar para pagar",
    payAmount: "Cantidad a pagar",
    pendingAmount: "Monto pendiente",
    paidAmount: "Monto de pago",
    hotelName: "Nombre del hotel",
    total: "Total",
    generateXml: "Generar XML",
    priceSummary: "Resumen de precios",
    preview: "XML de pago",
    noDataAvailable: "Datos no disponibles.",
    withoutSupplier: "Sin proveedor",
    quantityShort: "Cantidad",
    paymentMethodOpt: "El proveedor ha optado por",
    supplierAccountNotFound: "Sin cuenta bancaria",
    noPaymentMethod: "No se ha seleccionado ningún método de pago",
    general_information: "General",
    stage_information: "Etapa",
    point_of_interest: "PDI",
    confirmationOfPricing: "Confirmación de reserva y precios",
    confirmPriceInfo: "Por favor introduce el precio neto que WAW TRAVEL tiene que pagarte. Si quieres ofrecernos algo diferente puedes hacerlo en el botón Modificar",
    headerTile: "¿Cuál es el precio de cada servicio? ¿quieres ofrecer algo diferente?",
    unit: "Habitaciones",
    editPrice: "Editar Precio",
    editPriceWarning: "Aquí puedes introducir el precio de cada servicio",
    editFreeTextWarning: "Aquí puedes ofrecernos algo diferente",
    companyName: "nombre de empresa",
    totalCases: "Total de casos",
    completedCases: "Casos completados",
    pendingCases: "Casos pendientes",
    yearsOld: "años",
    startDate:"Fecha de inicio",
    alternativeStartDate:"Fecha de inicio alternativa",
    clientName:"Nombre del cliente" ,
    clientEmail:"Correo electrónico del cliente",
    languagePreferedByClient:"Idioma preferido por la cliente",
    clientPhoneNumber:"Número de teléfono del cliente",
    modification: "Modificar",
    supplierList: "Lista de proveedores",
    transfer: "Transferencia",
    transport: "Transporte",
    showSuppliers: "Mostrar fornecedores",
    confirmationText: "Estás segura de que quieres { title } este caso?",
    invoice: "Factura",
    concept: "Concepto",
    remainingAmount: "Restante",
    billing: "Facturación",
    customNotes: "Nota personalizada"
  },
  button: {
    new: "Nuevo",
    close: "Cerrar",
    loading: "Cargando...",
    create: "Crear",
    save: "Guardar",
    book: "Libro",
    bookNow: "Reservar ahora",
    getAQuote: "Consigue una cotización",
    saving: "Guardando...",
    archive: "Archivar",
    archiving: "Archivando...",
    delete: "Borrar",
    deleting: "Borrar...",
    remove: "Eliminar",
    removing: "Eliminar...",
    confirm: "Confirmar",
    modify: "Modificar",
    cancel: "Cancelar",
    processing: "Procesando",
    add: "Añadir",
    adding: "Añadiendo...",
    forgiveAccess: "Perdonar acceso",
    forgivingAccess: "Perdonar acceso...",
    login: "Iniciar sesión",
    loggingIn: "Iniciar sesión...",
    restore: "Restaurar",
    restoring: "Restaurando...",
    seeAll: "Ver todos",
    next: "Siguiente",
    update: "Actualizar",
    sendEmail: "Enviar email",
    send: "Enviar",
    sending: "Enviando...",
    see: "Ver",
    selectAll: "Seleccionar todos",
    unselectAll: "Deseleccionar todos",
    download: "Descargar",
    seeFiles: "Var archivos",
    generatingPdf: "Generando PDF...",
    seeDay: "Ver día",
    view: "Ver",
    viewMore: "Ver más",
    viewLess: "Ver menos",
    yes: "Sí",
    no: "No",
    ok: "De Acuerdo",
    exportBookings: "Exportar reservas",
  },
  toast: {
    successMessage: "Mensaje de éxito",
    informationMessage: "Mensaje de información",
    adventMessage: "Mensaje de advenimiento",
    errorMessage: "Mensaje de error",
    otherTypeTooltipMessage: "Por favor detalla cualquier requisito especial o preferencia de habitación que tengas",
    exportTooltip: "Haga clic aquí para exportar sus reservas a la hoja de Google",
    showSupplierBookings: "Haga clic aquí para mostrar las reservas del proveedor",
  },
  menu: {
    impersonateStopText: "Volver como Administrador",
    impersonateText: "Has iniciado sesión como el usuario { user } ({ email }).",
    cases: "Casos",
    clients: "Clientes",
    suppliers: "Proveedores",
    resources: "Recursos",
    contacts: "Contactos",
    agencies: "Agencias",
    statistics: "Estadísticas",
    logout: "Cerrar sesión",
    myProfile: "Mi perfil",
    products: "Actividades",
  },
  adminMenu: {
    users: "Usuarios",
    agencies: "Agencias",
    collaborators: "Colaboradores",
  },
  address: {
    address: "Dirección",
    addAddress: "Añadir dirección",
    addressLine2: "Línea de dirección 2",
    billingAddress: "Dirección de facturación",
    aptSuit: "Apto, Suite, Unidad, etc",
    secondAddress: "Segunda dirección",
    city: "Ciudad",
    state: "Estado",
    country: "País",
    postalCode: "Código Postal",
    provinceStateRegion: "Provincia / Estado / Región",
    streetAddress: "Dirección",
    number: "Número",
    matchesContactAddress: "Coincide con la dirección de contacto",
  },
  screenVerify: {
    title: "Lo sentimos",
    message: "El constructo del Tour no está disponible en la versión móvil",
    openOnDesktop: "Para una mejor experiencia, recomendamos acceder desde un dispositivo con la pantalla más grande.",
  },
  vueSelect: {
    noOptions: "Lo sentimos, no hay opciones que coincidan.",
  },
  errors: {
    error404: "404",
    pageNotFound: "¡Ups! Página no encontrada",
    pageNotFoundText: "No podemos encontrar la página que busca.",
  },
  pages: {
    confirmingReservation: "Se está confirmando la reserva...",
    modifingReservation: "Se esta modificando la reserva...",
    reservationConfirmed: "Reserva confirmada!",
    reservationModified: "Reserva modificada!",
    confirmEmailStatus: "Gracias por tu respuesta Recibirás un correo eléctronico",
    modifiedStatus: "Gracias por la sugerencia. Nos pondremos en contacto contigo.",
    emailConfirmationSucess: "Gracias...",
    emailAlreadyUpdated: "Este correo electrónico ya fue actualizado por el proveedor. Si tienes alguna consulta, por favor contactanos.",
  },
};
