export default {
  cases: 'Gevallen',
  case: 'Geval',
  tour: 'Tour',
  setup: 'Setup',
  caseSetup: 'Geval setup',
  builder: 'Builder',
  pax: 'Passagiers',
  contacts: 'Contacten',
  bookings: 'Boekingen',
  pricing: 'Prijsstelling',
  preview: 'Voorbeeld',
  tourBuilder: 'Tour Builder',
  tourPax: 'Tour Pax',
  tourContacts: 'Tour Contacten',
  tourStatuses: 'Tour Statussen',
  tourPricing: 'Tour Prijsstelling',
  createTour: 'Nieuwe tour maken',
  createTourText: 'Weet u zeker dat u een nieuwe tour wilt maken voor dit geval?',
  archiveTour: 'Tour archiveren',
  selectClient: 'Selecteer klant',
  writeSearchClients: 'Schrijf om te zoeken naar klanten',
  noCases: 'Er zijn geen gevallen',
  createNewCase: 'Maak een nieuw geval',
  archivedCases: 'Gearchiveerde gevallen',
  activeCases: 'Actieve gevallen',
  basicInformation: 'Basisinformatie',
  caseDetails: 'Geval details',
  archiveText: 'U kunt een geval archiveren, als u dit doet zal het geval niet verschijnen in de applicatie zoekopdrachten. U kunt het zien in de lijst van gevallen als u filtert op \'gearchiveerd\'. Als u de details van een gearchiveerd geval bekijkt, kunt u het definitief verwijderen of het opnieuw activeren.',
  media: 'Media',
  noMedia: 'Er zijn geen bijbehorende media',
  archivedText: 'Dit geval is gearchiveerd, als je wilt kun je het herstellen door op de knop \'Herstellen\' te klikken.',
  archiveCaseTitle: 'Geval archiveren',
  archiveCaseText: 'Weet je zeker dat je het geval \'{case}\' wilt archiveren? Als je het archiveert, zal het niet verschijnen in zoekopdrachten.',
  deleteText: 'Dit geval is gearchiveerd, als je het permanent wilt verwijderen, klik dan op de knop \'Verwijderen\'. Let op dat als je het verwijdert, dit geval niet langer zal bestaan en niet kan worden hersteld.',
  deleteCaseTitle: 'Geval verwijderen',
  deleteCaseText: 'Weet je zeker dat je het geval \'{case}\' wilt verwijderen? Als je het verwijdert, kun je het niet meer herstellen.',
  restoreCaseTitle: 'Geval herstellen',
  restoreCaseText: 'Weet je zeker dat je het geval \'{case}\' wilt herstellen? Als je het herstelt, zal het niet langer gearchiveerd zijn en zal het verschijnen in de zoekopdrachten van de applicatie.',
  caseStatus: 'Geval status',
  noContacts: 'Er zijn geen bijbehorende contacten',
  editContactsBelongCaseText: 'Bewerk contacten die bij het geval horen',
  addContacts: 'Contacten toevoegen',
  removeContactTitle: 'Gevalcontact verwijderen',
  removeContactText: 'Weet je zeker dat je {contact} als contact voor het geval {case} wilt verwijderen?',
  quote: 'Quote',
  statuses: 'Statussen',
  duplicateTour: 'Dubbele tour',
  connectLuggage: 'Bagage verbinden',
  duplicatedTourSuccess: 'Hele tour succesvol gedupliceerd',
  redirectingNewTour: 'Doorverwijzen naar de nieuwe tour...',
};
