<template>
  <!-- Tablet and Desktop -->
  <div :class="{ 'is-group-individual fw-semi': isGroupIndividual }" class="w-100 w-lg-40 p-0">
    <div class="d-flex flex-column flex-lg-row align-items-end align-items-lg-center update-pricing p-0">
      <div :title="`${lastLastNetPrice.toString().replace('.', ',')}€`" class="td w-lg-25">
        <template v-if="!!totalType">
          <span class="d-lg-none">{{ $t("tourPricing.netPrice") }}: </span>
          <span>{{ localNetPrice }} €</span>
        </template>

        <div v-else class="gap-8">
          <div class="d-lg-none text-nowrap">{{ $t("tourPricing.netPrice") }}:</div>
          <div class="input-with-icon-wrapper">
            <v-form-input
              id="pricing-net-price"
              v-model="localNetPrice"
              title=""
              :class="{
                validated: netValidated === true,
                updatebysupplier: !netValidated && supplierPrice !== 0 && netValidated === false && !isTotal,
                updatebysuppliervalidated: !netValidated && isPriceValidated && !isTotal,
              }"
              :disabled="netValidated === true || accepted"
              :yup-errors-variable="errors.localNetPrice"
              autocomplete="off"
              form-type="input-group"
              onwheel="return false;"
              right-addon="€"
              step=".01"
              type="number"
              @blur="updatePricing(localNetPrice, 'net', 'localNetPrice', 'lastNetPrice', 'lastLastNetPrice')"
              @input="currentVariablePrice = 'localNetPrice'"
              @keyup.enter="$event.target.blur()"
            />
            <span class="input-with-icon-tooltip" v-if="supplierPrice !== 0 && !isTotal">
              <div
                :class="{ 'supplier-price-description': supplierPrice !== 0 && !isTotal }"
                :data-title="$t('tourPricing.supplierOldPrice') + ': ' + localOldPrice"
                title=""
              >
                <v-icon icon="warning-sign" size="xs" />
                <div class="tooltip-right-arrow"></div>
              </div>
            </span>
          </div>
        </div>
      </div>

      <div class="td w-lg-25">
        <div class="d-lg-none text-nowrap">{{ $t("tourBooking.bookingStatus") }}:</div>

        <v-form
          id="tour-resource-update-reservation-status"
          :yup-errors-variable="errors.status"
          class="w-100"
          form-type="outline"
        >
          <v-select
            id="tour-resource-update-reservation-status"
            v-model="localReservationStatus"
            class="supplier-status-list"
            :class="[`bg-status-${findConceptByKey('tour.reservation_status_type', localReservationStatus).color}`]"
            :clearable="false"
            :options="tourReservationStatusTypes"
            :reduce="(returnValue) => returnValue.key"
            @option:selected="updateItemResourceReservationStatus('reservation_status')"
          >
            <template v-slot:no-options>
              <div class="my-4">{{ $t("general.vueSelect.noOptions") }}</div>
            </template>
          </v-select>
        </v-form>
      </div>

      <div class="td w-lg-25">
        <div class="d-lg-none text-nowrap">{{ $t("tourBooking.paymentStatus") }}:</div>

        <v-form
          id="tour-resource-update-reservation-status"
          :yup-errors-variable="errors.status"
          class="w-100"
          form-type="outline"
        >
          <v-select
            id="tour-resource-update-reservation-status"
            v-model="localPaymentStatus"
            class="supplier-status-list"
            :class="[`bg-status-${findConceptByKey('tour.payment_status_type', localPaymentStatus).color}`]"
            :clearable="false"
            :options="tourPaymentStatusTypes"
            :reduce="(returnValue) => returnValue.key"
            @option:selected="updateItemResourceReservationStatus('payment_status')"
          >
            <template v-slot:no-options>
              <div class="my-4">{{ $t("general.vueSelect.noOptions") }}</div>
            </template>
          </v-select>
        </v-form>
      </div>

      <div class="td w-lg-25">
        <div class="d-lg-none text-nowrap">{{ $t("tourBooking.invoiceStatus") }}:</div>

        <v-form
          id="tour-resource-update-reservation-status"
          :yup-errors-variable="errors.status"
          class="w-100"
          form-type="outline"
        >
          <v-select
            id="tour-resource-update-reservation-status"
            v-model="localInvoiceStatus"
            class="supplier-status-list"
            :class="[`bg-status-${findConceptByKey('tour.invoice_status_type', localInvoiceStatus).color}`]"
            :clearable="false"
            :options="tourInvoiceStatusTypes"
            :reduce="(returnValue) => returnValue.key"
            @option:selected="updateItemResourceReservationStatus('invoice_status')"
          >
            <template v-slot:no-options>
              <div class="my-4">{{ $t("general.vueSelect.noOptions") }}</div>
            </template>
          </v-select>
        </v-form>
      </div>

      <div v-if="isGroupType || isTotal" class="w-15 pe-lg-4 d-flex align-items-center justify-content-center">
        <v-button
          :aria-label="$t('general.button.save')"
          :disabled="accepted || !canUpdatePricing"
          :title="$t('general.button.save')"
          class="btn-primary p-4"
          variant="icon"
          @click="updatePricingOld"
        >
          <v-icon icon="check" />
        </v-button>
      </div>
    </div>

    <!-- <div v-if="isIndividual || isGroupIndividual" class="d-flex flex-column pt-8 ps-4">
      <template v-if="isIndividual">
        <v-checkbox
          :id="`net-${id}-validated`"
          v-model="localNetValidated"
          :for-key="`net-${id}-validated`"
          :label="$t('general.shared.netValidated')"
          class="text-nowrap"
          @update:model-value="validateNetPrice"
        />
      </template>

      <template v-else>
        <v-checkbox
          v-model="localNetValidated"
          :for-key="`net-${id}-validated`"
          :input-id="`net-${id}-validated`"
          :label="$t('general.shared.netValidated')"
          class="form-check-inline text-nowrap"
          @update:model-value="validateNetPrice"
        />
      </template>
    </div> -->

    <div
      v-if="
        (isIndividual && !!paymentStatus && paymentStatus !== 'pending' && localSplitPayment) ||
        (isGroupIndividual && !paymentPending && !!localSplitPayment)
      "
      class="d-flex flex-column flex-lg-row amounts"
    >
      <v-form
        id="payment-partial-amount-paid-form"
        :label="$t('general.shared.paid')"
        class="w-50 px-4"
        form-type="outline"
      >
        <v-form-input
          id="payment-partial-amount-paid"
          v-model="localAmountPaid"
          :disabled="accepted"
          :yup-errors-variable="errors.localAmountPaid"
          autocomplete="off"
          class="form-outline"
          form-type="input-group"
          onwheel="return false;"
          right-addon="€"
          step=".1"
          type="number"
          @blur="updateItemResource()"
          @keyup.enter="$event.target.blur()"
        />
      </v-form>

      <v-form
        id="payment-partial-amount-pending-form"
        :label="$t('general.shared.pending')"
        class="w-50 px-4"
        form-type="outline"
      >
        <v-form-input
          id="payment-partial-amount-pending"
          :model-value="localAmountPending"
          :yup-errors-variable="errors.localAmountPending"
          autocomplete="off"
          class="form-outline"
          disabled
          form-type="input-group"
          onwheel="return false;"
          right-addon="€"
          step=".1"
          type="number"
        />
      </v-form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { VForm, VFormInput } from "@uniqoders/form";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import { useField } from "vee-validate";
import { mapState } from "pinia";
import api from "@/api";
import useFormValidation from "@/helpers/form";
import VButton from "@/components/vendor/basic/button/VButton.vue";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";
import { useUserStore } from "@/stores/user";
import { usePriceStore } from "@/stores/priceCalculation";
import { IPricingItemSupplierResource } from "@/views/case/parts/TheTourPricing.vue";
// import VTriStateCheckbox from "@/components/vendor/basic/form/VTriStateCheckbox.vue";
import { findConceptByKey, mapConcepts } from "@/helpers/ConceptHelper";
import useConcepts from "@/helpers/Concepts";
import vSelect from "vue-select";

export default defineComponent({
  name: "VPricingAndStatusUpdate",
  components: {
    // VTriStateCheckbox,
    vSelect,
    VForm,
    VFormInput,
    VButton,
    VIcon,
    // VCheckbox,
  },
  emits: [
    "showLoader",
    "updatePricing",
    "updateTotalPricing",
    "updateGroupIndividual",
    "updateGroupType",
    "updatedIndividual",
    "updatedTotalTypePricing",
    "updatedIndividualNetValidated",
    "updatedIndividualMarkToPay",
    "updatedIndividualPaid",
    "updatedGroupIndividualPaid",
    "updatedIndividualSplitPayment",
    "update:net",
    "update:markup",
    "update:gross",
    "update:netValidated",
    "update:markToPay",
    "update:tourDayItemResources",
    "update:amountPaid",
    "update:amountPending",
    "update:paymentComplete",
    "update:paymentPartial",
    "update:paymentPending",
    "update:paymentStatus",
    "update:splitPayment",
    "updateItemResourceReservationStatus",
    "updateItemResourcePaymentStatus",
    "updateItemResourceInvoiceStatus",
    "updateTourPriceChange",
    "loadTourPricing",
  ],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    isIndividual: {
      type: Boolean,
      required: false,
      default: false,
    },
    isGroupIndividual: {
      type: Boolean,
      required: false,
      default: false,
    },
    isGroupType: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTotal: {
      type: Boolean,
      required: false,
      default: false,
    },
    net: {
      type: Number,
      required: true,
    },
    markup: {
      type: Number,
      required: true,
    },
    gross: {
      type: Number,
      required: true,
    },
    amountPaid: {
      type: Number,
      required: false,
    },
    amountPending: {
      type: Number,
      required: false,
    },
    netValidated: {
      type: Boolean,
      required: false,
    },
    markToPay: {
      type: [Boolean, String],
      required: false,
    },
    paymentComplete: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
    paymentPartial: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
    paymentPending: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
    agency: {
      type: String,
      required: true,
    },
    caseId: {
      type: Number,
      required: true,
    },
    splitPayment: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
    tourId: {
      type: Number,
      required: true,
    },
    tourDayId: {
      type: Number,
      required: false,
    },
    tourDayItemId: {
      type: Number,
      required: false,
    },
    tourDayItemResourceId: {
      type: Number,
      required: false,
    },
    tourDayItemResourceIds: {
      type: Array,
      required: false,
    },
    totalType: {
      type: String,
      required: false,
      default: "",
    },
    paymentStatus: {
      type: String,
      required: false,
      default: "",
    },
    reservationStatus: {
      type: String,
      required: false,
      default: "",
    },
    invoiceStatus: {
      type: String,
      required: false,
      default: "",
    },
    account: {
      type: String,
      required: false,
    },
    paymentMethod: {
      type: String,
      required: false,
    },
    tourDayItemResource: {
      type: Object as PropType<IPricingItemSupplierResource>,
      required: false,
    },
    tourDayItemResources: {
      type: Array as PropType<IPricingItemSupplierResource[]>,
      required: false,
    },
    isUpdatedBySupplier: {
      type: Boolean,
      default: false,
    },
    isPriceValidated: {
      type: Boolean,
      default: false,
    },
    supplierPrice: {
      type: Number,
      required: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const userStore = useUserStore();

    const rules = yup.object({
      localNetPrice: yup
        .number()
        .required()
        .nullable()
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t("tourPricing.netPrice")),
      localMarkup: yup
        .number()
        .required()
        .nullable()
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t("tourPricing.profit")),
      localGrossPrice: yup
        .number()
        .required()
        .nullable()
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t("tourPricing.grossPrice")),
      localNetValidated: yup.mixed().nullable().oneOf([true, false, "mixed"]).label(t("general.shared.netValidated")),
      localSplitPayment: yup.mixed().nullable().oneOf([true, false, "mixed"]).label(t("tourPricing.splitPayment")),
      localMarkToPay: yup.boolean().nullable().label(t("general.shared.netValidated")),
      localAmountPaid: yup
        .number()
        .nullable()
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t("general.shared.paid")),
      localAmountPending: yup
        .number()
        .nullable()
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t("general.shared.pending")),
    });

    const initialValues: Record<string, any> = {
      localNetPrice:
        props.isGroupType || props.isTotal
          ? Number(props.net)
          : props.supplierPrice !== 0
          ? props.supplierPrice
          : Number(props.net),
      localMarkup: Number(props.markup),
      localGrossPrice: Number(props.gross),
      localNetValidated: props.netValidated,
      localSplitPayment: props.splitPayment,
      localMarkToPay: props.markToPay,
      localAmountPaid: Number(props.amountPaid),
      localAmountPending: props.amountPending,
      localPaymentStatus: props.paymentStatus,
      localReservationStatus: props.reservationStatus,
      localInvoiceStatus: props.invoiceStatus,
      localOldPrice: props.supplierPrice !== 0 ? Number(props.net) : props.supplierPrice,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: localNetPrice } = useField<number>("localNetPrice");
    const { value: localMarkup } = useField<number>("localMarkup");
    const { value: localGrossPrice } = useField<number>("localGrossPrice");
    const { value: localNetValidated } = useField("localNetValidated");
    const { value: localSplitPayment } = useField("localSplitPayment");
    const { value: localMarkToPay } = useField("localMarkToPay");
    const { value: localAmountPaid } = useField("localAmountPaid");
    const { value: localAmountPending } = useField("localAmountPending");
    const { value: localPaymentStatus } = useField("localPaymentStatus");
    const { value: localReservationStatus } = useField("localReservationStatus");
    const { value: localInvoiceStatus } = useField("localInvoiceStatus");
    const { value: localOldPrice } = useField("localOldPrice");

    return {
      findConceptByKey,
      ...useConcepts(),
      userStore,
      ...form,
      localNetPrice,
      localMarkup,
      localGrossPrice,
      localNetValidated,
      localSplitPayment,
      localMarkToPay,
      localAmountPaid,
      localAmountPending,
      localPaymentStatus,
      localReservationStatus,
      localInvoiceStatus,
      localOldPrice,
    };
  },
  data() {
    return {
      priceData: {
        updated_price: "",
        updated_value: 0,
        type: this.totalType,
      },
      currentVariablePrice: "",
      currentLastVariablePrice: "",
      currentLastLastVariablePrice: "",
      lastNetPrice: 0,
      lastMarkup: 0,
      lastGrossPrice: 0,
      lastLastNetPrice: 0,
      lastLastMarkup: 0,
      lastLastGrossPrice: 0,
      canUpdatePricing: false,
      showSplitPayment: false,
      priceChange: false,
    };
  },
  computed: {
    ...mapState(useUserStore, ["currentTour"]),
    tourReservationStatusTypes(): any {
      if (!!this.concepts && this.concepts["tour.reservation_status_type"]) {
        return mapConcepts(this.concepts["tour.reservation_status_type"]).sort((a: any, b: any) => {
          const nameA = a.label?.toLowerCase() || "";
          const nameB = b.label?.toLowerCase() || "";
          return nameA.localeCompare(nameB);
        });
      }

      return [];
    },
    tourPaymentStatusTypes(): any {
      if (!!this.concepts && this.concepts["tour.payment_status_type"]) {
        return mapConcepts(this.concepts["tour.payment_status_type"]).sort((a: any, b: any) => {
          const nameA = a.label?.toLowerCase() || "";
          const nameB = b.label?.toLowerCase() || "";
          return nameA.localeCompare(nameB);
        });
      }

      return [];
    },
    tourInvoiceStatusTypes(): any {
      if (!!this.concepts && this.concepts["tour.invoice_status_type"]) {
        return mapConcepts(this.concepts["tour.invoice_status_type"]).sort((a: any, b: any) => {
          const nameA = a.label?.toLowerCase() || "";
          const nameB = b.label?.toLowerCase() || "";
          return nameA.localeCompare(nameB);
        });
      }

      return [];
    },
  },
  watch: {
    localNetPrice(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.priceChange = true;
      }
    },
    net(newValue, oldValue) {
      this.lastNetPrice = Number(newValue);
      this.lastLastNetPrice = Number(oldValue);
      if (newValue !== oldValue) {
        const priceStore = usePriceStore();
        priceStore.setCheckUpdate(!priceStore.updatingAmount);
        this.resetInitialFormValues();
      }
    },
    markup(newValue, oldValue) {
      this.lastMarkup = Number(newValue);
      this.lastLastMarkup = Number(oldValue);
      if (newValue !== oldValue) {
        this.resetInitialFormValues();
      }
    },
    gross(newValue, oldValue) {
      this.lastGrossPrice = Number(newValue);
      this.lastLastGrossPrice = Number(oldValue);
      if (newValue !== oldValue) {
        this.resetInitialFormValues();
      }
    },
    netValidated(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetInitialFormValues();
      }
    },
    splitPayment(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetInitialFormValues();
      }
    },
    markToPay(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetInitialFormValues();
      }
    },
    amountPaid(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetInitialFormValues();
      }
    },
    amountPending(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetInitialFormValues();
      }
    },
    paymentStatus(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetInitialFormValues();
      }
    },
    invoiceStatus(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetInitialFormValues();
      }
    },
    reservationStatus(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetInitialFormValues();
      }
    },
  },
  mounted() {
    this.lastNetPrice = Number(this.net);
    this.lastMarkup = Number(this.markup);
    this.lastGrossPrice = Number(this.gross);

    this.lastLastNetPrice = Number(this.net);
    this.lastLastMarkup = Number(this.markup);
    this.lastLastGrossPrice = Number(this.gross);

    if (!!this.tourDayItemResources && this.tourDayItemResources.length > 0) {
      this.showSplitPayment = this.tourDayItemResources.some(
        (resource) =>
          resource.amountPaid === 0 ||
          (resource.amountPaid >= 0 && resource.paymentStatus === "pending") ||
          resource.paymentStatus === "partial"
      );
    }
  },
  methods: {
    togglePrice(supplierPrice: number | undefined, isUpdatedBySupplier: boolean, localNetPrice: number) {
      return supplierPrice !== 0 && isUpdatedBySupplier ? localNetPrice : supplierPrice;
    },
    onMarkPay() {
      this.$emit("update:markToPay", this.localMarkToPay);
      const priceStore = usePriceStore();

      const setPriceForItem = (itemId: number, netPrice: number, amountPending: number, paymentStatus: string) => {
        const price = this.localMarkToPay ? (paymentStatus === "partial" ? amountPending : netPrice) : 0;
        priceStore.setPrice(itemId, price);
      };

      if (this.isIndividual && !!this.tourDayItemResourceId && !!this.amountPending) {
        setPriceForItem(this.tourDayItemResourceId, this.localNetPrice, this.amountPending, this.paymentStatus);
        this.$emit("updatedIndividualMarkToPay");
      } else if (this.isGroupIndividual && !!this.tourDayItemResources && this.tourDayItemResources.length > 0) {
        this.tourDayItemResources.forEach((resource) => {
          if (resource.paymentStatus !== "complete") {
            resource.markToPay = !!this.localMarkToPay;
            setPriceForItem(resource.id, resource.netPrice, resource.amountPending, resource.paymentStatus);
          }
        });
      }
    },
    resetInitialFormValues() {
      this.resetForm({
        values: {
          localNetPrice:
            this.isGroupType || this.isTotal
              ? Number(this.net)
              : this.supplierPrice !== 0
              ? this.supplierPrice
              : Number(this.net),
          localMarkup: Number(this.markup),
          localGrossPrice: Number(this.gross),
          localNetValidated: this.netValidated,
          localSplitPayment: this.splitPayment,
          localMarkToPay: this.markToPay,
          localAmountPaid: this.amountPaid,
          localAmountPending: this.amountPending,
          localPaymentStatus: this.paymentStatus,
          localInvoiceStatus: this.invoiceStatus,
          localReservationStatus: this.reservationStatus,
        },
      });

      this.currentVariablePrice = "";
    },
    async updatePricing(
      updatedValue: number,
      updatedPrice: string,
      variable: string,
      lastVariable: string,
      lastLastVariable: string
    ) {
      this.currentVariablePrice = variable;
      this.currentLastVariablePrice = lastVariable;
      this.currentLastLastVariablePrice = lastLastVariable;
      this.priceData.updated_value = updatedValue;
      this.priceData.updated_price = updatedPrice;

      if (this.meta.valid) {
        if (this.isTotal || this.isGroupType) {
          this.canUpdatePricing = updatedValue !== this[lastVariable];
        } else if (this.isGroupIndividual) {
          if (this.priceChange && updatedValue !== this[lastVariable]) {
            await this.doUpdatePricing();
            this.$emit("loadTourPricing");
          }
        } else {
          if (this.priceChange && updatedValue !== this[lastVariable]) {
            await this.doUpdatePricing();
            this.$emit("loadTourPricing");
          }
        }
      }
    },
    async updatePricingOld() {
      await this.$modal.confirm({
        title: this.$t("tourPricing.updatePriceTitle"),
        text: this.isTotal
          ? this.$t("tourPricing.updateTotalPriceText", {
              newTotal: this[this.currentVariablePrice].toString().replace(".", ","),
              initialValue: this[this.currentLastVariablePrice].toString().replace(".", ","),
            })
          : this.$t("tourPricing.updatePriceText", {
              newTotal: this[this.currentVariablePrice].toString().replace(".", ","),
              initialValue: this[this.currentLastVariablePrice].toString().replace(".", ","),
            }),
        leftButtonClasses: "btn-tertiary",
        confirmButtonText: this.$t("general.button.confirm"),
        confirmButtonCallback: this.doUpdatePricing,
      });
    },
    emitTotalPricing(response: any) {
      const totalResponse = {
        grossPrice: response.gross_value,
        markup: response.markup_value,
        netPrice: response.net_value,
      };

      this.$emit("updateTotalPricing", totalResponse);
    },
    async doUpdatePricing() {
      try {
        this.toggleAccepted();
        this.$emit("updateTourPriceChange", true);

        const data: Record<string, any> = {
          updated_price: this.priceData.updated_price,
          updated_value: this.priceData.updated_value,
        };

        let response;

        if (this.isTotal) {
          response = await api.tourPricing.updateTourTotalPrices(this.agency, this.caseId, this.tourId, data);

          this.$toast.success(this.$t("general.shared.savedChanges"));

          const totalResponse = {
            grossPrice: response.grossPrice,
            markup: response.markup,
            netPrice: response.netPrice,
          };

          this.canUpdatePricing = false;

          this.$emit("updateTotalPricing", totalResponse);
        } else if (this.isIndividual || this.isGroupIndividual) {
          data.resources_id = this.tourDayItemResourceIds;

          response = await api.tourPricing.updatePriceByGroup(this.agency, this.caseId, this.tourId, data);

          if (this.isIndividual && !!this.tourDayItemResourceId) {
            for (const group of response.groups) {
              const itemResource = group.items.find((item: any) => item.id === this.tourDayItemResourceId);

              if (!!itemResource) {
                this.emitPriceValues(itemResource.net_value, itemResource.markup_value, itemResource.gross_value);

                if (this.paymentStatus && this.paymentStatus === "partial") {
                  this.$emit("update:amountPending", itemResource.net_value - this.amountPaid);
                }

                break;
              }
            }

            this.$emit("updatedIndividual");
          } else {
            this.emitPriceValues(
              response.groups[0].net_value,
              response.groups[0].markup_value,
              response.groups[0].gross_value
            );

            if (!!this.tourDayItemResources && this.tourDayItemResources.length > 0) {
              const tourDayItemResourcesLocal = this.tourDayItemResources;

              response.groups[0].items.forEach((item: any) => {
                const resource = tourDayItemResourcesLocal.find(
                  (resource: IPricingItemSupplierResource) => resource.id === item.id
                );

                if (!!resource) {
                  resource.netPrice = item.net_value;
                  resource.markup = item.markup_value;
                  resource.grossPrice = item.gross_value;
                }
              });
            }

            this.$emit("updateGroupIndividual", response.groups[0].items);
          }

          const groupTypeResponse = {
            grossPrice: response.total.type.gross_value,
            markup: response.total.type.markup_value,
            netPrice: response.total.type.net_value,
          };

          this.$emit("updateGroupType", groupTypeResponse);

          this.emitTotalPricing(response.total.tour);
          this.$toast.success(this.$t("general.shared.savedChanges"));
        } else if (this.isGroupType) {
          data.type = this.totalType;

          response = await api.tourPricing.updateAllMarkupByType(this.agency, this.caseId, this.tourId, data);

          this.canUpdatePricing = false;

          this.emitPriceValues(
            response.groups[0].net_value,
            response.groups[0].markup_value,
            response.groups[0].gross_value
          );

          this.$emit("updatedTotalTypePricing", response.groups[0].items);

          this.emitTotalPricing(response.total.tour);
        }
      } catch (e: any) {
        this.currentVariablePrice = "";

        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.toggleAccepted();
        this.$emit("updateTourPriceChange", false);
      }
    },
    emitPriceValues(netPrice: number, markup: number, grossPrice: number) {
      this.$emit("update:net", netPrice);
      this.$emit("update:markup", markup);
      this.$emit("update:gross", grossPrice);
    },
    async validateNetPrice() {
      this.$emit("updateTourPriceChange", true);
      try {
        const data = {
          resource_id: this.tourDayItemResourceIds,
          value: this.localNetValidated,
        };
        await api.tourPricing.validateNetPrice(this.agency, this.caseId, this.tourId, data);
        this.$emit("update:netValidated", this.localNetValidated);

        if (this.isGroupIndividual && !!this.tourDayItemResources && this.tourDayItemResources.length > 0) {
          this.tourDayItemResources.forEach((resource: IPricingItemSupplierResource) => {
            resource.netValidated = !!this.localNetValidated;
          });
        } else {
          this.$emit("updatedIndividualNetValidated");
        }

        if (this.localNetValidated) {
          this.$emit("loadTourPricing");
        }
        this.$emit("updateTourPriceChange", false);
        this.$toast.success(this.$t("general.shared.savedChanges"));
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
    async updateItemResource() {
      if (this.meta.valid) {
        try {
          this.toggleAccepted();
          const priceStore = usePriceStore();
          priceStore.setCheckUpdate(!priceStore.updatingAmount);

          const data = {
            updated_value: this.localAmountPaid,
            resources_id: this.tourDayItemResourceIds,
          };

          const response = await api.tourPricing.updateAmountPaid(
            !!this.slugName ? this.slugName : this.agency,
            this.caseId,
            this.tourId,
            data
          );

          const group = response.groups[0];

          this.$emit("update:amountPaid", group.amount_paid);
          this.$emit("update:amountPending", group.amount_pending);

          if (this.isIndividual) {
            const resource = response.groups[0].items[0];

            this.$emit("update:netValidated", resource.net_validated);
            this.$emit("update:paymentStatus", resource.payment_status);
            this.$emit("updatedIndividualPaid");
          } else if (this.isGroupIndividual && !!this.tourDayItemResources && this.tourDayItemResources.length > 0) {
            group.items.forEach((item: any) => {
              const resource = this.tourDayItemResources.find(
                (resource: IPricingItemSupplierResource) => resource.id === item.id
              );

              if (!!resource) {
                resource.amountPaid = item.amount_paid;
                resource.amountPending = item.amount_pending;
                resource.paymentStatus = item.payment_status;
                resource.netValidated = item.net_validated;
              }
            });

            this.$emit("updatedGroupIndividualPaid");
          }
        } catch (e: any) {
          console.error(e);

          this.$toast.error(e.response.data.message);
        } finally {
          this.toggleAccepted();
        }
      }
    },
    // async compelatePaymentStatus() {
    //   const ids = this.tourDayItemResources.map((obj) => obj.id);
    //   const payload = {
    //     status: "complete",
    //     status_type: "payment",
    //     resources_id: ids,
    //   };
    //   const response = await api.tourDayItemResource.updateStatusBulk(
    //     !!this.slugName ? this.slugName : this.agency,
    //     this.caseId,
    //     this.tourId,
    //     payload
    //   );

    //   if (response) {
    //     this.$toast.success(this.$t("general.shared.savedChanges"));
    //     this.$emit("loadTourPricing");
    //   }
    // },
    async doUpdatePaymentStatus() {
      if (this.isGroupIndividual && !!this.tourDayItemResources && this.tourDayItemResources.length > 0) {
        if (this.localSplitPayment) {
          this.$emit("update:splitPayment", this.localSplitPayment);

          const resources = this.tourDayItemResources.filter(
            (resource) =>
              resource.paymentStatus !== "partial" && resource.paymentStatus !== "paid" && resource.amountPaid === 0
          );

          if (!!resources && resources.length > 0) {
            const data = {
              status_type: "payment",
              status: "partial",
              resources_id: resources.map((resource) => resource.id),
            };

            const response = await api.tourBooking.update(
              !!this.slugName ? this.slugName : this.agency,
              this.caseId,
              this.tourId,
              data
            );

            const group = response.groups[0];

            group.items.forEach((item: any) => {
              const resource = this.tourDayItemResources.find(
                (resource: IPricingItemSupplierResource) => resource.id === item.id
              );

              if (!!resource) {
                resource.paymentStatus = item.paymentStatus;
                resource.splitPayment = item.paymentStatus === "partial";
              }
            });

            this.$emit("updatedGroupIndividualPaid");
          }
        } else {
          this.$emit("update:splitPayment", this.localSplitPayment);

          const resources = this.tourDayItemResources.filter(
            (resource) => resource.paymentStatus === "partial" && resource.amountPaid === 0
          );

          resources.forEach((resource: any) => {
            resource.splitPayment = this.localSplitPayment;
            resource.paymentStatus = "pending";
          });
          if (!!resources && resources.length > 0) {
            const data = {
              status_type: "payment",
              status: "pending",
              resources_id: resources.map((resource) => resource.id),
            };

            const response = await api.tourBooking.update(
              !!this.slugName ? this.slugName : this.agency,
              this.caseId,
              this.tourId,
              data
            );

            const group = response.groups[0];

            group.items.forEach((item: any) => {
              const resource = this.tourDayItemResources.find(
                (resource: IPricingItemSupplierResource) => resource.id === item.id
              );

              if (!!resource) {
                resource.paymentStatus = item.paymentStatus;
                resource.splitPayment = item.paymentStatus === "pending";
              }
            });

            this.$emit("updatedGroupIndividualPaid");
          }
        }
      } else {
        if (this.localSplitPayment) {
          const data = {
            status_type: "payment",
            status: "partial",
            resources_id: this.tourDayItemResourceIds,
          };

          const response = await api.tourBooking.update(
            !!this.slugName ? this.slugName : this.agency,
            this.caseId,
            this.tourId,
            data
          );

          const group = response.groups[0];
          const resource = group.items[0];

          this.$emit("update:paymentStatus", resource.paymentStatus);
          this.$emit("update:splitPayment", resource.paymentStatus === "partial");
          this.$emit("updatedIndividualSplitPayment");
        } else {
          this.$emit("update:paymentStatus", "pending");
          this.$emit("update:splitPayment", false);
          this.$emit("updatedIndividualSplitPayment");
        }
      }
    },
    async updateItemResourceReservationStatus(type: string) {
      try {
        this.toggleAccepted();
        this.$emit("updateTourPriceChange", true);

        let status = "";

        if (type === "reservation_status") {
          status = this.localReservationStatus;
        } else if (type === "payment_status") {
          status = this.localPaymentStatus;
        } else {
          status = this.localInvoiceStatus;
        }
        const data = {
          status: status,
          status_type: type.split("_")[0],
          resources_id: this.tourDayItemResourceIds,
        };

        const response = await api.tourDayItemResource.updateStatusBulk(this.agency, this.caseId, this.tourId, data);

        if (response) {
          this.$toast.success(this.$t("general.shared.savedChanges"));
        }

        this.$emit("loadTourPricing");
      } catch (e: any) {
        console.error(e);
        this.$toast.error(e.response.data.message);
      } finally {
        this.$emit("updateTourPriceChange", false);
        this.toggleAccepted();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.is-group-individual {
  :deep(.form-control) {
    font-weight: 600;
  }
}

.is-mobile {
  @media (max-width: 992px) {
    .update-pricing {
      &:not(.is-total) {
        .td {
          padding-inline: 0;
          padding-block: 0.25rem;
          width: 100%;
        }
      }
    }

    .amounts {
      .form-outline {
        width: 100% !important;
      }
    }
  }
}

.update-pricing {
  .validated {
    :deep(.form-control),
    :deep(.input-group-text) {
      background-color: var(--uq-info-light);
    }
  }

  .updatebysupplier {
    :deep(.form-control),
    :deep(.input-group-text) {
      background-color: #f9c3ee;
    }
  }

  .updatebysuppliervalidated {
    :deep(.form-control),
    :deep(.input-group-text) {
      background-color: rgba(var(--uq-warning-rgb), 0.25);
    }
  }

  &:not(.is-total) {
    .td {
      padding-inline: 0.25rem;
      width: calc(100% / 3);
    }
  }

  :deep(.form-control) {
    padding-inline: 1rem;
    min-height: 32px !important;
    height: 32px !important;
  }

  :deep(.input-group-text) {
    height: 32px;
  }

  &.is-total {
    display: flex !important;
    width: 100%;

    .td {
      padding-inline: 0.375rem;
      font-weight: 600;
      width: calc(86% / 3);
    }

    :deep(.form-control) {
      padding-inline: 1rem;
      font-weight: 600;
    }

    :deep(.input-group-text) {
      font-weight: 500;
    }
  }
}

.input-with-icon-wrapper {
  position: relative;
}

.input-with-icon-tooltip {
  position: absolute;
  top: -12px;
  right: -3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f9c3ee;
  cursor: pointer;
}

.supplier-price-description {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
}

.supplier-price-description[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

.supplier-price-description[data-title]:after {
  content: attr(data-title);
  background-color: var(--uq-info-light);
  font-size: 13px;
  position: absolute;
  padding: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 3px var(--uq-info-light);
  opacity: 0;
  border: 1px solid var(--uq-info-light);
  z-index: 99999;
  visibility: hidden;
  border-radius: 5px;
  top: -36px;
  right: 0;
  height: 30px;
  width: fit-content;
  line-height: 20px;
  font-family: "Poppins", sans-serif;
}

.tooltip-right-arrow {
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  width: 10px;
  height: 10px;
  background: var(--uq-info-light);
  position: absolute;
  top: -8px;
  right: 5px;
  visibility: hidden;
  opacity: 0;
}

.supplier-price-description[data-title]:hover .tooltip-right-arrow {
  visibility: visible;
  opacity: 1;
  transition: all 0.1s ease 0.5s;
}

.supplier-status-list :deep(.vs__search) {
  padding-inline: 0 !important;
  min-height: 1.9375rem !important;
}

.supplier-status-list :deep(.vs__selected) {
  padding-inline: 0.5rem !important;
  min-height: 1.9375rem !important;
}

.supplier-status-list :deep(.vs__actions) {
  padding: 0 !important;
  padding-right: 5px !important;
}

.supplier-status-list :deep(.vs__dropdown-menu) {
  min-width: 100px !important;
}
</style>
