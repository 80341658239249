<template>
    <div :id="`accordion-tour-day-optional-info-${tourTemplate?.optionalInformation?.id}`">
      <div id="page-resume-contact">
        <div
          :id="`tour-day-head-optional-info-${tourTemplate?.optionalInformation?.id}`"
          class="day-simple-data cursor-pointer break-avoid"
        >
        <div
          :aria-controls="`collapse-tour-day-optional-info-${tourTemplate?.optionalInformation?.id}`"
          :data-bs-target="`#collapse-tour-day-optional-info-${tourTemplate?.optionalInformation?.id}`"
          aria-expanded="true"
          class="accordion-button bg-transparent p-0 gap-12"
          data-bs-toggle="collapse"
        >
          <h3 class="h5 mb-12">{{ $t("clientTour.optional") }}</h3>
        </div>
      </div>
  
        <div
          :id="`collapse-tour-day-optional-info-${tourTemplate?.optionalInformation?.id}`"
          :aria-labelledby="`tour-day-head-optional-info-${tourTemplate?.optionalInformation?.id}`"
          :data-bs-parent="`#accordion-tour-day-optional-info-${tourTemplate?.optionalInformation?.id}`"
          class="before-tour-container bg-white w-100 p-28 accordion-collapse collapse show"
        >
          <div v-for="(lang, index) in tourTemplate?.optionalInformation?.translations" :key="index">
            <div v-if="currentLanguage === lang.locale && lang?.description">
              <div class="message d-flex justify-content-center flex-column pb-32">
                <div :id="`collapseText-${index}`" :class="{ show: lang?.description?.length <= 500 }"
                  class="collapse collapsable-text">
                  <div v-html="lang?.description?.trim()?.replace(/\s+/g, ' ')" />
                </div>
  
                <v-button v-if="lang?.description?.trim()?.length > 500" ref="collapseButton"
                  :aria-controls="`collapseText-${index}`" :data-bs-target="`#collapseText-${index}`"
                  aria-expanded="false" class="btn-sm mx-auto" data-bs-toggle="collapse" type="button" variant="primary"
                  @click="toggleCollapsedText">
                  {{ isLargeTextCollapsed ? $t("general.button.viewMore") : $t("general.button.viewLess") }}
                </v-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  
  import { defineComponent } from "vue";
  import VButton from "@/components/vendor/basic/button/VButton.vue";
  
  export default defineComponent({
    name: "TheTravelBookOptionalInfo",
    components: {
      VButton,
    },
    props: {
      tourTemplate: {
        type: [] as any,
        required: true,
      },
    },
    data() {
      return {
        isLargeTextCollapsed: true,
        currentLanguage: localStorage.getItem("language"),
      };
    },
    methods: {
      toggleCollapsedText() {
        this.isLargeTextCollapsed = !this.isLargeTextCollapsed;
      },
    },
  });
  </script>
  
  <style lang="scss" scoped>
  .hide-print {
    @media print {
      display: none;
    }
  }
  
  .before-tour-container {
    border-radius: 0.5rem;
    box-shadow: 0 1px 0.25rem var(--uq-gray-300);
  }
  
  .message {
    @media print {
      font-size: 0.875rem;
    }
  
    .collapsable-text.collapsing {
      height: 300px;
    }
  
    .collapsable-text {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4rem;
        opacity: 0;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 100%);
        transition: opacity 0.1s ease-out;
      }
  
      &.collapsing {
        &:after {
          opacity: 1;
        }
      }
  
      &.collapse:not(.show) {
        display: block !important;
        height: 300px;
        max-height: 300px;
        overflow: hidden;
        position: relative;
  
        &:after {
          opacity: 1;
        }
      }
    }
  
    .btn {
      width: fit-content;
    }
  }
  </style>
  