<template>
  <div class="tour-reservations">
    <div v-if="updatingTourStatus" class="loader-tour-container">
      <v-loader />
    </div>
    <v-loader v-if="status === 'loading'" />

    <template v-else>
      <div v-if="bookings.length === 0" class="text-sm">{{ $t("tourBooking.noBookings") }}</div>

      <template v-else>
        <div class="mb-48 d-flex justify-content-end">
          <v-button
            :disabled="disableSelectAll"
            class="btn-icon w-fit align-self-end"
            size="sm"
            variant="primary"
            @click="openSendNotificationsModal"
          >
            <v-icon icon="sent" size="sm" space="me-12" />
            {{ $t("tourBooking.sendNotification") }}
          </v-button>
        </div>

        <div v-for="(type, typeIndex) in bookings" :key="typeIndex" class="mb-56 item-list">
          <div class="mb-48 d-flex justify-content-between">
            <div>
              <v-button
                :disabled="disableSelectAll"
                class="w-fit align-self-end"
                size="sm"
                variant="primary"
                @click="toggleSelectButton(type.name)"
              >
                {{ selectAllToggle[type.name] ? $t("general.button.unselectAll") : $t("general.button.selectAll") }}
              </v-button>
            </div>
          </div>
          <div
            :aria-controls="`collapse-${type.name}`"
            :data-bs-target="`#collapse-${type.name}`"
            aria-expanded="true"
            class="resource-header accordion-toggle align-items-center"
            data-bs-toggle="collapse"
          >
            <div class="pb-20 d-flex align-items-center">
              <div class="bg-secondary rounded-xs me-8">
                <v-icon :icon="getAssociatedIcon(type.name)" size="xxl" space="p-12" />
              </div>

              <h3 class="mb-0 h4 ff-secondary">
                {{ findConceptByKey("supplier.type", type.name).name }} - {{ $t("tourBooking.statuses") }}
              </h3>
            </div>

            <v-icon class="accordion-icon" icon="chevron-up" size="xl" />
          </div>

          <div :id="`collapse-${type.name}`" class="resources-container accordion-body collapse show">
            <div class="suppliers-body">
              <div
                v-for="(supplier, suppliersIndex) in type.suppliers"
                :key="suppliersIndex"
                class="supplier-container d-flex w-100"
              >
                <div class="w-auto px-8 ps-0 ps-lg-8">
                  <div class="py-20 d-flex flex-column align-items-center">
                    <v-checkbox
                      v-if="!!supplier.email"
                      :id="`reservations-supplier-${type.name}-check`"
                      v-model="supplier.invoiceChecked"
                      :for-key="`reservations-supplier-${type.name}-check`"
                      :label="' '"
                      class="m-0 form-check-inline d-flex align-items-center"
                      label-class="ms-0 ps-8 ps-md-16"
                      @update:model-value="
                        changeAllCheckSupplierResources(type.name, supplier.id, supplier.invoiceChecked, 'all')
                      "
                    />
                  </div>
                </div>

                <div class="p-12 supplier-data w-100">
                  <div
                    :aria-controls="`collapse-${type.name}-${suppliersIndex}`"
                    :data-bs-target="`#collapse-${type.name}-${suppliersIndex}`"
                    aria-expanded="true"
                    class="flex-wrap border border-0 d-flex justify-content-between align-items-center accordion-toggle gx-12 w-100"
                    data-bs-toggle="collapse"
                  >
                    <div class="td w-100">
                      <div class="email-container d-flex justify-content-between">
                        <div class="d-flex">
                          <div class="rounded-sm image-container bg-tertiary me-12">
                            {{ supplier.name.charAt(0) }}
                          </div>

                          <div class="d-flex flex-column">
                            <div class="pt-8 fw-medium">
                              {{ supplier.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <v-icon class="accordion-icon" icon="chevron-up" size="md" />
                  </div>
                  <div class="td d-flex w-100">
                    <div class="hidden-conatiner"></div>
                    <div class="td supplier-info align-items-center row">
                      <div v-if="!!supplier.siteUrl" class="pt-0 text-sm d-flex col-12 col-lg-2">
                        <a
                          :aria-label="supplier.siteUrl"
                          :href="supplier.siteUrl"
                          class="text-sm btn-link text-nowrap"
                          data-bs-target
                          data-bs-toggle="collapse"
                          rel="noopener noreferrer"
                          @click.prevent.stop="openWebsite(supplier.siteUrl)"
                        >
                          {{ $t("general.shared.viewWebsite") }}
                        </a>
                      </div>
                      <div v-else class="pt-0 text-sm d-flex empty-section col-12 col-lg-2">{{}}</div>
                      <div class="col-12 col-lg-3">
                        <template v-if="supplier.bookingType === 'Other'">
                          <a
                            :aria-label="$t('general.shared.bookingBy')"
                            :href="supplier.bookingTypeMeta"
                            class="text-sm resource-link btn-link"
                            rel="noopener noreferrer"
                            target="_blank"
                            @click.prevent.stop
                          >
                            {{ $t("general.shared.bookingBy") }} : {{ supplier.bookingTypeMeta }}
                          </a>
                        </template>
                        <template v-else-if="supplier.bookingType === 'Email'">
                          <a
                            :aria-label="$t('general.shared.bookingBy')"
                            :href="supplier.bookingTypeMeta"
                            class="text-sm resource-link btn-link"
                            rel="noopener noreferrer"
                            target="_blank"
                            @click.prevent.stop
                          >
                            {{ $t("general.shared.bookingBy") }} {{ supplier.bookingType }}
                          </a>
                        </template>
                        <template v-else-if="supplier.bookingType === 'Portal'">
                          <a
                            :aria-label="$t('general.shared.bookingBy')"
                            :href="supplier.bookingTypeMeta"
                            class="text-sm resource-link btn-link"
                            rel="noopener noreferrer"
                            target="_blank"
                            @click.prevent.stop="openWebsite(supplier.bookingTypeMeta)"
                          >
                            {{ $t("general.shared.bookingBy") }} {{ supplier.bookingType }}
                          </a>
                        </template>
                        <template v-else-if="supplier.bookingType === 'Promo code'">
                          <a
                            :aria-label="$t('general.shared.promoCode')"
                            :href="supplier.bookingTypeMeta"
                            class="text-sm resource-link btn-link"
                            rel="noopener noreferrer"
                            target="_blank"
                            @click.prevent.stop
                          >
                            {{ $t("general.shared.promoCode") }} : {{ supplier.bookingTypeMeta }}
                          </a>
                        </template>
                        <template v-else-if="supplier.bookingType === 'Website'">
                          <a
                            aria-label="website"
                            :href="supplier.bookingTypeMeta"
                            class="text-sm resource-link btn-link"
                            rel="noopener noreferrer"
                            target="_blank"
                            @click.prevent.stop
                          >
                            {{ $t("general.shared.bookingBy") }} {{ supplier.bookingType }}
                          </a>
                        </template>
                        <template v-else>
                          <div>
                            <span class="badge btn-danger fw-normal">
                              {{ $t("general.shared.bookingTypeNotFound") }}
                            </span>
                          </div>
                        </template>
                      </div>
                      <div class="ps-lg-0 col-12 col-lg-4">
                        <div v-if="!supplier.email" class="pe-16">
                          <span class="badge bg-danger fw-normal">{{ $t("tourBooking.supplierNoEmail") }}</span>
                        </div>
                        <div v-if="supplier.typeEmail === 'never'" class="pe-16">
                          <span class="badge bg-danger fw-normal">
                            {{ $t("general.shared.notNotificationsSentMail") }}
                          </span>
                        </div>
                        <div v-if="supplier.typeEmail !== 'never' && supplier.email">{{}}</div>
                      </div>
                      <div v-if="!!supplier.paymentMethod" class="text-sm col-12 col-lg-3">
                        <div v-if="supplier.paymentMethod === 'bank_transfer' && supplier.account === null">
                          <span class="badge bg-danger fw-normal">
                            {{ $t("general.shared.supplierAccountNotFound") }}
                          </span>
                        </div>
                        <a
                          :aria-label="$t('general.shared.paymentBy')"
                          href=""
                          v-else-if="supplier.paymentMethod !== 'payment_link'"
                          class="resource-link btn-link"
                          rel="noopener noreferrer"
                          @click.prevent.stop
                        >
                          {{
                            supplier.paymentMethod.charAt(0).toUpperCase() +
                            supplier.paymentMethod.split("_").join(" ").slice(1)
                          }}
                          {{ supplier.paymentDate }}
                        </a>
                        <template v-else>
                          <a
                            v-if="isPaymentLinkAvailable(supplier)"
                            :aria-label="$t('general.shared.paymentBy')"
                            :href="getPaymentLink(supplier)"
                            class="resource-link btn-link"
                            rel="noopener noreferrer"
                            target="_blank"
                            @click.prevent.stop="openWebsite(getPaymentLink(supplier))"
                          >
                            {{
                              supplier.paymentMethod.charAt(0).toUpperCase() +
                              supplier.paymentMethod.split("_").join(" ").slice(1)
                            }}
                            {{ supplier.paymentDate }}
                          </a>
                          <a
                            v-else
                            :aria-label="$t('general.shared.paymentBy')"
                            href=""
                            class="text-sm resource-link btn-link"
                            rel="noopener noreferrer"
                            @click.prevent.stop
                          >
                            {{
                              supplier.paymentMethod.charAt(0).toUpperCase() +
                              supplier.paymentMethod.split("_").join(" ").slice(1)
                            }}
                            {{ supplier.paymentDate }}
                          </a>
                        </template>
                      </div>
                      <div v-else class="col-12 col-lg-3">
                        <span class="badge bg-danger fw-normal">
                          {{ $t("general.shared.noPaymentMethod") }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="mt-8 d-flex send-notification align-self-start justify-content-end me-16">
                    <v-button
                      :disabled="disableSelectAll"
                      class="btn-icon w-fit align-self-center"
                      size="sm"
                      variant="primary"
                      @click="
                        changeAllCheckSupplierResources(
                          type.name,
                          supplier.id,
                          (supplier.invoiceChecked = true),
                          'individual'
                        );
                        openSendNotificationsModalIndividual();
                      "
                    >
                      <v-icon icon="sent" size="sm" />
                    </v-button>
                  </div>

                  <div :id="`collapse-${type.name}-${suppliersIndex}`" class="accordian-body collapse show">
                    <div>
                      <div class="d-none d-lg-block">
                        <div class="resources-header">
                          <div>
                            <div class="d-flex w-100">
                              <div class="w-05 th">
                                <span :aria-label="$t('tourBooking.selectResource')" />
                              </div>
                              <div class="w-25 th">{{ $t("general.shared.name") }}</div>
                              <div class="w-20 th">{{ $t("tourBooking.dates") }}</div>
                              <div class="w-20 fit th">{{ $t("tourBooking.bookingStatus") }}</div>
                              <div class="w-20 fit th">{{ $t("tourBooking.paymentStatus") }}</div>
                              <div class="w-20 fit th">{{ $t("tourBooking.invoiceStatus") }}</div>
                            </div>
                          </div>
                        </div>

                        <div class="resources-body d-none d-xl-block">
                          <div
                            v-for="(itemResource, resourcesIndex) in supplier.itemResourcesGrouped"
                            :key="resourcesIndex"
                            class="resource-data"
                            :class="{ 'bg-gray-200': resourcesIndex % 2 !== 0 }"
                          >
                            <div
                              v-if="itemResource.resources.length > 1"
                              :aria-controls="`collapse-tour-day-item-resource-${itemResource.id}-dropdown`"
                              :data-bs-target="`#collapse-tour-day-item-resource-${itemResource.id}-dropdown`"
                              aria-expanded="false"
                              class="accordion-toggle collapsed"
                              data-bs-toggle="collapse"
                            >
                              <v-icon class="accordion-icon group-status" icon="chevron-up" />
                            </div>
                            <div class="mb-12 text-sm row align-items-center gx-12 w-100 pe-24">
                              <the-tour-resource-reservations-status
                                :agency="agency.slug"
                                :booking-type="type.name"
                                :client-tour-id="Number(caseId)"
                                :show-checkbox="!!supplier.email && supplier.typeEmail !== 'never'"
                                :supplier="supplier"
                                :tour-day-id="itemResource.tourDayId"
                                :tour-day-item-id="itemResource?.tourDayItemIds[0]"
                                :tour-day-item-resource="itemResource"
                                :tour-day-item-resource-id="itemResource.tourDayItemResourceIds"
                                :tour-id="Number(tourId)"
                                @update-item-resource-reservation-status="itemResource.reservationStatus = $event"
                                @update-item-resource-payment-status="itemResource.paymnetStatus = $event"
                                @update-item-resource-invoice-status="itemResource.invoiceStatus = $event"
                                @update-item-resource-amount-paid="itemResource.amountPaid = $event"
                                @update-item-resource-check="
                                  itemResource.invoiceChecked = $event;
                                  checkSupplierResourceGroup(supplier, itemResource);
                                "
                                @set-current-item="setCurrentItem(itemResource)"
                                @delete-item="deleteItem(itemResource)"
                                @emit-load-tour="
                                  loadTourBooking(false);
                                  updatingTourStatus = true;
                                "
                              />
                            </div>
                            <div
                              :id="`collapse-tour-day-item-resource-${itemResource.id}-dropdown`"
                              class="accordian-body collapse"
                            >
                              <div v-if="itemResource.resources.length > 1">
                                <div v-for="(tourDayItemResource, index) in itemResource.resources" :key="index">
                                  <div class="py-16 text-sm row align-items-center gx-12 w-100 pe-24 dashed">
                                    <the-tour-resource-reservations-status
                                      :agency="agency.slug"
                                      :booking-type="type.name"
                                      :client-tour-id="Number(caseId)"
                                      :show-checkbox="!!supplier.email && supplier.typeEmail !== 'never'"
                                      :supplier="supplier"
                                      :tour-day-id="tourDayItemResource.tourDayId"
                                      :tour-day-item-id="tourDayItemResource?.tourDayItemIds"
                                      :tour-day-item-resource="tourDayItemResource"
                                      :tour-day-item-resource-id="[tourDayItemResource.id]"
                                      :tour-id="Number(tourId)"
                                      @update-item-resource-reservation-status="
                                        tourDayItemResource.reservationStatus = $event
                                      "
                                      @update-item-resource-payment-status="tourDayItemResource.paymnetStatus = $event"
                                      @update-item-resource-invoice-status="tourDayItemResource.invoiceStatus = $event"
                                      @update-item-resource-amount-paid="tourDayItemResource.amountPaid = $event"
                                      @update-item-resource-check="
                                        tourDayItemResource.invoiceChecked = $event;
                                        checkSupplierResource(itemResource, supplier);
                                      "
                                      @set-current-item="setCurrentItem(tourDayItemResource)"
                                      @delete-item="deleteItem(tourDayItemResource)"
                                      @emit-load-tour="
                                        loadTourBooking(false);
                                        updatingTourStatus = true;
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="resources-body d-xl-none">
                        <div
                          v-for="(itemResource, resourcesIndex) in supplier.itemResourcesGrouped"
                          :key="resourcesIndex"
                          class="resource-data"
                          :class="{ 'bg-gray-200': resourcesIndex % 2 !== 0 }"
                        >
                          <div
                            v-if="itemResource.resources.length > 1"
                            :aria-controls="`collapse-tour-day-item-resource-${itemResource.id}-dropdown`"
                            :data-bs-target="`#collapse-tour-day-item-resource-${itemResource.id}-dropdown`"
                            aria-expanded="false"
                            class="accordion-toggle collapsed"
                            data-bs-toggle="collapse"
                          >
                            <v-icon class="accordion-icon group-status" icon="chevron-up" />
                          </div>
                          <div class="text-sm row align-items-center gx-12 w-100">
                            <the-tour-resource-reservations-mobile
                              :agency="agency.slug"
                              :booking-type="type.name"
                              :client-tour-id="Number(caseId)"
                              :tour-day-id="itemResource?.tourDayId"
                              :tour-day-item-id="itemResource.tourDayItemIds"
                              :tour-day-item-resource="itemResource"
                              :tour-day-item-resource-id="itemResource.tourDayItemResourceIds"
                              :tour-id="Number(tourId)"
                              @update-item-resource-reservation-status="itemResource.reservationStatus = $event"
                              @update-item-resource-payment-status="itemResource.paymnetStatus = $event"
                              @update-item-resource-invoice-status="itemResource.invoiceStatus = $event"
                              @update-item-resource-amount-paid="itemResource.amountPaid = $event"
                              @update-item-resource-check="
                                itemResource.invoiceChecked = $event;
                                checkSupplierResourceGroup(supplier, itemResource);
                              "
                              @set-current-item="setCurrentItem(itemResource)"
                              @delete-item="deleteItem(itemResource)"
                              @emit-load-tour="
                                loadTourBooking(true);
                                updatingTourStatus = true;
                              "
                            />
                          </div>
                          <div
                            :id="`collapse-tour-day-item-resource-${itemResource.id}-dropdown`"
                            class="accordian-body collapse"
                          >
                            <div v-if="itemResource.resources.length > 1">
                              <div v-for="(tourDayItemResource, index) in itemResource.resources" :key="index">
                                <div class="py-16 text-sm row align-items-center gx-12 pe-36 dashed">
                                  <the-tour-resource-reservations-mobile
                                    :agency="agency.slug"
                                    :booking-type="type.name"
                                    :client-tour-id="Number(caseId)"
                                    :tour-day-id="tourDayItemResource?.tourDayId"
                                    :tour-day-item-id="tourDayItemResource.tourDayItemId"
                                    :tour-day-item-resource="tourDayItemResource"
                                    :tour-day-item-resource-id="[tourDayItemResource.id]"
                                    :tour-id="Number(tourId)"
                                    @update-item-resource-reservation-status="
                                      tourDayItemResource.reservationStatus = $event
                                    "
                                    @update-item-resource-payment-status="tourDayItemResource.paymnetStatus = $event"
                                    @update-item-resource-invoice-status="tourDayItemResource.invoiceStatus = $event"
                                    @update-item-resource-amount-paid="tourDayItemResource.amountPaid = $event"
                                    @update-item-resource-check="
                                      tourDayItemResource.invoiceChecked = $event;
                                      checkSupplierResource(itemResource, supplier);
                                    "
                                    @set-current-item="setCurrentItem(tourDayItemResource)"
                                    @delete-item="deleteItem(tourDayItemResource)"
                                    @emit-load-tour="
                                      loadTourBooking(true);
                                      updatingTourStatus = true;
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>

    <the-tour-statuses-send-notification-off-canvas
      v-if="offCanvasToShow === 'the-tour-statuses-send-notification-off-canvas'"
      :agency="agency.slug"
      :case-id="Number(caseId)"
      :resources="resources"
      :supplier-data-for-email="supplierDataForEmail"
      :tour-id="Number(tourId)"
      @closed="closeOffCanvas()"
      @reload-items="loadTourBooking(true)"
    />

    <the-tour-day-item-details-off-canvas
      v-if="offCanvasToShow === 'the-tour-day-item-details-off-canvas'"
      :agency="agency.slug"
      :client-tour-id="Number(caseId)"
      :status="status"
      :tour-id="Number(tourId)"
      @closed="
        closeOffCanvas();
        clearCurrentTourDayItem();
        loadTourBooking(false);
      "
      @emit-reload-tour="loadTourBooking(true)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { TYPE } from "vue-toastification";
import { VCheckbox } from "@uniqoders/form";
import api from "@/api";
import { useAuthStore } from "@/stores/auth";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";
import { findConceptByKey, mapConcepts } from "@/helpers/ConceptHelper";
import VLoader from "@/components/vendor/loader/VLoader.vue";
import { useUserStore } from "@/stores/user";
import useOffCanvasUtils from "@/helpers/OffCanvasUtils";
import TourDayItemResource from "@/api/objects/TourDayItemResource";
import Supplier from "@/api/objects/Supplier";
import TheTourResourceReservationsStatus from "@/components/reservation/TheTourResourceReservationsStatus.vue";
import TheTourResourceReservationsMobile from "@/components/reservation/TheTourResourceReservationsMobile.vue";
import VButton from "@/components/vendor/basic/button/VButton.vue";
import TheTourStatusesSendNotificationOffCanvas from "@/components/reservation/TheTourStatusesSendNotificationOffCanvas.vue";
import useConcepts from "@/helpers/Concepts";
import TheTourDayItemDetailsOffCanvas from "@/components/tour/TheTourDayItemDetailsOffCanvas.vue";

interface SelectAllToggleState {
  [key: string]: boolean;
}

export default defineComponent({
  name: "TheTourReservations",
  components: {
    TheTourStatusesSendNotificationOffCanvas,
    TheTourResourceReservationsMobile,
    TheTourResourceReservationsStatus,
    VLoader,
    VIcon,
    VButton,
    VCheckbox,
    TheTourDayItemDetailsOffCanvas,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    tourId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const userStore = useUserStore();
    return {
      findConceptByKey,
      ...useOffCanvasUtils(),
      ...useConcepts(),
      userStore,
    };
  },
  data() {
    return {
      status: "loading",
      updatingTourStatus: false,
      bookings: [] as Record<string, any>[],
      resources: [] as number[],
      hoveredId: 0 as number,
      supplierDataForEmail: [] as any[],
      selectAllToggle: {} as SelectAllToggleState,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["agency"]),
    ...mapState(useUserStore, ["currentTour"]),
    itemTypes(): any {
      if (!!this.concepts && this.concepts["supplier.type"]) {
        return mapConcepts(this.concepts["supplier.type"]);
      }

      return [];
    },
    disableSelectAll() {
      if (this.bookings.length === 0) {
        return true;
      }

      return this.bookings.every((booking) => {
        const { suppliers } = booking;

        return suppliers.every((supplier: Supplier) => !supplier.email || supplier.typeEmail === "never");
      });
    },
  },
  async created() {
    await this.loadTourBooking(true);
  },
  methods: {
    toggleSelectButton(supplierType: any) {
      this.selectAllToggle[supplierType] = !this.selectAllToggle[supplierType];
      if (this.selectAllToggle[supplierType]) {
        this.checkAllResources(supplierType);
      } else {
        this.deselectAllResources(supplierType);
      }
    },
    isPaymentLinkAvailable(supplier: { tourDayItemResources: any[] }) {
      return (
        supplier?.tourDayItemResources?.map((i) => i.paymentLink !== null && i.paymentLink)?.filter(Boolean)[0] !==
        undefined
      );
    },
    getPaymentLink(supplier: { tourDayItemResources: any[] }) {
      return supplier?.tourDayItemResources?.map((i) => i.paymentLink !== null && i.paymentLink)?.filter(Boolean)[0];
    },
    openWebsite(url: string) {
      window.open(url, "_blank");
    },
    sortTourDayItemResources(items: TourDayItemResource[]): TourDayItemResource[] {
      return items.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        const dateEndA = new Date(a.endDate);
        const dateEndB = new Date(b.endDate);

        if (dateA.getTime() === dateB.getTime()) {
          if (dateEndA.getTime() === dateEndB.getTime()) {
            return a.id - b.id;
          }

          return dateEndA.getTime() - dateEndB.getTime();
        }

        return dateA.getTime() - dateB.getTime();
      });
    },
    getAssociatedIcon(type: string) {
      let defaultIcon = "compass";

      if (!!type) {
        defaultIcon = this.itemTypes.find((item: any) => item.key === type)?.icon;
      }

      return defaultIcon;
    },
    calculatePax(resourcesGroup: TourDayItemResource[], type: string) {
      if (type === "transport") {
        return resourcesGroup[0].pax;
      } else {
        return resourcesGroup.reduce((total, resource) => total + resource.pax, 0);
      }
    },
    async loadTourBooking(updateStatus: boolean) {
      if (updateStatus) {
        this.status = "loading";
      }
      if (!!this.agency) {
        const filters = {
          order: "type",
        };

        const bookings = await api.tourBooking.all(this.agency.slug, this.caseId, this.tourId, filters);

        const orderedByType = bookings.group((item: TourDayItemResource) => item.type);

        this.bookings = Object.keys(orderedByType).map((name: string) => {
          const tourDayItems: TourDayItemResource[] = orderedByType[name];

          // 2. Get all suppliers within each type
          const suppliers = tourDayItems.reduce((values: Supplier[], item: TourDayItemResource) => {
            // 2.1. Search if the given supplier exists...
            const supplierInValues = !!values.find((i: Supplier) => i.id === item.resource.supplier?.id);

            // 2.2. If it doesn't exist, add it.
            if (!supplierInValues && !!item.resource.supplier) {
              values.push(item.resource.supplier);
            }

            return values;
          }, []);

          // 3. For each supplier add your items
          const suppliersMapped = suppliers.map((supplier: Supplier) => {
            const tourDayItemResources: TourDayItemResource[] = tourDayItems.filter(
              (item: TourDayItemResource) => item.resource.supplier?.id === supplier.id
            );
            tourDayItemResources.forEach((tourDayItemResource: any) => {
              tourDayItemResource.resource = {
                amenities: tourDayItemResource.resource.amenities,
                cancellationPolicy: tourDayItemResource.resource.cancellationPolicy,
                createdAt: tourDayItemResource.resource.createdAt,
                deletedAt: tourDayItemResource.resource.deletedAt,
                description: tourDayItemResource.resource.description,
                id: tourDayItemResource.resource.id,
                name: tourDayItemResource.resource.name,
                pax: tourDayItemResource.resource.pax,
                supplierId: tourDayItemResource.resource.supplierId,
                type: tourDayItemResource.resource.type,
                updatedAt: tourDayItemResource.resource.updatedAt,
                translations: tourDayItemResource.resource.translations,
              };
              tourDayItemResource.invoiceChecked = false;
              tourDayItemResource.account = supplier.account;
            });

            this.sortTourDayItemResources(tourDayItemResources);

            const tourDayItemsResourcesGroup: {
              [key: string]: TourDayItemResource[];
            } = tourDayItemResources.reduce((acc, itemResource) => {
              const key = `${itemResource.resource.id}-${itemResource.date}-${itemResource.endDate}-
              ${itemResource.paymentStatus}-${itemResource.reservationStatus}-${itemResource.invoiceStatus}`;

              if (!acc[key]) {
                acc[key] = [];
              }

              acc[key].push(itemResource);

              return acc;
            }, {});

            const tourDayItemsResourcesGrouped = Object.keys(tourDayItemsResourcesGroup).map((key: string) => {
              const tourDayItemsResourcesGroupMapped = tourDayItemsResourcesGroup[key].map((item) => {
                return {
                  account: item.account,
                  amountPaid: item.amountPaid,
                  date: item.date,
                  endDate: item.endDate,
                  id: item.id,
                  invoiceChecked: false,
                  invoiceStatus: item.invoiceStatus,
                  isOptional: item.isOptional,
                  isUpdatedBySupplier: item.isUpdatedBySupplier,
                  modelId: item.modelId,
                  modelType: item.modelType,
                  name: item.resource.name,
                  pax: item.pax,
                  paymentStatus: item.paymentStatus,
                  reservationStatus: item.reservationStatus,
                  stage: item.tourDayItem.stage,
                  supplierResourceId: item.resource.id,
                  tourDayId: item.tourDayItem.tourDayId,
                  tourDayItemId: item.tourDayItemId,
                  type: item.type,
                  paymentLink: item.paymentLink,
                };
              });

              const ids = tourDayItemsResourcesGroup[key].map((itemResource) => itemResource.id);
              return {
                account: tourDayItemsResourcesGroup[key][0].account,
                date: tourDayItemsResourcesGroup[key][0].date,
                endDate: tourDayItemsResourcesGroup[key][0].endDate,
                id: ids.join("-"),
                invoiceChecked: false,
                isUpdatedBySupplier: tourDayItemsResourcesGroup[key][0].isUpdatedBySupplier,
                name: tourDayItemsResourcesGroup[key][0].resource.name,
                paymentMethod: tourDayItemsResourcesGroup[key][0].paymentMethod,
                paymentLink: tourDayItemsResourcesGroup[key][0].paymentLink,
                paymentDate: tourDayItemsResourcesGroup[key][0].paymentDate,
                bookingType: tourDayItemsResourcesGroup[key][0].bookingType,
                bookingTypeMeta: tourDayItemsResourcesGroup[key][0].bookingTypeMeta,
                quantity: tourDayItemsResourcesGroup[key].length,
                resources: tourDayItemsResourcesGroupMapped,
                stage: tourDayItemsResourcesGroup[key][0].tourDayItem.stage,
                tourDayId: tourDayItemsResourcesGroup[key][0].tourDayItem.tourDayId,
                tourDayItemIds: tourDayItemsResourcesGroup[key].map((itemResource) => itemResource.tourDayItem.id),
                tourDayItemResourceIds: ids,
                type: tourDayItemsResourcesGroup[key][0].type,
                typeResource: tourDayItemsResourcesGroup[key][0].resource.type,
                invoiceStatus: tourDayItemsResourcesGroup[key][0].invoiceStatus,
                reservationStatus: tourDayItemsResourcesGroup[key][0].reservationStatus,
                paymentStatus: tourDayItemsResourcesGroup[key][0].paymentStatus,
                pax: this.calculatePax(tourDayItemsResourcesGroup[key], tourDayItemsResourcesGroup[key][0].type),
              };
            });
            supplier.tourDayItemResources = tourDayItemResources;
            supplier.invoiceChecked = false;
            return {
              account: supplier.account,
              email: supplier.email,
              id: supplier.id,
              invoiceChecked: false,
              itemResourcesGrouped: tourDayItemsResourcesGrouped,
              mapUrl: supplier.mapUrl,
              name: supplier.name,
              paymentMethod: supplier.paymentMethod,
              paymentDate: supplier.paymentDate,
              bookingType: supplier.bookingType,
              bookingTypeMeta: supplier.bookingTypeMeta,
              phone: supplier.phone,
              preferredLanguage: supplier.preferredLanguage,
              priceRange: supplier.priceRange,
              siteUrl: supplier.siteUrl,
              type: supplier.type,
              typeEmail: supplier.typeEmail,
            };
          });

          return {
            name,
            suppliers: suppliersMapped,
          };
        });
        this.bookings = this.bookings.filter((b) => b.name !== "null");
        this.updatingTourStatus = false;
      }
      if (updateStatus) {
        this.status = "loaded";
      }
    },
    setHoveredElement(id: number) {
      this.hoveredId = id;
    },
    checkItemsInvoiceChecked(items: any) {
      const isAllCheckTrue = items.every((group: any) => group.invoiceChecked === true);

      if (isAllCheckTrue) {
        return true;
      } else {
        const isAllCheckFalse = items.every((group: any) => group.invoiceChecked === false);

        if (isAllCheckFalse) {
          return false;
        } else {
          return "mixed";
        }
      }
    },
    checkSupplier(supplier: any) {
      supplier.invoiceChecked = this.checkItemsInvoiceChecked(supplier.itemResourcesGrouped);
    },
    checkSupplierResource(itemResourcesGrouped: any, supplier: any) {
      itemResourcesGrouped.invoiceChecked = this.checkItemsInvoiceChecked(itemResourcesGrouped.resources);

      this.checkSupplier(supplier);
      this.mapResourcesToNotification();
    },
    checkSupplierResourceGroup(supplier: any, itemResource: any) {
      itemResource.resources.forEach((resource: any) => {
        resource.invoiceChecked = itemResource.invoiceChecked;
      });

      this.checkSupplier(supplier);
      this.mapResourcesToNotification();
    },
    checkAllResources(type: any) {
      this.bookings.forEach((bookingType) => {
        if (bookingType.name === type) {
          bookingType.suppliers.forEach(
            (supplier: { typeEmail: string; email: any; invoiceChecked: boolean; itemResourcesGrouped: any[] }) => {
              if (supplier.typeEmail !== "never" && !!supplier.email) {
                supplier.invoiceChecked = true;

                supplier.itemResourcesGrouped.forEach((item: { invoiceChecked: boolean; resources: any[] }) => {
                  item.invoiceChecked = true;
                  item.resources.forEach((resource: { invoiceChecked: boolean }) => {
                    resource.invoiceChecked = true;
                  });
                });
              }
            }
          );
        }
      });

      this.mapResourcesToNotification();
    },
    deselectAllResources(type: any) {
      this.bookings.forEach((bookingType) => {
        if (bookingType.name === type) {
          bookingType.suppliers.forEach(
            (supplier: { typeEmail: string; email: any; invoiceChecked: boolean; itemResourcesGrouped: any[] }) => {
              if (supplier.typeEmail !== "never" && !!supplier.email) {
                supplier.invoiceChecked = false;

                supplier.itemResourcesGrouped.forEach((item: { invoiceChecked: boolean; resources: any[] }) => {
                  item.invoiceChecked = false;
                  item.resources.forEach((resource: { invoiceChecked: boolean }) => {
                    resource.invoiceChecked = false;
                  });
                });
              }
            }
          );
        }
      });

      this.mapResourcesToNotification();
    },
    setCurrentItem(item: any) {
      const payload = {
        agency: this.agency.slug,
        clientTourId: Number(this.caseId),
        tourId: Number(this.tourId),
        tourDayId: item?.tourDayId,
        tourDayItemId: item?.tourDayItemIds[0],
      };
      this.openItemDetailsOffCanvas(payload);
    },
    async openItemDetailsOffCanvas(data: any) {
      this.statusTourDayItem = "loading";

      if (this.offCanvasToShow !== "the-tour-day-item-details-off-canvas") {
        this.openOffCanvas("the-tour-day-item-details-off-canvas");
      }

      await this.userStore.setCurrentTourDayItem(data);

      this.statusTourDayItem = "loaded";
    },
    async deleteItem(tourDayItemResource: any) {
      await this.$modal.delete({
        title: this.$t("tourBuilder.deleteTourDayItemTitle"),
        text: this.$t("tourBuilder.deleteTourDayItemText", {
          dayItem: tourDayItemResource?.resource?.name,
        }),
        deleteButtonText: this.$t("general.button.delete"),
        rightButtonClasses: "btn-tertiary",
        deleteButtonCallback: () => this.doDeleteItem(tourDayItemResource),
      });
    },
    async doDeleteItem(tourDayItemResource: any) {
      this.status = "loading";
      const loadingToast = this.$toast.loading(this.$t("general.button.deleting"), "toast-remove-tour-day-item");

      try {
        if (tourDayItemResource?.tourDayItemId !== undefined) {
          await api.tourDayItemResource.delete(
            this.agency.slug,
            Number(this.caseId),
            Number(this.tourId),
            tourDayItemResource?.tourDayId,
            tourDayItemResource?.tourDayItemId,
            tourDayItemResource?.id
          );
        } else {
          await api.tourDayItemResource.delete(
            this.agency.slug,
            Number(this.caseId),
            Number(this.tourId),
            tourDayItemResource?.tourDayId,
            tourDayItemResource?.tourDayItemIds[0],
            tourDayItemResource?.tourDayItemResourceIds[0]
          );
        }

        this.loadTourBooking(true);

        if (!!this.currentTourDayItem && !!this.currentTourDayItem.id && tourDayItemId === this.currentTourDayItem.id) {
          this.$emit("closeItemDetailsOffCanvas");
        }

        this.status = "loaded";

        loadingToast.update("toast-remove-tour-day-item", {
          content: this.$t("tourBuilder.dayItemDeleted"),
          options: {
            type: TYPE.SUCCESS,
            icon: "icon icon-check-circle-fill",
            timeout: 5000,
          },
        });
      } catch (e: any) {
        loadingToast.update("toast-remove-tour-day-item", {
          content: this.$t("tourBuilder.dayItemDeleteError"),
          options: {
            type: TYPE.ERROR,
            icon: "icon icon-exclamation-triangle-fill",
            timeout: 5000,
          },
        });

        console.error(e);

        // this.$toast.error(e.response.data.message);
      }
    },
    clearCurrentTourDayItem() {
      this.userStore.setCurrentTourDayItem(null);
    },
    changeAllCheckSupplierResources(type: string, supplierId: number, checkValue: boolean, checboxType: string) {
      const supplier = this.bookings
        .find((typeAux: any) => typeAux.name === type)
        ?.suppliers.find((supplierAux: Record<string, any>) => supplierAux.id === supplierId);

      if (!!supplier) {
        supplier?.itemResourcesGrouped?.forEach((item: any) => {
          item.invoiceChecked = checboxType === "all" ? checkValue : item.invoiceChecked;
          item.resources.forEach((resource: any) => {
            resource.invoiceChecked = checboxType === "all" ? checkValue : resource.invoiceChecked;
          });
        });

        this.mapResourcesToNotificationIndividual(type, supplierId);
      }
    },
    mapResourcesToNotificationIndividual(type: string, id: number) {
      this.resources = [];
      this.supplierDataForEmail = [];

      this.bookings.forEach((supplierType: any) => {
        if (supplierType.name === type) {
          supplierType?.suppliers?.forEach((newSupplier: any) => {
            if (newSupplier.id === id) {
              this.supplierDataForEmail.push(newSupplier);
              newSupplier.itemResourcesGrouped.forEach((item: any) => {
                item.resources.forEach((resource: any) => {
                  if (resource.invoiceChecked) {
                    this.resources.push(resource.id);
                  }
                });
              });
            }
          });
        }
      });
    },
    mapResourcesToNotification() {
      this.resources = [];
      const supplierData: any[] = [];

      this.bookings.forEach((supplierType: any) => {
        supplierType.suppliers.forEach((newSupplier: any) => {
          supplierData.push(newSupplier);
          newSupplier.itemResourcesGrouped.forEach((item: any) => {
            item.resources.forEach((resource: any) => {
              if (resource.invoiceChecked) {
                this.resources.push(resource.id);
              }
            });
          });
        });
      });
      this.supplierDataForEmail = supplierData;
    },
    openSendNotificationsModal() {
      this.mapResourcesToNotification();

      if (this.resources.length === 0) {
        this.$toast.error(this.$t("tourBooking.noResourcesSelectedMessage"));
      } else {
        this.openOffCanvas("the-tour-statuses-send-notification-off-canvas");
      }
    },
    openSendNotificationsModalIndividual() {
      if (this.resources.length === 0) {
        this.$toast.error(this.$t("tourBooking.noResourcesSelectedMessage"));
      } else {
        this.openOffCanvas("the-tour-statuses-send-notification-off-canvas");
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.accordion-toggle {
  cursor: pointer;
}

.suppliers-header,
.resources-header {
  .th {
    padding: 0.5rem 0.375rem;
    font-weight: 300;
    font-size: 1.125rem;
  }
}

.suppliers-body {
  .supplier-container {
    border: 1px solid var(--uq-gray-400);
    border-radius: 0.25rem;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .supplier-data {
    position: relative;
    .td {
      padding-inline: 0.375rem;
      font-weight: 300;
    }
  }
}

.resources-body {
  .resource-data {
    position: relative;
    padding-top: 0.75rem;

    &:not(:last-child) {
      padding-bottom: 0.75rem;
    }

    &:not(:last-child):after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: var(--uq-gray-300);
    }

    &.bg-gray-200 {
      :deep(.form-outline .label span) {
        background-color: #f7f7fc !important;
      }

      :deep(.input-group .input-group-text) {
        background-color: #f0f0f9;
      }
    }

    :deep(.input-group .input-group-text) {
      background-color: #f7f7fc;
    }
  }
}

.email-container {
  width: 97%;
  align-items: center;
}

.tab-container {
  width: 95.5%;
  align-items: center;

  @media (max-width: 825px) {
    margin-left: 5px;
    flex-direction: column;
    align-items: baseline;
  }
}

.supplier-info {
  display: flex;
  justify-content: end;
  margin: 0 auto;
  width: 100%;
  margin-top: -4px;
}

.info-tab {
  width: 25%;
  @media (max-width: 1200px) {
    width: 100%;
  }
}
.icon-container {
  display: none;
}

.hover-container:hover .icon-container {
  display: flex;
}

.send-notification {
  position: absolute;
  top: 0.4rem;
  right: 3rem;
}

.hidden-conatiner {
  width: 2.5rem;
}

.group-status {
  margin-top: 1.5rem;
}

.dashed {
  border-bottom: 1px dashed var(--uq-gray-400);
  padding-bottom: 1rem;
}
</style>
