<template>
  <div v-if="canShow">
    <div v-if="updatingTour" class="loader-tour-container">
      <v-loader />
    </div>

    <template v-if="status === 'loaded'">
      <div class="d-flex justify-content-between">
        <div class="mb-16">{{ templateName }}</div>

        <div class="d-flex justify-content-end mb-28 gap-8">
          <v-button :disabled="updatingTour" class="btn-icon w-fit align-self-end"
          size="sm" variant="primary" @click="connectLuggage">
          {{ $t('tourClient.connectLuggage') }}
          <v-icon icon="luggage-cart" size="sm" space="ms-12" />
        </v-button>
          <v-button :disabled="updatingTour" class="btn-icon w-fit align-self-end" size="sm" variant="primary"
            @click="exportAsTemplate">
            {{ $t('tourBuilder.exportAsProduct') }}
            <v-icon icon="export-arrow" size="sm" space="ms-12" />
          </v-button>
        </div>
      </div>

      <div class="w-100 d-flex justify-content-center">
        <v-button :disabled="updatingTour" class="btn-icon p-4 rounded-circle" size="sm" variant="gradient"
          @click="addTourDayAtFirst()">
          <v-icon icon="plus" />
        </v-button>
      </div>

      <div class="tour-container">
        <the-tour-day-draggable v-model="tourDays"  :agency="agency.slug"
          :client-tour="tourMapped.clientTour" :current-tour-day="currentTourDayId"
          :end-date="tourMapped.clientTour.endDate" :init-date="tourMapped.clientTour.initDate" :tour-id="tourMapped.id"
          :tour-mapped="tourMappedOriginal" :updating-tour="updatingTour" class="mb-32" type="tour-day"
          @day-moved="updateTourDay" @emit-add-day="addTourDay" @reload-tour="loadTour"
          @reload-client-tour="$emit('reloadClientTour')" @open-item-details-off-canvas="openItemDetailsOffCanvas"
          @close-item-details-off-canvas="closeOffCanvas(); clearCurrentTourDayItem()"
          @open-day-information-off-canvas="openDayInformationOffCanvas"
          @move-item-to-another-day="moveItemToAnotherDay" />
      </div>

      <div class="w-100 d-flex justify-content-center">
        <v-button :disabled="updatingTour" class="btn-icon p-4 rounded-circle" size="sm" variant="gradient"
          @click="addTourDayAtEnd()">
          <v-icon icon="plus" />
        </v-button>
      </div>
    </template>

    <v-loader v-else />

    <the-tour-day-item-details-off-canvas v-if="offCanvasToShow === 'the-tour-day-item-details-off-canvas'"
       :agency="agency.slug" :client-tour-id="Number(caseId)" :status="statusTourDayItem"
      :tour-id="Number(tourId)" @closed="closeOffCanvas(); clearCurrentTourDayItem()" @emit-reload-tour="loadTour" @open-item-details-off-canvas="openItemDetailsOffCanvas"/>

    <the-tour-day-information-off-canvas v-if="offCanvasToShow === 'the-tour-day-information-off-canvas'"
       :agency="agency.slug" :client-tour-id="Number(caseId)" :tour-day-id="currentTourDayId"
      :tour-id="Number(tourId)" @closed="closeOffCanvas(); currentTourDayId = null" @emit-reload-tour="loadTour" />
    
    <the-confirmation-modal
      v-if="showConfirmationModal"
      :title="$t('tourClient.connectLuggage')"
      @confirm="doConnectLuggage"
      @closed="closeConfirmModal"
      :btn-loading="updatingTour"
    />  

  </div>

  <the-screen-size-verify @can-show="canShow = $event" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import { mapState } from 'pinia';
import { TYPE } from 'vue-toastification';
import api from '@/api';
import TheTourDayDraggable from '@/components/tour/TheTourDayDraggable.vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { useAuthStore } from '@/stores/auth';
import TheTourDayItemDetailsOffCanvas from '@/components/tour/TheTourDayItemDetailsOffCanvas.vue';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
import { useUserStore } from '@/stores/user';
import TourDayItemResource from '@/api/objects/TourDayItemResource';
import TheTourDayInformationOffCanvas from '@/components/tour/TheTourDayInformationOffCanvas.vue';
import TheScreenSizeVerify from '@/components/vendor/TheScreenSizeVerify.vue';
import TheConfirmationModal from "@/components/modal/TheConfirmationModal.vue";

export default defineComponent({
  name: 'TheTourBuilder',
  components: {
    TheScreenSizeVerify,
    TheTourDayInformationOffCanvas,
    TheTourDayItemDetailsOffCanvas,
    VLoader,
    TheTourDayDraggable,
    VIcon,
    VButton,
    TheConfirmationModal,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    tourId: {
      type: String,
      required: true,
    },
    
  },
  emits: ['reloadClientTour'],
  setup() {
    const userStore = useUserStore();

    return {
      ...useOffCanvasUtils(),
      userStore,
    };
  },
  data() {
    return {
      canShow: false,
      statusTourDayItem: 'loading' as string,
      statusTourDay: 'loading' as string,
      tourMapped: {} as Record<string, any>,
      tourMappedOriginal: {} as Record<string, any>,
      tourDays: [] as Record<string, any>[],
      status: 'loading' as string,
      updatingTour: false as boolean,
      templateName: '' as string,
      currentTourDayId: null as null | number,
      showConfirmationModal: false as boolean,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
  },
  watch: {
    async tourId(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.loadTour();
      }
    },
  },
  async created() {
    await this.loadTour();
  },
  methods: {
    async loadTour() {
      this.updatingTour = true;

      if (!!this.agency) {
        const params = {
          expand: ['tourDays', 'tourDayItemResources', 'client', 'clientTour', 'supplier'],
        };

        const tour: Record<string, any> = await api.tour.retrieve(
          this.agency.slug,
          Number(this.caseId),
          Number(this.tourId),
          params,
        );

        this.templateName = tour.name;

        this.mapTour(tour);
      }

      this.updatingTour = false;
    },
    mapTour(tour: Record<string, any>) {
      const tourDaysItemsHotelCache: Record<string, any>[] = [];

      const tourDays: Record<string, any>[] = tour.tourDays.map((tourDay: Record<string, any>) => {
        let tourDayItems = [];

        if (!!tourDay.items && tourDay.items.length > 0) {
          tourDayItems = tourDay.items.map((tourDayItem: Record<string, any>) => {
            let supplierName = '';

            if (tourDayItem.resources.length > 0) {
              supplierName = (tourDayItem.resources[0].resource !== undefined && tourDayItem.resources[0].resource.supplier !== undefined && !!tourDayItem.resources[0].resource.supplier && !!tourDayItem.resources[0].resource.supplier?.name) ? tourDayItem.resources[0].resource.supplier?.name : '-';
            }

            const tourDayItemMapped: Record<string, any> = {
              id: tourDayItem.id,
              ...tourDayItem,
              disabled: false,
              supplier: supplierName,
            };

            if (tourDayItem.type.toLowerCase() === 'hotel' && !tourDayItem.disabled) {
              const endDates: DateTime[] = tourDayItem.resources.map(
                (a: TourDayItemResource) => DateTime.fromISO(a.endDate));

              let firstDay = DateTime.fromISO(tourDay.date).plus({ days: 1 });
              const lastDay = DateTime.max(...endDates);

              while (firstDay < lastDay) {
                tourDaysItemsHotelCache.push({
                  ...tourDayItemMapped,
                  dateCheckout: firstDay.toFormat('yyyy-MM-dd'),
                  disabled: true,
                });

                firstDay = firstDay.plus({ days: 1 });
              }
            }

            return tourDayItemMapped;
          })
            .sort((itemA: Record<string, any>, itemB: Record<string, any>) => itemA.position - itemB.position);
        }

        let tourDayIsBlocked = false;
        if (!!tourDay.items && tourDay.items.length > 0) {
          tourDayIsBlocked = tourDay.items.some((tourDayItem: Record<string, any>) => !!tourDayItem.isBlocked);
        }

        if (!tourDay.stageLength) {
          tourDay.stageLength = 0;
        }

        return {
          id: tourDay.id,
          ...tourDay,
          isBlocked: tourDayIsBlocked,
          items: tourDayItems,
        };
      });

      tourDaysItemsHotelCache.forEach((tourDayItemHotel: Record<string, any>) => {
        const tourDayCheckOut = tourDays.find(
          (tourDayAux: Record<string, any>) => DateTime.fromISO(tourDayAux.date).toFormat('yyyy-MM-dd') === tourDayItemHotel.dateCheckout);

        if (!!tourDayCheckOut) {
          tourDayItemHotel.disabled = true;

          tourDayCheckOut.items.unshift(tourDayItemHotel);
        }
      });

      this.tourMapped = {
        id: tour.id,
        ...tour,
        tourDays,
        client: tour.client,
        clientTour: tour.clientTour,
      };

      this.tourDays = this.tourMapped.tourDays;

      this.tourMappedOriginal = JSON.parse(JSON.stringify(this.tourMapped));

      this.status = 'loaded';
    },
    async moveItemToAnotherDay(item: Record<string, any>) {
      const newTourDay = this.tourDays.find((tourDay: Record<string, any>) => tourDay.items.find(
        (tourDayItem: Record<string, any>) => tourDayItem.id === item.element.id));

      if (!!this.agency && !!newTourDay) {
        try {
          await api.tourDayItem.update(
            this.agency.slug,
            this.tourMapped.clientTourId,
            this.tourMapped.id,
            item.element.tourDayId,
            item.element.id,
            newTourDay.id,
          );

          const data = {
            position: item.newPosition,
          };

          await api.tourDayItem.movePosition(
            this.agency.slug,
            this.tourMapped.clientTourId,
            this.tourMapped.id,
            newTourDay.id,
            item.element.id,
            data,
          );

          this.$toast.success(this.$t('general.shared.savedChanges'));

          await this.loadTour();
        } catch (e: any) {
          this.$toast.error(e.response.data.message);

          await this.loadTour();
        }
      }
    },
    async updateTourDay(tourDay: Record<string, any>, newDayDate: string) {
      if (!!this.agency) {
        await api.tourDay.update(
          this.agency.slug,
          this.tourMapped.clientTourId,
          this.tourMapped.id,
          tourDay.id,
          {
            pax: tourDay.pax,
            date: newDayDate,
            type: tourDay.type,
            origin: tourDay.originId,
            destination: tourDay.destinationId,
            stage_length: tourDay.stageLength,
          },
        );
      }

      await this.loadTour();
    },
    async addTourDay(date: string) {
      await this.$modal.confirm({
        title: this.$t('tourBuilder.addDayTitle'),
        text: this.$t('tourBuilder.addDayText'),
        leftButtonText: this.$t('general.button.cancel'),
        switchButtons: true,
        confirmButtonCallback: async () => {
          if (!!this.agency) {
            const loadingToast = this.$toast.loading(this.$t('general.button.adding'), 'toast-add-tour-day');

            try {
              await api.tourDay.create(
                this.agency.slug,
                this.tourMapped.clientTourId,
                this.tourMapped.id,
                {
                  pax: this.tourMapped.clientTour.paxNumber,
                  date,
                  type: 'rest',
                },
              );

              await this.loadTour();

              loadingToast.update('toast-add-tour-day', {
                content: this.$t('tourBuilder.dayCreated'),
                options: {
                  type: TYPE.SUCCESS,
                  icon: 'icon icon-check-circle-fill',
                  timeout: 5000,
                },
              });
            } catch (e: any) {
              loadingToast.update('toast-add-tour-day', {
                content: this.$t('tourBuilder.dayCreateError'),
                options: {
                  type: TYPE.ERROR,
                  icon: 'icon icon-exclamation-triangle-fill',
                  timeout: 5000,
                },
              });

              console.error(e.response.data);

              this.$toast.error(e.response.data.message);
            }
          }
        },
        confirmButtonLoadingText: this.$t('general.button.adding'),
        confirmButtonText: this.$t('general.button.add'),
      });
    },
    async addTourDayAtFirst() {
      await this.$modal.confirm({
        title: this.$t('tourBuilder.addDayTitle'),
        text: this.$t('tourBuilder.addDayText'),
        leftButtonText: this.$t('general.button.cancel'),
        switchButtons: true,
        confirmButtonCallback: async () => {
          if (!!this.agency) {
            const loadingToast = this.$toast.loading(this.$t('general.button.adding'), 'toast-add-tour-day-at-first');

            const firstTourDayDate = DateTime.fromISO(this.tourMapped.clientTour.initDate);
            const newFirstDayDate = firstTourDayDate.minus({ days: 1 }).toFormat('yyyy-MM-dd');

            try {
              await api.case.update(
                this.agency.slug,
                this.tourMapped.clientTour.id,
                {
                  init_date: newFirstDayDate,
                  end_date: this.tourMapped.clientTour.endDate,
                  pax_number: this.tourMapped.clientTour.paxNumber,
                  type: this.tourMapped.clientTour.type,
                },
              );

              await api.tourDay.create(
                this.agency.slug,
                this.tourMapped.clientTourId,
                this.tourMapped.id,
                {
                  pax: this.tourMapped.clientTour.paxNumber,
                  date: newFirstDayDate,
                  type: 'stage',
                },
              );

              await this.loadTour();

              this.$emit('reloadClientTour');

              loadingToast.update('toast-add-tour-day-at-first', {
                content: this.$t('tourBuilder.dayCreated'),
                options: {
                  type: TYPE.SUCCESS,
                  icon: 'icon icon-check-circle-fill',
                  timeout: 5000,
                },
              });
            } catch (e: any) {
              loadingToast.update('toast-add-tour-day-at-first', {
                content: this.$t('tourBuilder.dayCreateError'),
                options: {
                  type: TYPE.ERROR,
                  icon: 'icon icon-exclamation-triangle-fill',
                  timeout: 5000,
                },
              });

              console.error(e.response.data);

              this.$toast.error(e.response.data.message);
            }
          }
        },
        confirmButtonLoadingText: this.$t('general.button.adding'),
        confirmButtonText: this.$t('general.button.add'),
      });
    },
    async addTourDayAtEnd() {
      await this.$modal.confirm({
        title: this.$t('tourBuilder.addDayTitle'),
        text: this.$t('tourBuilder.addDayText'),
        leftButtonText: this.$t('general.button.cancel'),
        switchButtons: true,
        confirmButtonCallback: async () => {
          if (!!this.agency) {
            const loadingToast = this.$toast.loading(this.$t('general.button.adding'), 'toast-add-tour-day-at-end');

            const lastTourDayDate = DateTime.fromISO(this.tourMapped.clientTour.endDate);
            const newLastDayDate = lastTourDayDate.plus({ days: 1 }).toFormat('yyyy-MM-dd');

            try {
              await api.case.update(
                this.agency.slug,
                this.tourMapped.clientTour.id,
                {
                  init_date: this.tourMapped.clientTour.initDate,
                  end_date: newLastDayDate,
                  pax_number: this.tourMapped.clientTour.paxNumber,
                  type: this.tourMapped.clientTour.type,
                },
              );

              await api.tourDay.create(
                this.agency.slug,
                this.tourMapped.clientTourId,
                this.tourMapped.id,
                {
                  pax: this.tourMapped.clientTour.paxNumber,
                  date: newLastDayDate,
                  type: 'stage',
                },
              );

              await this.loadTour();

              this.$emit('reloadClientTour');

              loadingToast.update('toast-add-tour-day-at-end', {
                content: this.$t('tourBuilder.dayCreated'),
                options: {
                  type: TYPE.SUCCESS,
                  icon: 'icon icon-check-circle-fill',
                  timeout: 5000,
                },
              });
            } catch (e: any) {
              loadingToast.update('toast-add-tour-day-at-end', {
                content: this.$t('tourBuilder.dayCreateError'),
                options: {
                  type: TYPE.ERROR,
                  icon: 'icon icon-exclamation-triangle-fill',
                  timeout: 5000,
                },
              });

              console.error(e.response.data);

              this.$toast.error(e.response.data.message);
            }
          }
        },
        confirmButtonLoadingText: this.$t('general.button.adding'),
        confirmButtonText: this.$t('general.button.add'),
      });
    },
    async openItemDetailsOffCanvas(data: Record<string, any>) {
      this.statusTourDayItem = 'loading';

      if (this.offCanvasToShow !== 'the-tour-day-item-details-off-canvas') {
        this.openOffCanvas('the-tour-day-item-details-off-canvas');
      }

      await this.userStore.setCurrentTourDayItem(data);

      this.statusTourDayItem = 'loaded';
    },
    async openDayInformationOffCanvas(tourDayId: number) {
      this.currentTourDayId = tourDayId;

      if (this.offCanvasToShow !== 'the-tour-day-information-off-canvas') {
        this.openOffCanvas('the-tour-day-information-off-canvas');
      }
    },
    clearCurrentTourDayItem() {
      this.userStore.setCurrentTourDayItem(null);
    },
    async exportAsTemplate() {
      await this.$modal.confirm({
        title: this.$t('tourBuilder.exportTourAsTemplateTitle'),
        text: this.$t('tourBuilder.exportTourAsTemplateText'),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonCallback: this.doExportAsTemplate,
      });
    },
    async doExportAsTemplate() {
      if (!!this.agency) {
        try {
          await api.template.create(
            this.agency.slug,
            this.tourMapped.clientTour.id,
            this.tourMapped.id,
          );

          this.$toast.success(this.$t('general.shared.savedChanges'));
        } catch (e: any) {
          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        }
      }
    },
    async connectLuggage() {
      this.showConfirmationModal = true;
    },
    async doConnectLuggage() {
      try {
        this.updatingTour = true;
        await api.case.connectLuggage(this.agency.slug, this.caseId, this.tourId); 
        this.$toast.success(this.$t("general.shared.savedChanges"));
        await this.loadTour();
        this.showConfirmationModal = false;
      } catch (e) {
        console.error(e);
      }
    },
    closeConfirmModal() {
      this.showConfirmationModal = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.tour-steps-container {
  gap: 1rem;
}

.list-enter-from {
  opacity: 0;
  transform: scaleY(0.25);
}

.list-enter-active,
.list-leave-active {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-leave-to {
  opacity: 0;
  transform: scaleY(0);
}

.list-move {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-leave-active {
  position: absolute;
}

.list-group-item {
  display: block;
  padding: 0;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
  width: 100%;
}
</style>
