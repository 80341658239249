<template>
  <div id="page-days">
    <div class="page-inner container">
      <div class="tour-days-container">
        <h3 class="h5 mb-12">{{ $t('travelBook.tour') }}</h3>

        <div class="days">
          <div class="mb-32">
            <the-travel-book-before-trip
              v-if="!!tour && shouldDisplayBeforeTripSection"
              :tour-template="tourTemplate"
            />
          </div>
          <div class="mb-32">
            <the-travel-book-include-info
              v-if="!!tour && shouldDisplayIncludeInfoSection"
              :tour-template="tourTemplate"
            />
          </div>
          <div class="mb-32">
            <the-travel-book-not-include-info
              v-if="!!tour && shouldDisplayNotIncludeInfoSection"
              :tour-template="tourTemplate"
            />
          </div>
          <div class="mb-32">
            <the-travel-book-optional-info
              v-if="!!tour && shouldDisplayOptionalInfoSection"
              :tour-template="tourTemplate"
            />
          </div>
          <template
            v-for="(day, index) in tourDays"
            :key="day.id"
          >
            <the-travel-book-day
              :day="day"
              :index="index"
              :is-summary="isSummary"
              :id="day.id"
            />
          </template>
          <div class="mb-32">
            <the-travel-book-after-trip
              v-if="!!tour && shouldDisplayAfterTripSection"
              :tour-template="tourTemplate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Tour from '@/api/objects/Tour';
import TourDay from '@/api/objects/TourDay';
import TheTravelBookDay from '@/components/tour/book/parts/TheTravelBookDay.vue';
import TheTravelBookBeforeTrip from '@/components/tour/book/parts/TheTravelBookBeforeTrip.vue';
import TheTravelBookAfterTrip from '@/components/tour/book/parts/TheTravelBookAfterTrip.vue';
import TheTravelBookIncludeInfo from '@/components/tour/book/parts/TheTravelBookIncludeInfo.vue';
import TheTravelBookNotIncludeInfo from '@/components/tour/book/parts/TheTravelBookNotIncludeInfo.vue';
import TheTravelBookOptionalInfo from '@/components/tour/book/parts/TheTravelBookOptionalInfo.vue';

export default defineComponent({
  name: 'TheTravelBookDays',
  components: {
    TheTravelBookDay,
    TheTravelBookBeforeTrip,
    TheTravelBookAfterTrip,
    TheTravelBookIncludeInfo,
    TheTravelBookNotIncludeInfo,
    TheTravelBookOptionalInfo,
  },
  props: {
    tour: {
      type: Object as PropType<Tour>,
      required: true,
    },
    tourDays: {
      type: Array as PropType<TourDay[]>,
      required: false,
    },
    locations: {
      type: Array as PropType<Location[]>,
      required: false,
    },
    isSummary: {
      type: Boolean,
      required: false,
      default: false,
    },
    tourTemplate: {
      type: [] as any,
      required: true,
    },
  },
  computed: {
    shouldDisplayBeforeTripSection() {
      const translations = this.tourTemplate?.beforeInformation?.translations || [];
      return translations.some((translation: { description: null; }) => translation.description !== null);
    },
    shouldDisplayIncludeInfoSection() {
      const translations = this.tourTemplate?.includedInformation?.translations || [];
      return translations.some((translation: { description: null; }) => translation.description !== null);
    },
    shouldDisplayNotIncludeInfoSection() {
      const translations = this.tourTemplate?.notIncludedInformation?.translations || [];
      return translations.some((translation: { description: null; }) => translation.description !== null);
    },
    shouldDisplayOptionalInfoSection() {
      const translations = this.tourTemplate?.optionalInformation?.translations || [];
      return translations.some((translation: { description: null; }) => translation.description !== null);
    },
    shouldDisplayAfterTripSection() {
      const translations = this.tourTemplate?.afterInformation?.translations || [];
      return translations.some((translation: { description: null; }) => translation.description !== null);
    },
  },
  data() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.hide-print {
  @media print {
    display: none;
  }
}
</style>
