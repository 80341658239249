<template>
  <v-modal id="show-email-warning-modal" class-name="modal-fullscreen-sm-down" size="lg" @closed="$emit('closed')">
    <modal-header>
      <modal-title>{{ title }}</modal-title>
    </modal-header>

    <modal-body>
      {{ $t("general.shared.confirmationText", { title: title }) }}
    </modal-body>
    <modal-footer>
      <div class="d-flex gap-8">
        <v-button
          class="btn-sm btn-danger align-self-end cursor-pointer"
          size="sm"
          variant="danger"
          @click="$emit('closed')"
          :disabled="btnLoading"
        >
          {{ $t("general.button.close") }}
        </v-button>
        <v-button
          class="btn-sm btn-success align-self-end cursor-pointer"
          size="sm"
          variant="success"
          @click="$emit('confirm')"
          :disabled="btnLoading"
        >
          {{ $t("general.button.save") }}
        </v-button>
      </div>
    </modal-footer>
  </v-modal>
</template>

<script>
import VModal from "@/components/vendor/basic/modal/VModal.vue";
import ModalHeader from "@/components/vendor/basic/modal/ModalHeader.vue";
import ModalTitle from "@/components/vendor/basic/modal/ModalTitle.vue";
import ModalBody from "@/components/vendor/basic/modal/ModalBody.vue";
import ModalFooter from "@/components/vendor/basic/modal/ModalFooter.vue";
import VButton from "@/components/vendor/basic/button/VButton.vue";

export default {
  name: "TheConfirmationModal",
  components: {
    VModal,
    VButton,
    ModalHeader,
    ModalTitle,
    ModalBody,
    ModalFooter,
  },
  emits: ["closed", "confirm"],
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    btnLoading: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
};
</script>
