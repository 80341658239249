export default {
  statuses: "Estados",
  bookingStatus: "Estado de la reserva",
  paymentStatus: "Estado del pago",
  splitPayment: "Pago dividido",
  invoiceStatus: "Estado de la factura",
  status: "Estado",
  dates: "Fechas",
  noBookings: "Aún no se han añadido recursos al Tour, por lo que no se pueden actualizar los estados del Tour.",
  selectResource: "Seleccionar recurso",
  sendNotification: "Enviar notificación",
  notification: "Notificación",
  notificationChannel: "Canal de notificación",
  notificationEmail: "Correo electrónico de notificación",
  noResourcesSelectedMessage: "Selecciona recursos para poder mandar las notificaciones",
  supplierNoEmail: "Sin correo electrónico",
  emailModal: {
    title: "Enviar correo electrónico a los proveedores",
    text: "¿Estás seguro de que quieres enviar el correo electrónico a los proveedores?",
  },
  teamName: "Nombre del equipo",
  roomType: "Tipo de habitación",
  quantity: "Cantidad",
  pax: "Pax",
  noBookingsFound: "No se encontraron reservas",
  caseName:  "Nombre del caso",
  clientName:  "Nombre del cliente",
  journeyRoute: "Ruta del viaje",
  product: "Producto",
  sold: 'Vendida',
  cancel: 'Cancelar'
};
