<template>
  <v-off-canvas
    id="tour-statuses-send-notification-off-canvas"
    :backdrop-static="true"
    offcanvas-class="offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
        <h5 class="offcanvas-title">{{ $t("tourBooking.sendNotification") }}</h5>
      </off-canvas-header>

      <off-canvas-body>
        <div class="d-flex flex-column justify-content-between h-100">
          <v-form id="case-type-notification-select" :label="$t('tourBooking.notification')" form-type="outline">
            <v-select
              v-model="notification"
              :clearable="false"
              :options="filteredOptions"
              :reduce="(returnValue) => returnValue.key"
            >
              <template v-slot:no-options>
                <div class="my-4">{{ $t("general.vueSelect.noOptions") }}</div>
              </template>
            </v-select>
          </v-form>

          <v-form id="case-type-notification-select" :label="$t('tourBooking.notificationChannel')" form-type="outline">
            <v-select
              v-model="notificationPlatform"
              :clearable="false"
              :options="notificationPlatformOptions"
              :reduce="(returnValue) => returnValue.key"
            >
              <template v-slot:no-options>
                <div class="my-4">{{ $t("general.vueSelect.noOptions") }}</div>
              </template>
            </v-select>
          </v-form>

          <v-form id="case-type-notification-select" :label="$t('tourBooking.notificationEmail')" form-type="outline">
            <v-select
              v-model="supplierEmail"
              :clearable="false"
              :options="supplierEmailOptions"
              :reduce="(returnValue) => returnValue.key"
              :selectable="isOptionEnabled"
            >
              <template v-slot:no-options>
                <div class="my-4">{{ $t("general.vueSelect.noOptions") }}</div>
              </template>
            </v-select>
          </v-form>

          <v-button
            :disabled="disableButton"
            :is-loading="disableButton"
            class="btn-submit-form btn-icon w-fit align-self-end mt-16"
            size="sm"
            variant="primary"
            @click="sendNotification"
          >
            {{ $t("general.button.send") }}
            <v-icon icon="arrow-right" size="sm" space="ms-12" />
          </v-button>
        </div>
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import vSelect from "vue-select";
import { VForm } from "@uniqoders/form";
import VOffCanvas from "@/components/vendor/basic/offcanvas/VOffCanvas.vue";
import OffCanvasHeader from "@/components/vendor/basic/offcanvas/OffCanvasHeader.vue";
import OffCanvasBody from "@/components/vendor/basic/offcanvas/OffCanvasBody.vue";
import api from "@/api";
import useConcepts from "@/helpers/Concepts";
import VButton from "@/components/vendor/basic/button/VButton.vue";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";
import { mapConcepts } from "@/helpers/ConceptHelper";
import ResizableContainer from "@/components/resizablecontainer/ResizableContainer.vue";
import { mapState } from "pinia";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";
import { useAppStore } from "@/stores/app";
import { DateTime } from "luxon";

export default defineComponent({
  name: "TheTourStatusesSendNotificationOffCanvas",
  components: {
    vSelect,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    VForm,
    VIcon,
    VButton,
    ResizableContainer,
  },
  emits: ["closed", "reloadItems"],
  props: {
    agency: {
      type: String,
      required: true,
    },
    caseId: {
      type: Number,
      required: true,
    },
    tourId: {
      type: Number,
      required: true,
    },
    resources: {
      type: Array as () => number[],
      required: true,
    },
    supplierDataForEmail: {
      type: Array as () => any[],
      default: () => [],
    },
  },
  setup() {
    const userStore = useUserStore();

    return {
      ...useConcepts(),
      ...mapState(useAppStore, ["settings"]),
      userStore,
    };
  },
  data() {
    return {
      disableButton: false,
      notification: "",
      notificationPlatformOptions: [
        {
          key: "MailChimp",
          label: "MailChimp",
        },
        {
          key: "Google",
          label: "Google",
        },
      ],
      notificationPlatform: "",
      supplierEmail: "",
    };
  },
  computed: {
    supplierEmailNotificationOptions(): any[] {
      if (!!this.concepts && this.concepts["supplier.email_notification_types"]) {
        return mapConcepts(this.concepts["supplier.email_notification_types"],true);
      }

      return [];
    },
    filteredOptions() {
      return this.supplierEmailNotificationOptions.filter(option => option.key !== "booking_request_modification");
    },
    ...mapState(useAuthStore, ["user"]),
    supplierEmailOptions(): any[] {
      return [
        {
          key: "booking@waw.travel",
          label: "booking@waw.travel",
        },
        {
          key: this.user.email,
          label: this.user.email,
        },
      ];
    },
    bookingRequestLabel() {
      const bookingRequest = this.supplierEmailNotificationOptions.find(item => item.key === this.notification);
      return bookingRequest ? bookingRequest.label : "Label not found";
    },
    bookingRequestSubject() {
      switch(this.bookingRequestLabel) {
        case "Booking Request":
          return this.$t("emailTemplate.request");
        case "Booking Modification":
          return this.$t("emailTemplate.mofication");
        case "Booking Reminder":
          return this.$t("emailTemplate.reminder");
        case "Invoice Request":
          return this.$t("emailTemplate.invoice_request");
        case "Payment Information":
          return this.$t("emailTemplate.payment_information");
        default:
          return ""
      }
    }
  },
  mounted() {
    this.notification = this.supplierEmailNotificationOptions[0].key;
    this.notificationPlatform = this.notificationPlatformOptions[0].key;
    this.supplierEmail = this.supplierEmailOptions[0].key;
  },
  methods: {
    isOptionEnabled(option: { label: string; }) {
      // Enable if the email contains @waw.travel
      return /@waw\.travel$/.test(option.label);
    },
    calculateNights(initDate: string, endDate: string): number {
      if (!initDate || !endDate) {
        return 0;
      }

      const init = DateTime.fromISO(initDate).startOf("day");
      const end = DateTime.fromISO(endDate).startOf("day");

      return end.diff(init, "days").days;
    },
    urlEncodeText(text: string) {
      return encodeURIComponent(text)
    .replace(/%20/g, "+")    // Use '+' for spaces in URL query strings.
    .replace(/%0A/g, "%0D%0A") // Ensure newlines are handled with CRLF.
    .replace(/%2A/g, "*")    // Decode '*' if it should remain unchanged.
    .replace(/%2B/g, "%20")  // Convert '+' back to space if needed.
    .replace(/%3A/g, ":")    // Decode ':' for readability.
    .replace(/%2C/g, ",")    // Decode ',' for readability.
    .replace(/%3F/g, "?")    // Decode '?' to maintain readability.
    .replace(/%2F/g, "/")    // Decode '/' if it should remain unchanged.
    .replace(/%40/g, "@")    // Decode '@' for email addresses.
    .replace(/%25/g, "%")    // Decode '%' if it should remain unchanged.
    .replace(/%26/g, "%26")  // Keep '%26' for '&' to avoid breaking URLs.
    .replace(/%3D/g, "=")    // Decode '=' for query strings.
    .replace(/%23/g, "#")    // Decode '#' for readability.
    .replace(/%3B/g, ";")    // Decode ';' for readability.
    .replace(/%27/g, "'")    // Decode "'" for readability.
    .replace(/%24/g, "$")    // Decode '$' for readability.
    .replace(/%5B/g, "[")    // Decode '[' for readability.
    .replace(/%5D/g, "]")    // Decode ']' for readability.
    .replace(/%5C/g, "\\")   // Decode '\' if it should remain unchanged.
    .replace(/%7B/g, "{")    // Decode '{' for readability.
    .replace(/%7D/g, "}")    // Decode '}' for readability.
    .replace(/%22/g, '"');   // Decode '"' for readability.
},
    handleGoogle() {
      const recipient = encodeURIComponent(this.supplierDataForEmail[0]?.email);
      const subject = `${this.$t("emailTemplate.email_subject")} ${this.bookingRequestLabel}`;

      const body = `${this.$t("emailTemplate.greeting")} ${this.supplierDataForEmail[0]?.name},
${this.$t("emailTemplate.hope_you_are_fine")} ${this.bookingRequestSubject}

${this.$t("emailTemplate.client")} ${this.userStore.currentTour?.clientTour?.name?.split(" ")?.slice(2).join(" ")?.replace(" | -", "")}
${this.$t("emailTemplate.case")} ${this.userStore.currentTour?.clientTour?.name?.split(" ")[0]}

${this.supplierDataForEmail
  .map((e) =>
    e.itemResourcesGrouped
    ?.filter((item: any) => item.invoiceChecked === 'mixed' || item.invoiceChecked === true)
      ?.map(
        (item: any) => `
${this.$t("emailTemplate.room")} ${item.name}
${this.$t("emailTemplate.quantity")} ${item.quantity}
${this.$t("emailTemplate.check_in_date")} ${this.$str.formatDateTime(item.date, this.settings.formatDate)}
${this.$t("emailTemplate.check_out_date")} ${this.$str.formatDateTime(item.endDate, this.settings.formatDate)}
${this.$t("emailTemplate.nights")} ${this.calculateNights(item.date, item.endDate)}
${this.$t("emailTemplate.number_of_people")} ${item.resources.map((e: any) => e.pax)[0]}
${this.$t("emailTemplate.observations")}
`
      )
      .join("\n")
  )
  .join("\n")}

${this.$t("emailTemplate.understanding")}`;

      const gmailURL = `https://mail.google.com/mail/?view=cm&fs=1&to=${recipient}&su=${this.urlEncodeText(
        subject
      )}&body=${this.urlEncodeText(body)}`;
      window.open(gmailURL, "_blank");
    },
    sendNotification() {
      this.$modal.confirm({
        title: this.$t("tourBooking.emailModal.title"),
        text: this.$t("tourBooking.emailModal.text"),
        leftButtonClasses: "btn-tertiary",
        confirmButtonText: this.$t("general.button.send"),
        confirmButtonCallback: () =>
          this.notificationPlatform === "Google" ? this.handleGoogle() : this.doSendNotification(),
      });
    },
    async doSendNotification() {
      try {
        this.disableButton = true;

        const data = {
          notification: this.notification,
          channel: this.notificationPlatform,
          from: this.supplierEmail,
          resources: this.resources,
        };

        await api.notification.supplier(
          this.agency, this.caseId, this.tourId, data
        );

        this.$toast.success(this.$t("general.shared.savedChanges"));

        this.$emit("reloadItems");
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.disableButton = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.input-group) {
  .form-control {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

.resource-type {
  border: 1px solid var(--uq-gray-400);
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin-bottom: 1rem;

  .resource-supplier {
    margin-left: 1rem;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem;
    }

    &:not(:last-child):after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: var(--uq-gray-300);
    }
  }
}
</style>
