<template>
  <div id="generated-travel-book" class="h-100">
    <v-loader v-if="loadingTravelBook" />

    <div v-else-if="show404Error" class="error-container d-flex align-items-center justify-content-center h-100">
      <div class="p-16 py-lg-24 px-lg-32">
        <div class="title fw-bold">{{ $t("general.errors.error404") }}</div>
        <div class="text-xl mb-16">{{ $t("general.errors.pageNotFound") }}</div>
        <div>{{ $t("general.errors.pageNotFoundText") }}</div>
      </div>
    </div>

    <template v-else>
      <v-travel-book-page-header v-if="!!tour" :tour="tour" />

      <table>
        <thead>
          <tr>
            <td>
              <div class="header-space" />
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <div class="page">
                <div class="page-inner container">
                  <div class="d-flex justify-content-between align-items-center hide-print mb-32">
                    <div v-if="showSupplierCheckbox">
                      <v-checkbox
                        id="my-supplier-checkbox"
                        v-model="showSuuplier"
                        :label="$t('general.shared.showSuppliers')"
                        class="text-nowrap"
                      />
                    </div>
                    <v-button
                      :disabled="downloadingPdf"
                      class="fw-medium text-sm hide-print"
                      size="xs"
                      variant="primary"
                      @click="downloadPDF"
                    >
                      <v-icon icon="download" space="me-8" />

                      <template v-if="downloadingPdf">{{ $t("general.button.generatingPdf") }}</template>
                      <template v-else>{{ $t("general.button.download") }}</template>
                    </v-button>
                  </div>

                  <div class="d-flex flex-column flex-lg-row gap-lg-24 justify-content-between">
                    <the-travel-book-client v-if="!!tour" :tour="tour" />

                    <the-travel-book-agency-billing v-if="!!tour" :tour="tour" />
                  </div>

                  <div v-if="!!featuredImage" class="featured-image mt-32">
                    <v-picture class="d-block">
                      <img :alt="$t('clientTour.featuredImage')" :src="featuredImage.storageUrl" />
                    </v-picture>
                  </div>
                </div>
              </div>

              <div class="page">
                <the-travel-book-resume-days v-if="!!tour" :is-summary="true" :tour-days="tourDays" :show-suuplier="showSuuplier"/>
              </div>

              <div class="page">
                <the-travel-book-price
                  v-if="!!tour && !!tourTotalPrice"
                  :price-type="priceType"
                  :tour-total-price="tourTotalPrice"
                />

                <the-travel-book-optional-resources v-if="!!tour && optionals.length > 0" :tour-days="optionals" />

                <div v-if="!!tour?.clientTour?.complementaryText" id="page-aditional-information">
                  <div class="page-inner container">
                    <h3 class="h5 mb-12">{{ $t("travelBook.extraField") }}</h3>

                    <div
                      class="terms-container table-container bg-white w-100 rounded-sm p-28"
                      style="box-shadow: 0 1px 0.25rem var(--uq-gray-300)"
                    >
                      <div v-html="tour.clientTour.complementaryText" />
                    </div>
                  </div>
                </div>

                <the-travel-book-terms v-if="!!tour && tour?.clientTour?.translations.length > 0" :tour="tour" />
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div class="footer-space" />
            </td>
          </tr>
        </tfoot>
      </table>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import { DateTime } from "luxon";
import { VCheckbox } from "@uniqoders/form";
import { findConceptByKey } from "@/helpers/ConceptHelper";
import VLoader from "@/components/vendor/loader/VLoader.vue";
import useOffCanvasUtils from "@/helpers/OffCanvasUtils";
import api from "@/api";
import Tour from "@/api/objects/Tour";
import VTravelBookPageHeader from "@/components/tour/book/parts/VTravelBookPageHeader.vue";
import SupplierResource from "@/api/objects/SupplierResource";
import TheTravelBookResumeDays from "@/components/tour/book/parts/TheTravelBookResumeDays.vue";
import TourDay from "@/api/objects/TourDay";
import TheTravelBookPrice from "@/components/tour/book/parts/TheTravelBookPrice.vue";
import TheTravelBookTerms from "@/components/tour/book/parts/TheTravelBookTerms.vue";
import TheTravelBookClient from "@/components/tour/book/parts/TheTravelBookClient.vue";
import TheTravelBookAgencyBilling from "@/components/tour/book/parts/TheTravelBookAgencyBilling.vue";
import TheTravelBookOptionalResources from "@/components/tour/book/parts/TheTravelBookOptionalResources.vue";
import VButton from "@/components/vendor/basic/button/VButton.vue";
import VPicture from "@/components/vendor/basic/picture/VPicture.vue";
import Media from "@/api/objects/Media";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";
import TourDayItemResource from "@/api/objects/TourDayItemResource";

export default defineComponent({
  name: "TheQuotePdf",
  components: {
    VPicture,
    TheTravelBookOptionalResources,
    TheTravelBookAgencyBilling,
    TheTravelBookClient,
    TheTravelBookTerms,
    TheTravelBookPrice,
    TheTravelBookResumeDays,
    VLoader,
    VTravelBookPageHeader,
    VButton,
    VIcon,
    VCheckbox,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    tourId: {
      type: String,
      required: true,
    },
  },
  computed: {
    token(): any {
      return this.$route.query.token;
    },
    locale(): any {
      return this.$route.query.locale;
    },
    summary(): any {
      return !!this.$route.query.summary && this.$route.query.summary === "1" ? 1 : 0;
    },
    domain() {
      return import.meta.env.VITE_APP_API_URL;
    },
    isOptional(): boolean {
      return !!this.$route.query.is_optional && this.$route.query.is_optional === "1";
    },
    optional(): any {
      return !!this.$route.query.is_optional && this.$route.query.is_optional === "1" ? 1 : 0;
    },
    priceType(): any {
      return !!this.$route.query.price_type ? this.$route.query.price_type : "tp";
    },
    showSupplierCheckbox(): boolean {
      const agency = localStorage.getItem("agency");
      const isCollaborator = localStorage.getItem("isCollaborator");
      return !!agency && !!isCollaborator && isCollaborator === "false";
    }
  },
  setup() {
    return {
      findConceptByKey,
      ...useOffCanvasUtils(),
    };
  },
  data() {
    return {
      loadingTravelBook: true,
      downloadingPdf: false,
      show404Error: false,
      tour: {} as Tour,
      supplierResources: [] as SupplierResource[],
      tourDays: [] as TourDay[],
      tourTotalPrice: "0",
      optionals: [] as TourDay[],
      featuredImage: null as Media | null,
      tourDaysDates: [] as Record<string, string | number>[],
      showSuuplier: false,
    };
  },
  async created() {
    if (!!this.token) {
      let tour: Tour | any = {};

      const params = {
        token: this.token,
        locale: this.locale,
        summary: this.summary,
        price_type: this.priceType,
        is_optional: this.optional,
      };

      try {
        this.tour = await api.travelBook.simple(Number(this.caseId), Number(this.tourId), params);
        tour = await api.travelBook.dataForQuote(Number(this.caseId), Number(this.tourId), params);

        this.tourDays = tour.tourDays;

        this.mapTourDays();

        let dayNumber = 0;

        this.tourDaysDates = this.tourDays.map((tourDay: any) => {
          dayNumber += 1;

          return {
            day: dayNumber,
            date: tourDay.date,
          };
        });

        // When "optional" query param is true, we need to get the optional resources
        if (this.isOptional) {
          this.optionals = await api.travelBook.optional(Number(this.caseId), Number(this.tourId), params);

          this.setOptionalToTourDays();

          this.optionals.forEach((optional: any) => {
            const dayDate = this.tourDaysDates.find((tourDayDate: any) => tourDayDate.date === optional.date);

            if (!!dayDate) {
              optional.day = dayDate.day;
            }
          });
        }

        document.title = tour.name;

        this.featuredImage = tour.featuredImage;

        this.tourTotalPrice =  tour.quotePrice.toString().replace(".", ",") || tour.grossPrice.toString().replace(".", ",");
        this.loadingTravelBook = false;
      } catch (e: any) {
        console.error(e);

        this.loadingTravelBook = false;
        this.show404Error = true;
      }
    } else {
      this.show404Error = true;
    }
  },
  methods: {
    mapTourDays() {
      const tourDaysItemsHotelCache: Record<string, any>[] = [];

      this.tourDays.forEach((tourDay: Record<string, any>) => {
        if (!!tourDay.items && tourDay.items.length > 0) {
          tourDay.items.forEach((tourDayItem: Record<string, any>) => {
            if (tourDayItem.type.toLowerCase() === "hotel" && !tourDayItem.disabled) {
              if (!!tourDayItem.resources && tourDayItem.resources.length > 0) {
                tourDayItem.resources.forEach((resource: TourDayItemResource) => {
                  if (!resource.isOptional) {
                    let firstDay = DateTime.fromISO(tourDay.date).plus({ days: 1 });
                    const lastDay = DateTime.fromISO(resource.endDate);

                    while (firstDay < lastDay) {
                      const tourDayItemResource = {
                        id: resource.id,
                        name: resource.resource.name,
                        currentDay: firstDay.toFormat("yyyy-MM-dd"),
                        type: tourDayItem.type,
                        disabled: true,
                      };

                      tourDaysItemsHotelCache.push(tourDayItemResource);
                      firstDay = firstDay.plus({ days: 1 });
                    }
                  }
                });
              }
            }
          });
        }
      });

      tourDaysItemsHotelCache.forEach((tourDayItemHotel: Record<string, any>) => {
        const tourDayCheckOut = this.tourDays.find(
          (tourDayAux: Record<string, any>) =>
            DateTime.fromISO(tourDayAux.date).toFormat("yyyy-MM-dd") === tourDayItemHotel.currentDay
        );

        if (!!tourDayCheckOut) {
          if (!tourDayCheckOut.disabledItemResources) {
            tourDayCheckOut.disabledItemResources = [];
            tourDayCheckOut.disabledItemResources.push(tourDayItemHotel);
          } else {
            tourDayCheckOut.disabledItemResources.push(tourDayItemHotel);
          }
        }
      });
    },
    setOptionalToTourDays() {
      this.optionals.forEach((tourDay: Record<string, any>) => {
        const foundedTourDay = this.tourDays.find(
          (tourDayAux: Record<string, any>) => tourDayAux.date === tourDay.date
        );

        if (!!foundedTourDay) {
          if (!foundedTourDay.optionalItemResources) {
            foundedTourDay.optionalItemResources = [];
          }

          tourDay.items.forEach((tourDayItem: Record<string, any>) => {
            tourDayItem.resources.forEach((resource: TourDayItemResource) => {
              const tourDayItemResource = {
                id: resource.id,
                name: resource.resource.name,
                currentDay: DateTime.fromISO(tourDay.date).toFormat("yyyy-MM-dd"),
                type: tourDayItem.type,
              };

              if (tourDayItem.type !== "hotel") {
                foundedTourDay.optionalItemResources.push(tourDayItemResource);
              } else {
                let firstDay = DateTime.fromISO(tourDay.date);
                const lastDay = DateTime.fromISO(resource.endDate);

                while (firstDay < lastDay) {
                  tourDayItemResource.currentDay = firstDay.toFormat("yyyy-MM-dd");

                  const foundedDay = this.tourDays.find(
                    (dayAux: Record<string, any>) =>
                      DateTime.fromISO(dayAux.date).toFormat("yyyy-MM-dd") === tourDayItemResource.currentDay
                  );

                  if (!!foundedDay) {
                    if (!foundedDay.optionalItemResources) {
                      foundedDay.optionalItemResources = [];
                    }
                    foundedDay.optionalItemResources.push(tourDayItemResource);
                  }

                  firstDay = firstDay.plus({ days: 1 });
                }
              }
            });
          });
        }
      });
    },
    async downloadPDF() {
      try {
        this.downloadingPdf = true;

        const params = {
          token: this.token,
          locale: this.locale,
          summary: this.summary,
          price_type: this.priceType,
          is_optional: this.optional,
        };

        const response = await axios({
          url: `/api/case/${this.caseId}/tour/${this.tourId}/preview/download`,
          baseURL: this.domain,
          params,
          responseType: "blob",
          withCredentials: true,
        });

        const contentDisposition = response.headers["content-disposition"];
        let filename = this.tour.name;

        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);

          if (filenameMatch.length > 1) {
            filename = filenameMatch[1];
          }
        }

        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `quote-${filename}.pdf`);
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        console.error(e);

        this.$toast.error(this.$t("travelBook.downloadErrorText"));
      } finally {
        this.downloadingPdf = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.hide-print {
  @media print {
    display: none;
  }
}

#generated-travel-book {
  .error-container {
    .title {
      font-size: 5rem;
    }
  }

  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  table {
    width: 100%;
  }

  .header-space {
    position: relative;
    z-index: 1;

    @media print {
      height: 7.375rem;
    }
  }

  .footer-space {
    position: relative;
    z-index: 1;
  }

  @page {
    size: A4;
    margin: 1.5cm;
  }

  @media print {
    thead {
      display: table-header-group;
    }

    tfoot {
      display: table-footer-group;
    }
  }

  .page {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 100vw;

    @media print {
      page-break-before: always;
    }

    :deep(.page-inner) {
      padding-inline: 2rem;
      margin: 0 auto;
      width: 100%;

      @media only screen {
        padding-block: 2rem;
      }

      @media print {
        max-width: 58.125rem !important;
        padding-block: 1rem;
      }
    }
  }

  .featured-image {
    :deep(img) {
      height: 100%;
      width: 100%;
      max-height: 18rem;
      object-fit: contain;
    }
  }
}
</style>
