import { AbstractService } from '@uniqoders/sdk';
import SupplierResourcePrice from '@/api/objects/SupplierResourcePrice';

export default class SupplierResourcePriceService extends AbstractService {
  /**
   * Retrieves all Supplier Resource Prices
   * @protected
   */
  public all(resource: number): Promise<SupplierResourcePrice> {
    return this.request({
      method: 'get',
      url: this.buildPath('/supplier/resource/{resource}/prices', { resource }),
    });
  }

  /**
   * Creates a single Supplier Resource Price
   * @protected
   * @param resource
   * @param data
   */
  public create(resource: number, data: Record<string, any>): Promise<SupplierResourcePrice> {
    return this.request({
      method: 'post',
      url: this.buildPath('/supplier/resource/{resource}/price', { resource }),
      data,
    });
  }

  /**
   * Updates the specified Supplier Resource
   * @param resource
   * @param price
   * @param data
   * @protected
   */
  public update(resource: number, price: string | number, data: Record<string, any>): Promise<SupplierResourcePrice> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/supplier/resource/{resource}/price/{price}', { resource, price }),
      data,
    });
  }

  /**
   * Deletes the specified Supplier Resource Price
   * @protected
   * @param resource
   * @param price
   */
  public delete(resource: number, price: string | number): Promise<SupplierResourcePrice> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/supplier/resource/{resource}/price/{price}', { resource, price }),
    });
  }

  /**
   * Duplicate a multiple Suppliers Resource Price
   * @protected
   * @param data
   */
  public duplicate(data: Record<string, any>): Promise<SupplierResourcePrice> {
    return this.request({
      method: 'post',
      url: this.buildPath('/supplier/resource/duplicate-price'),
      data,
    });
  }


    /**
   * Delete a Suppliers Resource Price
   * @protected
   * @param supplierResource
   */
    public deleteduplicate(supplierResource: number | string): Promise<SupplierResourcePrice> {
      return this.request({
        method: 'delete',
        url: this.buildPath('/supplier/resource/{supplierResource}/delete-block', { supplierResource }),
      });
    }

  /**
   * Sortable Suppliers Room
   * @protected
   * @param resource
   * @param data
   */
  public sortable(resource: number, data: Record<string, any>): Promise<SupplierResourcePrice> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/supplier/resource/{resource}/update-position', {
        resource,
      }),
      data,
    });
  }

  /**
   * Validate the supplier resource price
   * @param supplier
   * @param data
   */
  public validateSupplierResourcePrice(supplier: number | string, data: Record<string, any>): Promise<SupplierResourcePrice> {
    return this.request({
      method: 'post',
      url: this.buildPath('/supplier/{supplier}/validate-prices', { supplier }),
      data,
    });
  }
}
