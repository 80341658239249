<template>
  <div>
    <!-- Desktop layout when not mobile -->
    <div v-if="!isMobile" class="d-flex flex-column">
      <div class="d-flex gap-20 align-items-center">
        <div class="d-flex flex-column align-items-center w-25 common-net-price">
          <v-form-input
            id="pricing-net-price"
            :class="{
              validated: netValidated === true,
              updatebysuppliervalidated: !netValidated && isPriceValidated,
            }"
            v-model="localCommonNetPrice"
            :disabled="localNetValidated === true"
            autocomplete="off"
            form-type="input-group"
            onwheel="return false;"
            right-addon="€"
            step=".01"
            type="number"
            @blur="callCommonNetPriceAPI(localCommonNetPrice)"
          />
        </div>

        <div>
          <v-checkbox
            :id="`net-validated`"
            v-model="localNetValidated"
            :for-key="`net-validated`"
            :label="$t('general.shared.netValidated')"
            class="text-nowrap"
          />
          <!-- <v-checkbox v-model="localMarkToPay" :label="$t('general.shared.markedPay')" class="text-nowrap" />
          <v-checkbox v-model="localIsSplitted" :label="$t('tourPricing.splitPayment')" class="text-nowrap" /> -->
        </div>

        <!-- <div v-if="localIsSplitted" class="d-flex">
          <v-form
            id="payment-partial-amount-paid-form"
            :label="$t('general.shared.paid')"
            class="w-50 px-4"
            form-type="outline"
          >
            <v-form-input
              id="payment-partial-amount-paid"
              v-model="localAmountPaid"
              autocomplete="off"
              class="form-outline"
              form-type="input-group"
              onwheel="return false;"
              right-addon="€"
              step=".1"
              type="number"
            />
          </v-form>

          <v-form
            id="payment-partial-amount-pending-form"
            :label="$t('general.shared.pending')"
            class="w-50"
            form-type="outline"
          >
            <v-form-input
              id="payment-partial-amount-pending"
              :model-value="localAmountPending"
              autocomplete="off"
              class="form-outline"
              disabled
              form-type="input-group"
              onwheel="return false;"
              right-addon="€"
              step=".1"
              type="number"
            />
          </v-form>
        </div> -->
      </div>
    </div>

    <!-- Mobile layout when isMobile is true -->
    <div v-else class="d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column align-items-center w-20">
        <v-form-input
          id="pricing-net-price"
          v-model="localCommonNetPrice"
          autocomplete="off"
          form-type="input-group"
          onwheel="return false;"
          right-addon="€"
          step=".01"
          type="number"
        />
      </div>

      <v-checkbox
        :id="`net-validated`"
        v-model="localNetValidated"
        :for-key="`net-validated`"
        :label="$t('general.shared.netValidated')"
        class="text-nowrap"
      />
      <!-- <v-checkbox v-model="localMarkToPay" :label="$t('general.shared.markedPay')" class="text-nowrap" />
      <v-checkbox v-model="localIsSplitted" :label="$t('tourPricing.splitPayment')" class="text-nowrap" />

      <div v-if="localIsSplitted" class="d-flex">
        <v-form
          id="payment-partial-amount-paid-form"
          :label="$t('general.shared.paid')"
          class="w-50 px-4"
          form-type="outline"
        >
          <v-form-input
            id="payment-partial-amount-paid"
            v-model="localAmountPaid"
            autocomplete="off"
            class="form-outline"
            form-type="input-group"
            onwheel="return false;"
            right-addon="€"
            step=".1"
            type="number"
          />
        </v-form>

        <v-form
          id="payment-partial-amount-pending-form"
          :label="$t('general.shared.pending')"
          class="w-50"
          form-type="outline"
        >
          <v-form-input
            id="payment-partial-amount-pending"
            :model-value="localAmountPending"
            autocomplete="off"
            class="form-outline"
            disabled
            form-type="input-group"
            onwheel="return false;"
            right-addon="€"
            step=".1"
            type="number"
          />
        </v-form>
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import debounce from "lodash/debounce";
import { VCheckbox, VFormInput } from "@uniqoders/form";
import api from "@/api";

export default defineComponent({
  name: "VSupplierProductPriceUpdate",
  components: {
    VCheckbox,
    VFormInput,
    // VForm,
  },
  props: {
    agency: String,
    caseId: Number,
    tourId: Number,
    commonNetPrice: [Number, String],
    netValidated: Boolean,
    markToPay: Boolean,
    isSplitted: Boolean,
    amountPaid: [Number, String],
    amountPending: [Number, String],
    isMobile: {
      type: Boolean,
      default: false,
    },
    tourDayItemId: Number,
    supplierPrice: {
      type: Number,
      default: 0,
    },
    isPriceValidated: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const instance = getCurrentInstance();
    const $toast = instance?.appContext.config.globalProperties.$toast;

    const localCommonNetPrice = ref(props.commonNetPrice);
    const localNetValidated = ref(props.netValidated);
    const localMarkToPay = ref(props.markToPay);
    const localIsSplitted = ref(props.isSplitted);
    const localAmountPaid = ref(props.amountPaid);
    const localAmountPending = ref(props.amountPending);

    const debouncedEmit = debounce((key, value) => emit(`update:${key}`, value), 100);

    watch(
      () => props.commonNetPrice,
      (newVal) => {
        localCommonNetPrice.value = newVal;
      }
    );

    watch(
      () => props.markToPay,
      (newVal) => {
        localMarkToPay.value = newVal;
      }
    );

    watch(
      () => props.isSplitted,
      (newVal) => {
        localIsSplitted.value = newVal;
      }
    );

    watch(
      () => props.amountPaid,
      (newVal) => {
        localAmountPaid.value = newVal;
      }
    );

    watch(
      () => props.amountPending,
      (newVal) => {
        localAmountPending.value = newVal;
      }
    );

    const callCommonNetPriceAPI = async (newVal: any) => {
      const payload = {
        updated_price: "markup",
        updated_value: newVal,
        tour_day_item_id: props.tourDayItemId,
      };
      const response = await api.tourPricing.updateAllMarkupByType(props.agency, props.caseId, props.tourId, payload);
      if (response) {
        emit("load-tour-pricing");
        $toast?.success(t("general.shared.savedChanges"));
      }
    };

    const callNetValidatedAPI = async (newVal: boolean) => {
      const payload = {
        updated_price: "net_validated",
        updated_value: newVal,
        tour_day_item_id: props.tourDayItemId,
      };
      const response = await api.tourPricing.updateAllMarkupByType(props.agency, props.caseId, props.tourId, payload);
      if (response) {
        emit("load-tour-pricing");
        $toast?.success(t("general.shared.savedChanges"));
      }
    };

    watch(localNetValidated, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        debouncedEmit("netValidated", newVal);
        callNetValidatedAPI(newVal);
      }
    });

    return {
      localCommonNetPrice,
      localNetValidated,
      localMarkToPay,
      localIsSplitted,
      localAmountPaid,
      localAmountPending,
      callCommonNetPriceAPI,
    };
  },
});
</script>

<style lang="scss" scoped>
.validated {
  :deep(.form-control) {
    background-color: var(--uq-info-light);
  }
}

.updatebysuppliervalidated {
  :deep(.form-control) {
    background-color: rgba(var(--uq-warning-rgb), 0.25);
  }
}
</style>